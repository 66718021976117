import React from 'react'
import { SaveInfoIcon } from 'Components/Review/assets'

import {
  StyledCancelButtonWrapper,
  StyledEditButtonWrapper,
  StyledReviewButton,
  StyledReviewCancelButton,
} from './style'

const SaveButton = ({ handleSubmit, handleCancel, isClientPortal }) => (
  <>
    {isClientPortal ? (
      <div>
        <StyledCancelButtonWrapper
          className='cancel-button'
          onClick={handleCancel}
        >
          Cancel
        </StyledCancelButtonWrapper>
        <StyledEditButtonWrapper
          data-testid='saveButton'
          startIcon={SaveInfoIcon}
          onClick={handleSubmit}
        >
          Save Information
        </StyledEditButtonWrapper>
      </div>
    ) : (
      <div className='cancel-button-Review save-button-Review'>
        <StyledReviewCancelButton
          // className='cancel-button-Review'

          onClick={handleCancel}
        >
          Cancel
        </StyledReviewCancelButton>
        <StyledReviewButton
          data-testid='saveButton'
          // className='cancel-button-Review'
          onClick={handleSubmit}
        >
          Save changes
        </StyledReviewButton>
      </div>
    )}
  </>
)

export default SaveButton
