import React from 'react'
import { BackButton } from 'Components/Outcome/OutcomeButtons'
import { StyledOutcomeButton } from 'Components/Outcome/style'
import Icon from 'Components/Shared/Icon/Icon'
import continueArrowIconPath from 'Shared/Icons/continueArrow'

const ButtonsContainer = ({
  backButtonCallback,
}: {
  backButtonCallback: () => void
}) => (
  <div className='actions-container'>
    {backButtonCallback && <BackButton onClick={backButtonCallback} />}
    <StyledOutcomeButton type='submit' $primary $navButton>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          lineHeight: '22px',
        }}
      >
        Continue
        <Icon
          fill={'#ffffff'}
          scale={'24px'}
          viewBox={'0 0 24 24'}
          width={'24px'}
          screenReaderLabel='Continue to the next Question'
        >
          {continueArrowIconPath}
        </Icon>
      </div>
    </StyledOutcomeButton>
  </div>
)

export default ButtonsContainer
