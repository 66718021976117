import { Popover, withStyles } from '@material-ui/core'
import Button from '@mui/material/Button'
import theme from 'Shared/Theme/ssTheme'
import styled, { css } from 'styled-components'

type StyledCategoryFilterProps = {
  isOpen: boolean
}

export const StyledCategoryFilterWrapper = styled.div<{
  isDesktopAsideOpen: boolean
}>`
  display: flex;
  position: absolute;
  top: 1.5rem;
  // left: 25rem;
  left: ${(props) => (props.isDesktopAsideOpen ? '25rem' : `1rem`)};
  transition: left 0.5s ease-in-out;
`

export const StyledCategoryFilterButton = withStyles({
  root: {
    backgroundColor: (props: StyledCategoryFilterProps) =>
      props.isOpen
        ? `${theme.palette.text.dark} !important`
        : `${theme.palette.color.white} !important`,
    color: (props: StyledCategoryFilterProps) =>
      props.isOpen
        ? `${theme.palette.color.white} !important`
        : `${theme.palette.text.darken} !important`,
    // position: 'absolute !important',
    // top: '1.5rem',
    // left: '30rem',
    zIndex: 1,
    borderRadius: '50px !important',
    border: (props: StyledCategoryFilterProps) =>
      props.isOpen
        ? '1px solid #000 !important'
        : '1px solid #EFEFEF !important',
    boxShadow: '5px 5px 20px 0 #AEAEAE40 !important',
    width: '17.5rem',
    height: '3rem',
    justifyContent: 'flex-start !important',
    transition: 'all .3s ease-in-out !important',
  },
})(Button)

export const StyledCategoryLevelsWrapper = styled.div`
  padding: 1rem;
  display: flex;
  gap: 1rem;
  max-height: 424px;
  overflow: hidden;
  border-radius: 16px !important;
  ul {
    margin: 0rem;
  }

  ul:first-of-type {
    padding-right: 0rem;
  }

  li:focus,
  li:focus-visible {
    background: rgba(0, 0, 0, 0.12);
  }
`

export const StyledCategoryFilterPopover = styled(Popover)`
  margin-top: 1rem;
  min-width: 180px;
  color: ${theme.palette.color.lightGrey};
  overflow: hidden;
  border-radius: 16px !important;
  .MuiPaper-root {
    box-shadow:
      rgb(255, 255, 255) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
    border-radius: 16px !important;
  }
`

type StyledMenuLevelsWrapperPrpos = {
  $isOpen?: boolean
  $isMobileOrTablet?: boolean
  $customWidth?: boolean
  $isRightSide?: boolean
}

export const StyledMenuTopLevel = styled.ul<StyledMenuLevelsWrapperPrpos>`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
  column-count: 2;
  overflow: auto;
  padding: 1rem;
  height: 100%;
  & ul {
    padding: 0;
    margin: 0;
    display: grid;
    gap: 0.5rem;
  }

  & li {
    width: 10.75rem;
    height: 3.25rem;
    background-color: #f9f9f9;
    padding: 0.5rem 0.75rem !important;
    border-radius: 8px;
    white-space: break-spaces;
    & label {
      font-size: 0.75rem;
      color: ${theme.palette.text.dark};
      font-family: 'Noto Sans HK';
    }
    &:hover,
    &.selected {
      background-color: #eef9f6;
      cursor: pointer;
      & label {
        color: ${theme.palette.primary.darken30};
        cursor: pointer;
      }
      & svg {
        stroke: ${theme.palette.primary.darken30};
        cursor: pointer;
      }
    }
  }
  ${({ $isMobileOrTablet }) =>
    $isMobileOrTablet &&
    css`
      width: 33.333%;
      padding: 1rem !important;
      display: flex;
      grid-template-columns: unset;
      column-count: unset;
      flex-direction: column;
      max-height: 100%;
      overflow-x: auto;
      & li {
        width: 100%;
        height: auto;
        & label {
          font-size: 1.2rem;
        }
      }
      @media (max-width: 574px) {
        & li {
          height: 4.675rem;
          padding: 1rem !important;
        }
        & > div:first-child {
          height: 100%;
          overflow: auto;
        }
      }
    `}
`

const StyledMenuLevelsWrapper = css<StyledMenuLevelsWrapperPrpos>`
  border: unset;
  border-radius: 8px;
  padding: 0;
  max-height: 70vh;
  overflow: auto;
  > li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    color: ${theme.palette.text.darken};
    font-size: 0.875rem;
    font-weight: 500;
    font-family: 'Noto Sans HK';
    border-radius: 8px;
    cursor: pointer;
    & label {
      color: ${theme.palette.text.darken};
      font-size: 0.875rem;
      font-weight: 500;
      font-family: 'Noto Sans HK';
      cursor: pointer;
    }
  }
  @media (max-width: 1024px) {
    max-height: 100%;
  }
  ${({ $isMobileOrTablet }) =>
    $isMobileOrTablet &&
    css`
      width: 33.333%;
      max-height: 100%;
      border: unset;
      padding: 0 1.875rem 1rem 1.875rem;
      margin-left: 0;
      overflow-x: auto;
      & li {
        width: 100%;
        height: auto;
        padding: 1.18rem 0.75rem !important;
        font-size: 1.2rem;
        & label {
          font-size: 1.2rem;
        }
      }
      @media (max-width: 574px) {
        padding: 0 !important;
        & li {
          font-size: 1.2rem;
          padding: 0.67rem 1rem !important;
          > div {
            & label {
              font-size: 1.2rem;
            }
          }
        }
      }
    `}
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      @media (min-width: 1024.1px) {
        border: 1px solid #efefef;
        padding: 0.75rem;
        margin-left: 0.75rem;
        > li {
          width: 13.75rem;
          padding: 0.5rem 0.75rem !important;
        }
      }
    `}
`

export const StyledMenuSecondLevel = styled.ul<StyledMenuLevelsWrapperPrpos>`
  ${StyledMenuLevelsWrapper};
  .selected {
    background-color: #eef9f6 !important;
    cursor: pointer;
  }
  @media (max-width: 574px) {
    padding: 0 !important;
    & li {
      padding: 1.4rem 1rem !important;
    }
  }
  > li {
    border-radius: 8px;
  }
  > li:hover,
  .selected {
    border-radius: 8px;
    background-color: #eef9f6;
    cursor: pointer;
    > div {
      & label {
        color: ${theme.palette.primary.darken30};
        cursor: pointer;
      }
      & svg {
        stroke: ${theme.palette.primary.darken30};
      }
    }
  }
`

export const StyledMenuLevelLabel = styled.label<StyledMenuLevelsWrapperPrpos>`
  white-space: normal;
`
export const StyledMenuLevelSpan = styled.span<StyledMenuLevelsWrapperPrpos>`
  margin-left: 0.5rem;
`

export const StyledMenuLastLevel = styled.ul<StyledMenuLevelsWrapperPrpos>`
  ${StyledMenuLevelsWrapper};
  li.selected {
    color: #08674a;
    background-color: #eef9f6;
  }

  @media (max-width: 574px) {
    padding: 0 !important;
    & li {
      padding: 0.68rem !important;
    }
  }
  > li:hover,
  .selected {
    background-color: unset;
    color: #08674a;
    > label {
      color: #08674a;
    }
  }
  > li.selected {
    font-weight: 600;
    > label {
      color: #08674a;
    }
  }
`
type levelsProps = {
  $isFirstLevel?: boolean
  $isSecondLevel?: boolean
  $isLastLevel?: boolean
  $level?: string
}
export const StyledCategoryFilterMobileMenu = styled.div<levelsProps>`
  width: 300%;
  display: flex;
  position: relative;
  height: 100%;
  right: ${(props) =>
    props.$level === 'first'
      ? '0%'
      : props.$level === 'second'
        ? '100%'
        : '200%'};
  transition: right 0.3s ease-in-out;
  ${({ $isFirstLevel }) =>
    $isFirstLevel &&
    css`
      right: 0%;
    `}
  ${({ $isSecondLevel }) =>
    $isSecondLevel &&
    css`
      right: 100%;
    `}
    ${({ $isLastLevel }) =>
    $isLastLevel &&
    css`
      right: 200%;
    `}
`
StyledCategoryFilterWrapper.displayName = 'StyledCategoryFilterWrapper'
StyledCategoryFilterButton.displayName = 'StyledCategoryFilterButton'
StyledCategoryFilterPopover.displayName = 'StyledCategoryFilterPopover'
StyledMenuTopLevel.displayName = 'StyledMenuTopLevel'
StyledMenuSecondLevel.displayName = 'StyledMenuSecondLevel'
StyledMenuLastLevel.displayName = 'StyledMenuLastLevel'
StyledMenuLevelLabel.displayName = 'StyledMenuLevelLabel'
StyledMenuLevelSpan.displayName = 'StyledMenuLevelSpan'
StyledCategoryFilterMobileMenu.displayName = 'StyledCategoryFilterMobileMenu'
