import React from 'react'

const KebabMenuIcon = () => (
  <svg
    role='button'
    width='36'
    height='32'
    viewBox='0 0 36 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18 17C18.5523 17 19 16.5523 19 16C19 15.4477 18.5523 15 18 15C17.4477 15 17 15.4477 17 16C17 16.5523 17.4477 17 18 17Z'
      stroke='#151515'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18 10C18.5523 10 19 9.55228 19 9C19 8.44772 18.5523 8 18 8C17.4477 8 17 8.44772 17 9C17 9.55228 17.4477 10 18 10Z'
      stroke='#151515'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18 24C18.5523 24 19 23.5523 19 23C19 22.4477 18.5523 22 18 22C17.4477 22 17 22.4477 17 23C17 23.5523 17.4477 24 18 24Z'
      stroke='#151515'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default KebabMenuIcon
