import React from 'react'
import {
  StyledCasesCard,
  StyledFlexReferralTime,
  // StyledFlexReferralType,
  StyledFlexWrapper,
} from 'Components/ClientPortal/MyBenefits/style'
import {
  StyledHeader,
  StyledLink,
  StyledSubHeader,
} from 'Components/ClientPortal/MyResources/style'
import moment from 'moment'

const CaseCard = ({ resourceData }) => {
  const findHelpLocation = resourceData?.actionable?.findHelpLocation
  const referralCategories = resourceData?.actionable?.referralCategories[0]
  let providerId = findHelpLocation.providerId
  let programId = findHelpLocation.programId

  return (
    <>
      <StyledCasesCard className='styledCardItem'>
        <StyledFlexWrapper>
          <StyledFlexReferralTime>
            Referral Sent on{' '}
            {moment(referralCategories.createdAt).format('MMM DD, YYYY')}
          </StyledFlexReferralTime>
          {/* this will be static for now bue will be changed later we will add style in class name here selfReferral , caseManagerReferral */}
          {/* <StyledFlexReferralType className='selfReferral'>
            Self Referral
          </StyledFlexReferralType> */}
        </StyledFlexWrapper>
        <StyledLink
          to={{
            pathname: `/locations/list/${findHelpLocation.officeId}`,
          }}
          state={{
            officeId: findHelpLocation.officeId,
            providerId,
            programId,
            providerName: findHelpLocation.providerName,
            programName: findHelpLocation.programName,
            zipCode: findHelpLocation.zipCode,
            officeName: findHelpLocation.name,
            serviceTags: findHelpLocation.categories,
          }}
        >
          <StyledHeader
            title={findHelpLocation.programName}
            className='ReferralStyledHeader'
          >
            {findHelpLocation.programName}
          </StyledHeader>
        </StyledLink>
        <StyledSubHeader
          title={findHelpLocation.providerName}
          className='ReferralStyledSubHeader'
        >
          by <span>{findHelpLocation.providerName}</span>
        </StyledSubHeader>
      </StyledCasesCard>
    </>
  )
}

export default CaseCard
