import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MyBenefits from 'Components/ClientPortal/MyBenefits/MyBenefits'
import BenefitUpToDateModal from 'Components/ClientPortal/MyBenefits/UpdateBenefitModal/BenefitUpToDateModal'
import UpdateBenefitCashModal from 'Components/ClientPortal/MyBenefits/UpdateBenefitModal/UpdateBenefitCashModal'
import UpdateBenefitConfirmationModal from 'Components/ClientPortal/MyBenefits/UpdateBenefitModal/UpdateBenefitConfirmationModal'
import UpdateBenefitDenialModal from 'Components/ClientPortal/MyBenefits/UpdateBenefitModal/UpdateBenefitDenialModal'
import UpdateBenefitResponseModal from 'Components/ClientPortal/MyBenefits/UpdateBenefitModal/UpdateBenefitResponseModal'
import UpdateNotStartedBenefitModal from 'Components/ClientPortal/MyBenefits/UpdateBenefitModal/UpdateNotStartedBenefitModal'

const MyBenefitsRoutes = () => (
  <Routes>
    <Route path={'/'} element={<MyBenefits />} />
    <Route path={'/application'} element={<UpdateNotStartedBenefitModal />} />
    <Route path={'/response'} element={<UpdateBenefitResponseModal />} />
    <Route
      path={'/confirmation'}
      element={<UpdateBenefitConfirmationModal />}
    />
    <Route path={'/denial'} element={<UpdateBenefitDenialModal />} />
    <Route path={'/cash-benefit'} element={<UpdateBenefitCashModal />} />
    <Route path={'/up-to-date'} element={<BenefitUpToDateModal />} />
  </Routes>
)

export default MyBenefitsRoutes
