import { NavLink } from 'react-router-dom'
import theme from 'Shared/Theme/ssTheme'
import { Container } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const CustomStyledClientPortalWrapper = styled.div`
  width: 100%;
  background-color: ${theme.palette.secondary.default};
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  @media only screen and (max-width: 1024px) {
    padding: 16px 0;
  }
`
export const CustomStyledClientPortalContent = styled.section`
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`
export const CustomContainer = styled(Container)`
  @media only screen and (max-width: 800px) {
    width: 100%;
    max-width: 100%;
  }
`
export const TabsWrapper = styled.section`
  margin-bottom: 32px;
  @media only screen and (max-width: 1024px) {
    margin-bottom: 24px;
  }
  @media only screen and (max-width: 767px) {
    &.ClientTabsWrapper {
      overflow-x: auto;
      white-space: nowrap;
      padding-bottom: 5px;
    }
  }
`
type StyledSubMenuNavLinkProps = {
  $isClientPortal?: boolean
}

export const CustomTabItem = styled(NavLink)<StyledSubMenuNavLinkProps>`
  display: inline-flex;
  font-weight: 650;
  font-size: 14px;
  line-height: 22.4px;
  font-family: 'Noto Sans HK', sans-serif;
  text-decoration: none;
  color: #4f4f4f;
  position: relative;
  padding-bottom: 8px;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: transparent;
    width: 100%;
    height: 2px;
  }
  &.activeTab {
    color: #08674a;
    &:before {
      background-color: #08674a;
    }
  }
  &:not(:last-child) {
    margin-right: 32px;
  }
  @media only screen and (max-width: 1024px) {
    padding-bottom: 4px;
    font-weight: 550;
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`
CustomStyledClientPortalWrapper.displayName = 'CustomStyledClientPortalWrapper'
CustomStyledClientPortalContent.displayName = 'CustomStyledClientPortalContent'
TabsWrapper.displayName = 'TabsWrapper'
