import React from 'react'
import ModalTemplate from 'Components/CaseManagement/Modals/Shared/ModalTemplate'
import MyBenefits from 'Components/ClientPortal/MyBenefits/MyBenefits'

const BenefitModalPageTemplate = ({
  children,
  handleCancel,
  hasXToClose = true,
}) => {
  return (
    <>
      <ModalTemplate
        open={true}
        heading={''}
        handleCancel={handleCancel}
        width={'600px'}
        hasXToClose={hasXToClose}
      >
        {children}
      </ModalTemplate>
      <MyBenefits />
    </>
  )
}

export default BenefitModalPageTemplate
