import React, { useRef } from 'react'
import { useInView } from 'react-hook-inview'
import { useMediaQuery } from 'react-responsive'
import { useDynamicRegistrationUrl } from 'Components/App/Hooks/index'
import HelpImage from 'Components/LandingPage/Assets/HelpImage.svg'
import Table from 'Components/LandingPage/Assets/Table.svg'
import { TimelineMax } from 'gsap'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { Col, Container } from 'styled-bootstrap-grid'

import { FeaturesText } from '../Components/FeaturesText'
import { StyledClipFigure, StyledImage } from '../Styles/FeaturesImage'
import {
  StyledDotsShape,
  StyledParentHelp,
  StyledParentImg,
} from '../Styles/HelpSection'
import { LandingRow } from '../Styles/LandingSection'
import { LandingSection } from '../Styles/LandingSection'

const TableImg = () => {
  return (
    <StyledParentImg>
      <StyledImage width={'100%'} src={Table} maxHeight='auto' alt='' />
    </StyledParentImg>
  )
}

const HelpSec = () => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  const registrationUrl = useDynamicRegistrationUrl()
  let ElemRef = useRef([])
  let FigureTL = null
  let entered = false
  window.screen.orientation?.addEventListener('change', () => {
    entered = false
  })
  const TweenText = () => {
    FigureTL = new TimelineMax({
      defaults: {
        duration: 1,
      },
    })
    FigureTL.set(ElemRef.current, { y: -20 }).staggerTo(
      ElemRef.current,
      1.5,
      { css: { clipPath: 'circle(100% at 50% 50%)' } },
      0.3
    )
  }
  const [figureRef] = useInView({
    threshold: 0.5,
    onEnter: () => {
      if (!entered) {
        TweenText()
        entered = true
      }
    },
  })

  return (
    <LandingSection Help>
      <StyledParentHelp></StyledParentHelp>
      <StyledDotsShape></StyledDotsShape>
      <Container>
        <LandingRow>
          <Col lg='6'>
            <FeaturesText
              sectionTop={'-4rem'}
              marginSmallerMessage={'0'}
              widthMessage={'90%'}
              subTitle='Help me find my eligibility'
              title='What am I eligible for?'
              message="With a few quick questions, we'll help you find personalized benefits and resources!"
            ></FeaturesText>
          </Col>
          {!isMobile && (
            <Col lg='6' ref={figureRef}>
              <TableImg />
              <StyledClipFigure
                ref={(element) => {
                  ElemRef.current[0] = element
                }}
              >
                <StyledImage
                  src={HelpImage}
                  maxHeight='auto'
                  className='max-w-none'
                  alt=''
                />
              </StyledClipFigure>
            </Col>
          )}
        </LandingRow>
        <LandingRow>
          <Col lg='6'>
            <FeaturesText
              moveBtn={'true'}
              footerMarginSmaller={'0 0 2rem 0'}
              topBtn={'-9rem'}
              topBtnTab={'5rem'}
              action1='Find My Eligibility'
              target1={registrationUrl}
              action2='About Us'
              target2='https://singlestop.org'
              target2External={true}
            ></FeaturesText>
          </Col>
        </LandingRow>
        <LandingRow>
          {isMobile && (
            <Col ref={figureRef}>
              <TableImg />
              <StyledClipFigure
                ref={(element) => {
                  ElemRef.current[0] = element
                }}
              >
                <StyledImage src={HelpImage} maxHeight='auto' alt='' />
              </StyledClipFigure>
            </Col>
          )}
        </LandingRow>
      </Container>
    </LandingSection>
  )
}

export default HelpSec
