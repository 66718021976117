export const handlePreventUserTypeLetters = (
  evt: React.KeyboardEvent<HTMLInputElement>
) => {
  const charCode = evt.key.charCodeAt(0)

  // Check if Ctrl or Command is pressed
  const isCtrlOrCmd = evt.ctrlKey || evt.metaKey

  // Allow copy (Ctrl/Cmd + C), paste (Ctrl/Cmd + V), cut (Ctrl/Cmd + X), and select all (Ctrl/Cmd + A)
  if (
    isCtrlOrCmd &&
    (evt.key === 'c' || evt.key === 'v' || evt.key === 'x' || evt.key === 'a')
  ) {
    return false
  }

  if (
    (charCode < 48 || charCode > 57) && // Not a number
    evt.key !== 'Backspace' && // Allow backspace
    evt.key !== 'ArrowLeft' && // Allow left arrow
    evt.key !== 'ArrowRight' && // Allow right arrow
    evt.key !== 'Delete' && // Allow delete
    evt.key !== 'Tab' // Allow tab
  ) {
    evt.preventDefault()
    return true
  } else {
    return false
  }
}
