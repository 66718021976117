import React from 'react'
import { useParams } from 'react-router-dom'
import { StyledButtonsContainer } from 'Components/CaseManagement/Modals/style'
import type { FindHelpResource } from 'Components/LocalResources/FindHelpResource'
import { Numbers } from 'Components/LocalResources/FindHelpResource'
import Card from 'Components/Shared/Card/Card'
import TodaysClosingTime from 'Components/Shared/LocationDetails/BusinessHours/TodaysClosingTime'
import Distance from 'Components/Shared/LocationDetails/Distance/Distance'
import PhoneNumber from 'Components/Shared/LocationDetails/PhoneNumber'
import { Button } from 'Components/Shared/Uielements/styles/buttons.style'
import { isFeatureToggleOn, replaceApostrophes } from 'Shared/helpers'
import { getUserType } from 'Shared/helpers'
import theme from 'Shared/Theme/ssTheme'

import EmailDeliveryIcon from '../Assets/emailDeliveryIcon.svg'

import {
  StyledCardContent,
  StyledCardTwoColumnLayoutSection,
  StyledDivLastChild,
  StyledLink,
  StyledLocationDetail,
  StyledParentDivLastChild,
  StyledProgramName,
  StyledProviderName,
  StyledReferralIcon,
} from './style'

type LocationCardProps = {
  active?: boolean
  bottom?: number
  childBackground?: string
  childMargin?: string
  childMinHeight?: string
  childRadius?: string
  childShadow?: string
  childWidth?: string
  className?: string
  location?: FindHelpResource
  margin?: string | number
  parentBackground?: string
  parentBottom?: string
  parentLeft?: string
  parentRight?: string
  position?: string
  provider_name: string
  program_name: string
  width?: string
  withBorder?: boolean
  withShadow?: boolean
  modalLayout?: boolean
  tabIndex: number
  isLocationList?: boolean
}

export const LocationCard = (props: LocationCardProps) => {
  const {
    active,
    location,
    width,
    withShadow,
    modalLayout,
    tabIndex,
    isLocationList,
  } = props
  const params = useParams()

  if (!location) {
    return null
  }

  const elevation = active ? 3 : 1

  const {
    address,
    hours: businessHours,
    distance,
    name,
    numbers,
    provider_name,
    program_name,
    id,
    providerId,
    programId,
    timezoneName,
    acceptsReferrals,
    categories,
  } = location

  const cardBackground =
    location.id !== params.locationId
      ? props.parentBackground
      : theme.palette.secondary.lightGrey

  const isCaseManager = getUserType().toLocaleLowerCase() === 'case manager'
  const showDigitalReferral = isFeatureToggleOn('digital_referrals')

  return (
    <Card
      {...props}
      customSurface={true}
      elevation={elevation}
      margin='0'
      boxShadow={withShadow ? null : 'none'}
      focusBackground
      background={cardBackground}
      bottom={props.parentBottom}
      left={props.parentLeft}
      width={width}
      right={props.parentRight}
      role='group'
      aria-label='Location Card Details'
      tabIndex='-1'
    >
      <StyledCardContent
        withBorder={props.withBorder}
        minHeight={props.childMinHeight}
        width={props.childWidth}
        margin={props.childMargin}
        background={props.childBackground}
        radius={props.childRadius}
        shadow={props.childShadow}
      >
        {!modalLayout && (
          <StyledLink
            aria-label={name}
            tabIndex={tabIndex}
            to={location?.id}
            state={{
              officeId: id,
              providerId,
              programId,
              providerName: provider_name,
              programName: program_name,
              zipCode: address.zipCode,
              officeName: name,
              serviceTags: categories,
            }}
          />
        )}
        <StyledProgramName
          className={isLocationList && isCaseManager ? 'resources__title' : ''}
        >
          {replaceApostrophes(program_name)}
          {/* StyledReferralIcon__active */}
          {isLocationList &&
            isCaseManager &&
            acceptsReferrals &&
            showDigitalReferral && (
              <StyledReferralIcon className=''>
                <img src={EmailDeliveryIcon} alt='' />
              </StyledReferralIcon>
            )}
        </StyledProgramName>
        <StyledLocationDetail> by </StyledLocationDetail>
        <StyledProviderName>
          {replaceApostrophes(provider_name)}
        </StyledProviderName>
        {provider_name !== name && (
          <>
            <StyledLocationDetail> &mdash; </StyledLocationDetail>
            <StyledProviderName>{replaceApostrophes(name)}</StyledProviderName>
          </>
        )}
        <StyledCardTwoColumnLayoutSection>
          <div style={{ marginTop: '1.5rem' }}>
            <Distance
              alignitems={'center'}
              distance={distance}
              address={address}
            />
          </div>
          <StyledParentDivLastChild>
            <StyledDivLastChild>
              <PrimaryContact numbers={numbers} tabIndex={tabIndex + 1} />
            </StyledDivLastChild>
          </StyledParentDivLastChild>
          {businessHours && (
            <TodaysClosingTime
              alignitems={'center'}
              businessHours={businessHours}
              timezoneName={timezoneName}
            />
          )}
        </StyledCardTwoColumnLayoutSection>
        {modalLayout && (
          <StyledButtonsContainer margintop='1rem'>
            <Button
              mdFontSize='0.8125rem'
              mdMinWidth='10.25rem'
              mdMinHeight='3rem'
              fontWeight={500}
              marginSmall='1rem'
              primary
              md
              data-testid='rtl-confirm-button'
            >
              Select
            </Button>
          </StyledButtonsContainer>
        )}
      </StyledCardContent>
    </Card>
  )
}

export default LocationCard

const PrimaryContact = ({
  numbers,
  tabIndex,
}: {
  numbers: Numbers
  tabIndex: number
}) => {
  return (
    <>
      {numbers ? (
        <div style={{ marginTop: '0.875rem' }}>
          <PhoneNumber
            tabIndex={tabIndex}
            alignitems={'center'}
            numbers={numbers}
          />
        </div>
      ) : null}
    </>
  )
}
