import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { StyledDynamicCtaButton } from 'Components/Screener/style'
import LoadingIndicator from 'Components/Shared/LoadingIndicator/LoadingIndicator'
import { CALCULATE_SCREENING_RESULTS } from 'Pages/Results/gql'
import { ERROR_GENERIC_MESSAGE } from 'Shared/constants'

export type ScreenerCompletedPrimaryCallToActionProps = {
  isReturningUser: boolean
  numberOfDaysSinceScreeningCompleted: number
  clientId: string
  setError: (arg0: string) => void
}

const ScreenerCompletedPrimaryButton = ({
  setError,
  isReturningUser,
  numberOfDaysSinceScreeningCompleted = 0,
  clientId,
}: ScreenerCompletedPrimaryCallToActionProps) => {
  const [handleUpdateBenefits, { loading }] = useMutation(
    CALCULATE_SCREENING_RESULTS
  )

  const navigate = useNavigate()

  let mainCtaText: string
  const buttonURL = '/eligibility'

  if (isReturningUser) {
    mainCtaText = 'Check for updated eligibility'
  } else {
    mainCtaText = 'View my screening results'
  }

  return (
    <>
      <StyledDynamicCtaButton
        $hasExtraButton={false}
        $isYesNoQuestion={false}
        $nextSteps={false}
        disabled={loading}
        variant='contained'
        component={Link}
        to={buttonURL}
        onClick={(e: React.SyntheticEvent) => {
          if (isReturningUser) {
            e.preventDefault()
            handleUpdateBenefits({
              variables: {
                clientId,
              },
            })
              .then(() => {
                navigate(buttonURL)
              })
              .catch((error) => {
                console.error(error)
                setError(ERROR_GENERIC_MESSAGE)
              })
          } else {
            return true
          }
        }}
      >
        {mainCtaText}
      </StyledDynamicCtaButton>

      {loading && (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            right: 0,
            zIndex: 999999,
          }}
        >
          <LoadingIndicator show={true} />
        </div>
      )}
    </>
  )
}

export default ScreenerCompletedPrimaryButton
