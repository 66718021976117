import React from 'react'
import CardInfo from 'Components/CaseManagement/Reporting/Charts/Demographics/Overview/CardInfo/CardInfo'
import { StyledCard } from 'Components/CaseManagement/Reporting/Charts/Shared/style'
import Icon from 'Components/Shared/Icon/Icon'

import {
  StyledGrid,
  StyledLabel,
  StyledLeftPanel,
  StyledValue,
} from '../../CaseManagement/Reporting/Charts/Shared/RateDisplay/style'

// import ConfirmSvg from './confirm.svg'
const ConfirmedBenefit = ({ icon }) => {
  return (
    <StyledCard
      //   data-testid={dataTestId}
      style={{ height: 'auto', marginBottom: '1rem' }}
      className={'ConfirmedBenefit'}
    >
      <StyledGrid>
        <StyledLeftPanel>
          <Icon
            screenReaderLabel='Confirmed Benefit'
            fill={'auto'}
            scale={'3.825rem'}
            viewBox={'0 0 60.705 60.705'}
          >
            {icon}
            {/* {ConfirmSvg} */}
          </Icon>
        </StyledLeftPanel>
        <StyledValue>
          120$
          {/* {currency} */}
          <CardInfo
            rateDisplay={false}
            title={'Confirmed Benefit'}
            descr={'Confirmed Benefit.'}
          />
        </StyledValue>
        <StyledLabel>Confirmed Benefit</StyledLabel>
      </StyledGrid>
    </StyledCard>
  )
}

export default ConfirmedBenefit
