import { Field } from 'react-final-form'
import {
  DESKTOP_MEDIA_QUERY,
  MOBILE_AND_TABLET_MEDIA_QUERY,
  MOBILE_LANDSCAPE_AND_PORTRAIT_MEDIA_QUERY,
} from 'Shared/constants'
import styled, { css } from 'styled-components'

export const FirstScreenContainer = styled.div`
  h1 {
    line-height: 2rem;

    span {
      display: block;
    }
  }

  h2 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }
`

export const StyledButtonContainer = styled.div`
  width: 37.5rem;
  margin: 0 auto;
  @media ${MOBILE_LANDSCAPE_AND_PORTRAIT_MEDIA_QUERY} {
    width: 94%;
  }
`
export const StyledHiddenRadio = styled(Field)`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledRadioLabel = styled.label<{
  $isChecked: boolean
  $variant?: 'primary' | 'secodary' | 'tertiary'
}>`
  text-align: center;
  font-weight: 450;
  display: block;
  padding: 16px 32px;
  border-radius: 0.5rem;
  width: fit-cotent;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  @media ${DESKTOP_MEDIA_QUERY} {
    font-size: 14px;
  }

  ${({ $variant }) =>
    $variant === 'primary' &&
    css`
      color: #000;
      border-color: black;
      @media ${DESKTOP_MEDIA_QUERY} {
        border: 1px solid #efefef;
      }
    `}
  ${({ $isChecked }) =>
    $isChecked &&
    css` #0d8762
            ;
                background-color: #08674A; //#0d8762; 
                color: white;
                border-color: #08674A;
                font-weight: 700;
            `}
    &:hover {
    background-color: #08674a;
    color: #fff;
    border-color: #08674a;
  }

  &:focus-within {
    background-color: #08674a;
    color: white;
    border-color: #08674a;
    font-weight: 700;
  }
`

export const StyledError = styled.p`
  color: #e41a0c;
  font-weight: 700;
  padding: 0;
  margin: 0;
`

export const StyledForm = styled.form`
  background: #ffffff;
  border-radius: 0.5rem;
  max-width: 62.5rem;
  margin: 2.5rem auto 0;
  height: 78vh;
  @media ${DESKTOP_MEDIA_QUERY} {
    padding: 5rem 15rem 5.625rem 5rem;
  }

  @media ${MOBILE_AND_TABLET_MEDIA_QUERY} {
    padding: 1rem;
  }

  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);

  h1 {
    font-size: 1.6rem;
    line-height: 1.5;
    padding-top: 0;
    margin-top: 0;

    @media ${MOBILE_AND_TABLET_MEDIA_QUERY} {
      font-size: 1.4rem;
    }
  }

  button {
    margin-top: 2rem;
  }

  label {
    color: #716f6f;
    margin-bottom: 0.75rem;
    display: block;
  }
`

export const StyledWrapper = styled.main`
  background: #f8f8f8;
  height: 100vh;

  @media ${MOBILE_AND_TABLET_MEDIA_QUERY} {
    padding: 1rem;
  }
`

export const StyledLineBreak = styled.span`
  display: block;
  line-height: 1.5;
`
export const StyledOutcomeButton = styled.button<{
  $primary?: boolean
  $secondary?: boolean
  $tertiary?: boolean
  $back?: boolean
  navButton?: boolean
}>`
  --accent-color: #08674a; //#0d8762;
  --shadow-color: #2e2e2e;
  display: block;
  font-weight: 700;
  padding: 0.9rem 1.4rem 1rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.375rem;
  min-width: 19.75rem;
  width: 100%;

  ${(props) =>
    props.$navButton &&
    css`
      padding: 10px 0;
      @media ${DESKTOP_MEDIA_QUERY} {
        min-width: 172px;
        width: 172px;
      }
    `}
  cursor: pointer;

  @media ${DESKTOP_MEDIA_QUERY} {
    border-radius: 56px;
  }

  &:hover {
    box-shadow: 0 0.1875rem 0.3125rem -0.0625rem var(--shadow-color);
  }

  ${(props) =>
    props.$primary &&
    css`
      background-color: var(--accent-color);
      color: #ffffff;
      border-color: var(--accent-color);
    `}
  ${(props) =>
    props.$secondary &&
    css`
      background-color: #ffffff;
      @media ${DESKTOP_MEDIA_QUERY} {
        background-color: #f9f9f9;
      }

      color: #151515;
      border-color: #f9f9f9;
    `}

    ${(props) =>
    props.$tertiary &&
    css`
      background-color: #ffffff;
      color: #000000;
      border-color: #999999;
      @media ${DESKTOP_MEDIA_QUERY} {
        border-color: #efefef;
      }
    `}
    ${(props) =>
    props.$back &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      border-width: 0;
    `}
`

export const StyledContentContainer = styled.div<{
  $radioContainer?: boolean
  $modal?: boolean
}>`
  background: #ffffff;
  max-width: 1000px;
  ${(props) =>
    props.$radioContainer &&
    css`
      @media ${DESKTOP_MEDIA_QUERY} {
        width: 600px;
      }
    `}
  margin: 1.8rem auto;
  padding: 2rem 0;
  ${(props) =>
    props.$modal &&
    css`
      margin: 0 auto;
      padding: 0;
    `}
  border-radius: 0.5rem;

  ${(props) =>
    !props.$modal &&
    css`
      box-shadow:
        0 1px 3px rgba(0, 0, 0, 0.12),
        0 1px 2px rgba(0, 0, 0, 0.24);
    `}
  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0;
    list-style-type: none;
  }

  h1 {
    font-size: 1.6rem;
  }

  h2 {
    text-align: center;
  }

  ${(props) =>
    props.$modal &&
    css`
      h2 {
        line-height: 44px;
        font-size: 24px;
        margin-bottom: 1rem;
      }
    `}
  p {
    margin-bottom: 1.5rem;
  }

  form {
    padding: 0;
    box-shadow: none;
    margin: 0;
  }

  .form-container {
    width: 94%;
    margin: 0rem auto;

    h2 {
      color: #08674a;
    }

    .screen-one-header {
      text-align: center;

      svg {
        width: 74px;
        height: 74px;
      }
    }

    .select-benefits-header {
      .line-breaker {
        display: none;
      }

      @media ${MOBILE_AND_TABLET_MEDIA_QUERY} {
        flex-direction: column;
        text-align: center;
        align-items: center;
        .line-breaker {
          display: block;
        }
      }

      display: flex;
      gap: 1rem;
      text-align: left;

      h1 {
        line-height: 2rem;
        margin: 0;
        margin-bottom: 0.5rem;
      }

      p {
        margin: 0;
        font-size: 1rem;
      }

      svg {
        width: 74px;
        height: 74px;
      }
    }

    @media ${DESKTOP_MEDIA_QUERY} {
      ul {
        width: 360px;
        margin: 0 auto;
      }

      ${(props) =>
        props.$modal &&
        css`
          ul {
            width: 100%;
          }
        `}

      h1 {
        color: #08674a;
        font-family: 'Noto Sans HK', 'sans-serif';
        font-weight: 600;
      }
    }
  }

  .error-message {
    margin-top: 0.5rem;
    text-align: center;
    font-weight: bold;
    color: #ed0000;
  }

  .actions-container {
    button {
      margin: 0;
    }

    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;

    @media ${DESKTOP_MEDIA_QUERY} {
      flex-direction: row;
      margin: 2rem auto 0;
      width: 364px;
    }
  }

  .outcome-thank-you {
    text-align: center;
  }

  .bold {
    font-weight: 700;
  }
`
export const StyledScrollerBox = styled.div`
  border: 1px solid #dadada;
  border-radius: 5px;
  padding: 2rem 1.4rem;
  height: 60vh;
  overflow: scroll;
  margin-top: 1.5rem;
`
export const StyledScrollerBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const StyledHorizontalRule = styled.hr`
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 0 0 1rem 0;
`

export const StyledCheckboxContainer = styled.li`
  margin-left: 1rem;
  display: flex;
  align-items: center;

  label {
    cursor: pointer;
  }
`

StyledButtonContainer.displayName = 'StyledButtonContainer'
StyledError.displayName = 'StyledError'
StyledForm.displayName = 'StyledForm'
StyledWrapper.displayName = 'StyledWrapper'
StyledLineBreak.displayName = 'StyledLineBreak'
StyledOutcomeButton.displayName = 'StyledOutcomeButton'
StyledContentContainer.displayName = 'StyledContentContainer'
StyledCheckboxContainer.displayName = 'StyledCheckboxContainer'
StyledHorizontalRule.displayName = 'StyledHorizontalRule'
StyledRadioLabel.displayName = 'StyledRadioLabel'
