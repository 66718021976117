import { configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import reducer from 'Reducers'
import { rememberEnhancer } from 'redux-remember'

const store = configureStore({
  reducer,
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(
      Sentry.createReduxEnhancer(),
      rememberEnhancer(window.sessionStorage, [
        'locations',
        'ui',
        'client',
        'geolocation',
        'filters',
        'preScreener',
        'screener',
        'nextSteps',
        'form',
        'outcome',
        'myBenefits',
      ])
    )
  },
})

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer,
    preloadedState,
  })
}

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
