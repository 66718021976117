import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import InactiveCaseNotificationScreenOne from 'Components/InactiveCaseNotifications/InactiveCaseNotificationScreenOne/InactiveCaseNotificationScreenOne'
import InactiveCasesSelectBenefitsForm from 'Components/InactiveCaseNotifications/InactiveCasesSelectBenefitsForm/InactiveCasesSelectBenefitsForm'
import OutcomeThankYou from 'Components/Outcome/OutcomeThankYou/OutcomeThankYou'
import OutcomeUpToDate from 'Components/Outcome/OutcomeUpToDate/OutcomeUpToDate'
import LoadingIndicator from 'Components/Shared/LoadingIndicator/LoadingIndicator'
import { setId, setUnsubscribeToken } from 'Reducers/outcome'
import { API_BASE } from 'Shared/constants'
import { useAppDispatch } from 'Store/hooks'

export type InactiveCase = {
  id: string
  caseName: string
  category: string
}

const InactiveCaseNotifications = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [queryData, setQueryData] = useState<{
    logo?: string
    unsubscribeToken: string
    inactiveCases: InactiveCase[]
  }>({
    logo: '',
    unsubscribeToken: '',
    inactiveCases: [],
  })
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!id) return navigate('up-to-date')
    axios
      .get(`${API_BASE}/followups/clients/${id}/inactive_cases/`)
      .then(({ data }) => {
        setQueryData(data.data)
        setLoading(false)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setLoading(false)
          navigate('up-to-date')
        }
      })
  }, [id])

  useEffect(() => {
    if (typeof id === 'string') {
      dispatch(setId(id))
    }
  }, [id])

  useEffect(() => {
    if (queryData?.unsubscribeToken) {
      dispatch(setUnsubscribeToken(queryData.unsubscribeToken))
    }
  })

  return (
    <>
      {loading && <LoadingIndicator show={loading} />}
      {!loading && (
        <Routes>
          <Route
            path={'/'}
            element={
              <InactiveCaseNotificationScreenOne
                logo={queryData?.logo ?? null}
                token={queryData?.unsubscribeToken}
              />
            }
          />
          <Route
            path={'select-benefits'}
            element={
              <InactiveCasesSelectBenefitsForm
                logo={queryData?.logo ?? null}
                followups={queryData?.inactiveCases}
              />
            }
          />
          <Route
            path={'thank-you'}
            element={<OutcomeThankYou logo={queryData?.logo ?? null} />}
          />
          <Route
            path={'up-to-date'}
            element={<OutcomeUpToDate logo={queryData?.logo ?? null} />}
          />
        </Routes>
      )}
    </>
  )
}

export default InactiveCaseNotifications
