import React from 'react'
import { useMediaQuery } from 'react-responsive'
import CustomQuestionField from 'Components/CustomQuestions/CustomQuestionField/CustomQuestionField'
import { INPUT_TYPE_STRING } from 'Components/CustomQuestions/CustomQuestionField/Inputs/constants'
import {
  StyledBox,
  StyledCustomBox,
} from 'Components/Review/ReviewSection/style'
import { StyledSubHeading } from 'Components/Screener/style'
import UserVerificationStatusIcon from 'Components/Shared/Icon/UserVerificationStatusIcon'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'

import { IValueDisplay } from '..'

const ValueDisplay = ({
  name,
  value,
  verified,
  displayValue,
  format,
  isEditing,
  required = false,
  id,
  options,
  validator,
  parse,
  maxLength,
  inputTypePhone,
  dataType = INPUT_TYPE_STRING,
}: IValueDisplay & { isEditing: boolean }) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })

  const StyledBoxWraper = [
    'Login - Phone Number',
    'Alternate Phone Number',
    'Alternate Email',
  ].some((label) => label === name)
    ? StyledCustomBox
    : StyledBox
  const isPhoneOrEmail = [
    'Login - Phone Number',
    'Alternate Phone Number',
    'Login - Email',
    'Alternate Email',
  ].some((label) => label === name)

  return (
    <>
      {isEditing ? (
        <StyledBoxWraper>
          <CustomQuestionField
            format={format}
            name={id}
            label={name}
            dataType={dataType}
            options={options}
            required={required}
            validator={validator}
            maxLength={maxLength}
            inputTypePhone={inputTypePhone}
            parse={parse}
            className={`reviewPageContainer`}
          />
        </StyledBoxWraper>
      ) : (
        <StyledBoxWraper>
          {value !== 'Not Available' && (
            <>
              <StyledSubHeading
                reviewInfoCard={true}
                fontSize={isMobile ? '1rem' : '.75rem'}
                fontWeight={400}
                marginBottom='0.625rem'
              >
                {name}
              </StyledSubHeading>
              {value !== '' && (
                <StyledSubHeading
                  reviewInfoCard={true}
                  fontSize='1rem'
                  fontWeight={600}
                  marginBottom='0'
                >
                  {displayValue ?? value}

                  {isPhoneOrEmail && value !== 'Not Available' && (
                    <UserVerificationStatusIcon verified={verified} />
                  )}
                </StyledSubHeading>
              )}
            </>
          )}
        </StyledBoxWraper>
      )}
    </>
  )
}

export default ValueDisplay
