import { gql } from '__generated__/gql'

export const GET_NEXT_STEPS_LOCATION_DATA = gql(`
  query getNextStepsLocationData($id: ID!) {
    client(id: $id) {
      id
      screening {
        id
        location {
          id
          customNextSteps
          name
          organization {
            id
            name
          }
        }
      }
    }
  }
`)

export const GET_NEXT_STEPS_GUIDES = gql(`
query getNextSteps($clientLocationId: ID!) {
  nextSteps(clientLocationId: $clientLocationId) {
  id
    service { 
    id 
    name 
    mainCategory {
      id
      name
      openEligibilityKey
    }
    toolkitUrl 
    }
  }
}`)

export const GET_CLIENT_LOCATION_ID = gql(`
query getClientLocationId($id: ID!) {
    client(id: $id) {
      id
      clientLocations {
        id
      }
    }
  }`)

export const GET_HELPFUL_LINKS = gql(`
 query getHelpfulLinks($id: ID!) {
      client(id: $id) {
          clientLocations {
              location {
                  helpfulLinks {
                      id
                      link
                      description
                      title
                      active
                  }
              }
          }
      }
  }
`)
