import React, { useEffect, useState } from 'react'
import MediaQuery from 'react-responsive'
import { useLocation } from 'react-router-dom'
import { LoadScriptNext } from '@react-google-maps/api'
import { filterLocations } from 'Actions/filters'
import { updateCoordinates } from 'Actions/geolocation'
import LocationRequest from 'Pages/LocationRequest/LocationRequest'
import {
  CUSTOM_MOBILE_MEDIA_QUERY,
  customDesktopAndTabletMediaQuery,
  GOOGLE_API_KEY,
} from 'Shared/constants'
import { useAppDispatch, useAppSelector } from 'Store/hooks'
import useDeepCompareEffect from 'use-deep-compare-effect'

import DesktopLocations from './DesktopLocations'
import MobileLocations from './MobileLocations'

const Locations = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const isCaseManagement = location.pathname.includes('case-management')
  const { query, serviceTags } = useAppSelector((state) => state.filters)

  const coordinates = useAppSelector((state) => state.geolocation.coordinates)
  const zipCode = useAppSelector((state) => state.geolocation?.resourcesZipCode)
  const registeredZipCode = useAppSelector((state) => state.client?.zipCode)
  const searchTerm = useAppSelector((state) => state.filters.query)
  const [zipCodeError, setZipCodeError] = useState(false)
  const [searchThisArea, setSearchThisArea] = useState(false)
  const [showSearchThisAreaButton, setShowSearchThisAreaButton] =
    useState(false)
  const [initialPageLoad, setInitialPageLoad] = useState(true)
  setTimeout(() => {
    setInitialPageLoad(false)
  }, 1000)

  useEffect(() => {
    dispatch(filterLocations(true))
  }, [serviceTags, query, zipCode, searchTerm])

  useDeepCompareEffect(() => {
    if (initialPageLoad) return
    setShowSearchThisAreaButton(true)
  }, [coordinates])

  useEffect(() => {
    if (searchThisArea) {
      dispatch(updateCoordinates(coordinates))
      dispatch(filterLocations())
      setShowSearchThisAreaButton(false)
      setSearchThisArea(false)
    }
  }, [searchThisArea])

  const hasZipCode = !!(registeredZipCode ?? zipCode)

  return (
    <>
      {!hasZipCode && !isCaseManagement && <LocationRequest />}
      <LoadScriptNext
        id='script-loader'
        googleMapsApiKey={`${GOOGLE_API_KEY}&loading=async` || ''}
      >
        <>
          <MediaQuery query={CUSTOM_MOBILE_MEDIA_QUERY}>
            <MobileLocations
              registeredZipCode={zipCode}
              searchTerm={searchTerm}
              setSearchThisArea={setSearchThisArea}
              showSearchThisAreaButton={showSearchThisAreaButton}
              setZipCodeError={setZipCodeError}
              zipCodeError={zipCodeError}
            />
          </MediaQuery>

          <MediaQuery query={customDesktopAndTabletMediaQuery}>
            <DesktopLocations
              registeredZipCode={zipCode}
              searchTerm={searchTerm}
              showSearchThisAreaButton={showSearchThisAreaButton}
              setSearchThisArea={setSearchThisArea}
              setZipCodeError={setZipCodeError}
              zipCodeError={zipCodeError}
            />
          </MediaQuery>
        </>
      </LoadScriptNext>
    </>
  )
}

export default Locations
