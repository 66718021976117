import React, { ChangeEvent } from 'react'
import FormError from 'Components/Shared/FormError/FormError'
import { stringToKebabCase } from 'Shared/helpers'

import {
  StyledFormControl,
  StyledLabel,
  StyledOption,
  StyledSelect,
} from './style'

export type ListInputProps = {
  id: string
  input: {
    onChange: (value: string) => void
    value: string
  }
  meta: {
    touched: boolean
    error: string
    submitError: string
  }
  name: string
  options: Array<string | { value: string; label: string }>
  dataTestId: string
  inputLabelText?: string
  placeholder?: string
  label: string
}
const ListInput = ({
  id,
  input: { onChange, value, ...rest },
  meta: { touched, error, submitError },
  name,
  options,
  dataTestId,
  inputLabelText = 'Select an Option',
  placeholder = '',
  label,
}: ListInputProps) => {
  return (
    <StyledFormControl
      variant='standard'
      fullWidth
      error={touched && (!!error || !!submitError)}
    >
      <StyledLabel>{label}</StyledLabel>
      <StyledSelect
        {...rest}
        id={name}
        labelId={`${name}-label`}
        label={value ? '' : inputLabelText}
        dataTestId={dataTestId}
        value={value}
        onChange={(event: ChangeEvent<HTMLSelectElement>) =>
          onChange(event.target.value)
        }
        error={!!error && touched}
        name={name}
      >
        {[placeholder, ...options].map((option, i) => {
          const optionType = typeof option

          const value =
            optionType === 'string'
              ? (option as string)
              : (option as { value: string })?.value
          const label =
            optionType === 'string'
              ? (option as string)
              : (option as { label: string })?.label

          return (
            <StyledOption
              key={i}
              value={value}
              id={stringToKebabCase(`${id}-${label}`)}
            >
              {label}
            </StyledOption>
          )
        })}
      </StyledSelect>
      <FormError touched={touched} error={error ?? submitError} />
    </StyledFormControl>
  )
}

export default ListInput
