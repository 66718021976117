import React, { useEffect, useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material'
import FormError from 'Components/Shared/FormError/FormError'

import { StyledLabel } from '../CustomQuestionField/Inputs/ListInput/style'

import { StyledButtonFilter, StyledButtonFilterWrapper } from './style'

export type ListInputProps = {
  input: {
    onChange: (value: string) => void
    value: string
  }
  meta: {
    touched: boolean
    error: string
    submitError: string
  }

  options: Array<string | { value: string; label: string }>
}

export default function MultiSelect({
  input: { onChange, value },
  meta: { touched, error, submitError },
  options,
}: ListInputProps) {
  const [selectedNames, setSelectedNames] = useState(
    value && value ? value.split(',') : []
  )
  const [isSelectAllActive, setIsSelectAllActive] = useState(false)
  const [isClearAllActive, setIsClearAllActive] = useState(false)
  useEffect(() => {
    setSelectedNames(value ? value.split(',') : [])
  }, [value])

  const handleCheckboxChange = (optionValue: string) => {
    let newSelected
    if (selectedNames.includes(optionValue)) {
      newSelected = selectedNames.filter((name) => name !== optionValue)
    } else {
      newSelected = [...selectedNames, optionValue]
    }
    setSelectedNames(newSelected)
    onChange(newSelected.join(','))
  }
  const handleSelectAll = () => {
    const allOptionValues = options.map((option) =>
      typeof option === 'string' ? option : option.value
    )
    const isAllSelected = allOptionValues.every((value) =>
      selectedNames.includes(value)
    )
    setSelectedNames(allOptionValues)

    if (!isAllSelected || selectedNames.length === 0) {
      setSelectedNames(allOptionValues)
      onChange(allOptionValues.join(','))
      setIsSelectAllActive(true)
      setIsClearAllActive(false)
    } else {
      setSelectedNames([])
      onChange('')
      setIsSelectAllActive(false)
      setIsClearAllActive(true)
    }
  }

  useEffect(() => {
    console.log('Selected names after update:', selectedNames)
  }, [selectedNames])

  const handleClearAll = () => {
    setSelectedNames([])
    onChange('')
    setIsClearAllActive(true)
    setIsSelectAllActive(false)
  }
  return (
    <>
      <StyledLabel>Example</StyledLabel>
      <FormControl
        sx={{
          width: '100%',
          background: '#F9F9F9',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#D8D8D8', // Set the border color only here
            },
          },
        }}
        title={selectedNames}
      >
        <InputLabel id='select-label'>Select An Option</InputLabel>
        <Select
          labelId='select-label'
          multiple
          value={selectedNames}
          input={<OutlinedInput label='Select An Option' />}
          renderValue={(selected) => (selected as string[]).join(', ')}
          MenuProps={{
            PaperProps: {
              sx: {
                padding: '12px',
                boxShadow: 'none',
                border: '1px solid #EFEFEF',
                borderRadius: '16px',
              },
            },
          }}
        >
          <StyledButtonFilterWrapper>
            <StyledButtonFilter
              onClick={handleSelectAll}
              isActive={isSelectAllActive}
            >
              Select All
            </StyledButtonFilter>
            <StyledButtonFilter
              onClick={handleClearAll}
              isActive={isClearAllActive}
            >
              Clear
            </StyledButtonFilter>
          </StyledButtonFilterWrapper>
          {options.map((option, i) => {
            const optionValue =
              typeof option === 'string' ? option : option.value
            const optionLabel =
              typeof option === 'string' ? option : option.label
            return (
              <MenuItem
                aria-label={`Select ${optionLabel}`}
                tabIndex={0}
                onClick={() => handleCheckboxChange(optionValue)}
                key={i}
                value={optionValue}
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  gap: '8px',
                  margin: 0,
                  padding: '8px',
                  marginBottom: '8px',
                  height: 'fit-content',

                  '&.Mui-selected': {
                    backgroundColor: '#EEF9F6 !important',
                    color: '#08674A',
                    borderRadius: '8px',
                  },
                  '&.Mui-selected:hover': {
                    backgroundColor: '#c0c0c0',
                  },
                  '& .MuiTypography-root': {
                    margin: 0,
                  },
                }}
              >
                <FormControlLabel
                  style={{ marginRight: 0 }}
                  control={
                    <Checkbox
                      style={{
                        color: selectedNames.includes(optionValue)
                          ? '#08674A'
                          : undefined,
                        width: 'fit-content',
                        height: 0,
                      }}
                      defaultChecked
                      checked={selectedNames.includes(optionValue)}
                      onChange={() => handleCheckboxChange(optionValue)}
                      inputProps={{
                        'aria-label': `Checkbox for ${optionLabel}`,
                      }}
                      icon={
                        <span
                          style={{
                            border: '1px solid #D8D8D8',
                            borderRadius: '4px',
                            width: 20,
                            height: 20,
                            backgroundColor: '#FFF',
                          }}
                        />
                      }
                    />
                  }
                />
                {optionLabel}
              </MenuItem>
            )
          })}
        </Select>
        <FormError touched={touched} error={error ?? submitError} />
      </FormControl>
    </>
  )
}
