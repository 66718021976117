import React from 'react'

const emergencyIconPath = (
  <>
    <g id='b'>
      <g transform='translate(-20 -24)'>
        <path
          d='M2.42,18C15.17-4.26,77.364-7.56,92.919,17.67s-14.826,88.7-34.6,90.955S44.173,69.732,37.667,58.578-10.334,40.253,2.42,18Z'
          transform='matrix(-0.799, 0.602, -0.602, -0.799, 147.248, 99.673)'
          fill='#F7A158'
          opacity='0.7'
        />
        <g
          transform='matrix(3.950142, 0, 0, 3.950142, -718.820923, -277.537689)'
          opacity='0.8'
        >
          <path
            d='M 196.243 91.817 L 196.809 91.817 L 196.809 86.606 C 196.809 83.149 199.687 80.259 203.131 80.259 C 206.626 80.259 209.454 83.149 209.454 86.606 L 209.454 91.817 L 210.019 91.817 C 210.739 91.817 211.304 92.384 211.304 93.107 L 211.304 95.274 C 211.304 95.996 210.739 96.564 210.019 96.564 L 196.243 96.564 C 195.575 96.564 195.01 95.996 195.01 95.274 L 195.01 93.107 C 195.01 92.384 195.575 91.817 196.243 91.817 Z M 197.991 91.817 L 208.271 91.817 L 208.271 86.606 C 208.271 83.768 205.958 81.446 203.131 81.446 C 200.304 81.446 197.991 83.768 197.991 86.606 L 197.991 91.817 Z M 210.019 93.003 L 196.243 93.003 C 196.192 93.003 196.141 93.055 196.141 93.107 L 196.141 95.274 C 196.141 95.325 196.192 95.377 196.243 95.377 L 210.019 95.377 C 210.07 95.377 210.122 95.325 210.122 95.274 L 210.122 93.107 C 210.122 93.055 210.07 93.003 210.019 93.003 Z'
            fill='#151515'
          />
          <path
            d='M 203.131 83.664 C 202.823 83.664 202.566 83.406 202.566 83.096 C 202.566 82.735 202.823 82.477 203.131 82.477 C 205.393 82.477 207.243 84.335 207.243 86.605 C 207.243 86.915 206.986 87.224 206.627 87.224 C 206.318 87.224 206.061 86.915 206.061 86.605 C 206.061 85.006 204.725 83.664 203.131 83.664 Z'
            fill='#151515'
          />
          <path
            d='M 202.566 76.131 C 202.566 75.822 202.823 75.564 203.131 75.564 C 203.44 75.564 203.748 75.822 203.748 76.131 L 203.748 78.814 C 203.748 79.124 203.44 79.433 203.131 79.433 C 202.823 79.433 202.566 79.124 202.566 78.814 L 202.566 76.131 Z'
            fill='#151515'
          />
          <path
            d='M 210.07 78.815 C 210.327 78.557 210.687 78.557 210.944 78.815 C 211.15 79.021 211.15 79.382 210.944 79.64 L 209.042 81.498 C 208.837 81.756 208.425 81.756 208.22 81.498 C 207.963 81.291 207.963 80.93 208.22 80.672 L 210.07 78.815 Z'
            fill='#151515'
          />
          <path
            d='M 213.566 86.038 C 213.874 86.038 214.131 86.296 214.131 86.606 C 214.131 86.915 213.874 87.225 213.566 87.225 L 210.893 87.225 C 210.585 87.225 210.328 86.915 210.328 86.606 C 210.328 86.296 210.585 86.038 210.893 86.038 L 213.566 86.038 Z'
            fill='#151515'
          />
          <path
            d='M 195.37 79.64 C 195.113 79.382 195.113 79.021 195.37 78.815 C 195.575 78.557 195.935 78.557 196.192 78.815 L 198.043 80.672 C 198.3 80.93 198.3 81.291 198.043 81.498 C 197.837 81.756 197.477 81.756 197.22 81.498 L 195.37 79.64 Z'
            fill='#151515'
          />
          <path
            d='M 192.697 87.225 C 192.388 87.225 192.131 86.915 192.131 86.606 C 192.131 86.296 192.388 86.038 192.697 86.038 L 195.369 86.038 C 195.678 86.038 195.935 86.296 195.935 86.606 C 195.935 86.915 195.678 87.225 195.369 87.225 L 192.697 87.225 Z'
            fill='#151515'
          />
          <path
            d='M 196.243 91.817 L 196.809 91.817 L 196.809 86.606 C 196.809 83.149 199.687 80.259 203.131 80.259 C 206.626 80.259 209.454 83.149 209.454 86.606 L 209.454 91.817 L 210.019 91.817 C 210.739 91.817 211.304 92.384 211.304 93.107 L 211.304 95.274 C 211.304 95.996 210.739 96.564 210.019 96.564 L 196.243 96.564 C 195.575 96.564 195.01 95.996 195.01 95.274 L 195.01 93.107 C 195.01 92.384 195.575 91.817 196.243 91.817 Z M 197.991 91.817 L 208.271 91.817 L 208.271 86.606 C 208.271 83.768 205.958 81.446 203.131 81.446 C 200.304 81.446 197.991 83.768 197.991 86.606 L 197.991 91.817 Z M 210.019 93.003 L 196.243 93.003 C 196.192 93.003 196.141 93.055 196.141 93.107 L 196.141 95.274 C 196.141 95.325 196.192 95.377 196.243 95.377 L 210.019 95.377 C 210.07 95.377 210.122 95.325 210.122 95.274 L 210.122 93.107 C 210.122 93.055 210.07 93.003 210.019 93.003 Z'
            stroke='#151515'
            strokeWidth='0.2'
          />
          <path
            d='M 203.131 83.664 C 202.823 83.664 202.566 83.406 202.566 83.096 C 202.566 82.735 202.823 82.477 203.131 82.477 C 205.393 82.477 207.243 84.335 207.243 86.605 C 207.243 86.915 206.986 87.224 206.627 87.224 C 206.318 87.224 206.061 86.915 206.061 86.605 C 206.061 85.006 204.725 83.664 203.131 83.664 Z'
            stroke='#151515'
            strokeWidth='0.2'
          />
          <path
            d='M 202.566 76.131 C 202.566 75.822 202.823 75.564 203.131 75.564 C 203.44 75.564 203.748 75.822 203.748 76.131 L 203.748 78.814 C 203.748 79.124 203.44 79.433 203.131 79.433 C 202.823 79.433 202.566 79.124 202.566 78.814 L 202.566 76.131 Z'
            stroke='#151515'
            strokeWidth='0.2'
          />
          <path
            d='M 210.07 78.815 C 210.327 78.557 210.687 78.557 210.944 78.815 C 211.15 79.021 211.15 79.382 210.944 79.64 L 209.042 81.498 C 208.837 81.756 208.425 81.756 208.22 81.498 C 207.963 81.291 207.963 80.93 208.22 80.672 L 210.07 78.815 Z'
            stroke='#151515'
            strokeWidth='0.2'
          />
          <path
            d='M 213.566 86.038 C 213.874 86.038 214.131 86.296 214.131 86.606 C 214.131 86.915 213.874 87.225 213.566 87.225 L 210.893 87.225 C 210.585 87.225 210.328 86.915 210.328 86.606 C 210.328 86.296 210.585 86.038 210.893 86.038 L 213.566 86.038 Z'
            stroke='#151515'
            strokeWidth='0.2'
          />
          <path
            d='M 195.37 79.64 C 195.113 79.382 195.113 79.021 195.37 78.815 C 195.575 78.557 195.935 78.557 196.192 78.815 L 198.043 80.672 C 198.3 80.93 198.3 81.291 198.043 81.498 C 197.837 81.756 197.477 81.756 197.22 81.498 L 195.37 79.64 Z'
            stroke='#151515'
            strokeWidth='0.2'
          />
          <path
            d='M 192.697 87.225 C 192.388 87.225 192.131 86.915 192.131 86.606 C 192.131 86.296 192.388 86.038 192.697 86.038 L 195.369 86.038 C 195.678 86.038 195.935 86.296 195.935 86.606 C 195.935 86.915 195.678 87.225 195.369 87.225 L 192.697 87.225 Z'
            stroke='#151515'
            strokeWidth='0.2'
          />
        </g>
      </g>
    </g>
  </>
)

export default emergencyIconPath
