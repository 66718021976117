import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link, useNavigate } from 'react-router-dom'
import { Service, ServiceCategory } from '__generated__/graphql'
import { useQuery } from '@apollo/client'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import BenefitGuidesList from 'Components/NextSteps/BenefitGuidesList'
import { mapAndGroupBenefitsGuides } from 'Components/NextSteps/helpers'
import HelpfulLinksList from 'Components/NextSteps/HelpfulLinksList'
import { StyledDynamicCtaButton } from 'Components/Screener/style'
import { StyledMainHeading, StyledParagraph } from 'Components/Screener/style'
import { Dictionary } from 'lodash'
import NextStepsDefaultCopy from 'Pages/NextSteps/NextStepsDefaultCopy'
import NextStepsLoading from 'Pages/NextSteps/NextStepsLoading'
import {
  Benefitwrapper,
  CustomStyledBox,
  CustomStyledBoxDescription,
  CustomStyledBoxTitle,
  StyledCustomContent,
  StyledFlexWrapper,
  StyledWrapper,
} from 'Pages/NextSteps/style'
import {
  MOBILE_AND_TABLET_MEDIA_QUERY,
  MOBILE_LANDSCAPE_AND_PORTRAIT_MEDIA_QUERY,
} from 'Shared/constants'
import { singleStopGreen } from 'Shared/Theme'
import { useAppSelector } from 'Store/hooks'
import { Container } from 'styled-bootstrap-grid'

import {
  GET_CLIENT_LOCATION_ID,
  GET_HELPFUL_LINKS,
  GET_NEXT_STEPS_GUIDES,
  GET_NEXT_STEPS_LOCATION_DATA,
} from './gql'

export type BenefitGuide = Pick<Service, 'id' | 'name' | 'toolkitUrl'> & {
  categoryName: ServiceCategory['name']
  openEligibilityKey: ServiceCategory['openEligibilityKey']
}

// adding tabs component
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
const BenefitGuides = () => {
  const { id: clientId } = useAppSelector((state) => state.client)
  const navigate = useNavigate()
  const isTabletAndMobile = useMediaQuery({
    query: MOBILE_AND_TABLET_MEDIA_QUERY,
  })
  const isMobile = useMediaQuery({
    query: MOBILE_LANDSCAPE_AND_PORTRAIT_MEDIA_QUERY,
  })
  const [customContent, setCustomContent] = useState('')
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const [benefitGuides, setBenefitGuides] = useState<
    Dictionary<BenefitGuide[]> | undefined
  >(undefined)

  const { data: customTextData, loading: customTextLoading } = useQuery(
    GET_NEXT_STEPS_LOCATION_DATA,
    {
      variables: {
        id: clientId,
      },
      skip: !clientId,
    }
  )

  const { data: clientLocationData } = useQuery(GET_CLIENT_LOCATION_ID, {
    variables: {
      id: clientId,
    },
  })

  const { data: benefitGuidesData, loading: benefitGuidesLoading } = useQuery(
    GET_NEXT_STEPS_GUIDES,
    {
      variables: {
        clientLocationId:
          clientLocationData?.client?.clientLocations[0]?.id ?? '',
      },
      skip: !clientLocationData,
    }
  )

  const id = useAppSelector((state) => state.client.id)
  const { data: HelpfulLinksData, loading: HelpfulLinksLoading } = useQuery(
    GET_HELPFUL_LINKS,
    {
      variables: {
        id,
      },
      skip: !id,
    }
  )

  const customNextSteps =
    customTextData?.client?.screening?.location?.customNextSteps

  useEffect(() => {
    setCustomContent(customNextSteps)
  }, [customNextSteps])

  useEffect(() => {
    if (benefitGuidesData) {
      setBenefitGuides(mapAndGroupBenefitsGuides(benefitGuidesData))
    }
  }, [benefitGuidesData])

  const hasHelpfulLinks =
    (HelpfulLinksData?.client?.clientLocations?.[0]?.location?.helpfulLinks
      ?.length ?? 0) > 0

  const activeLinks =
    HelpfulLinksData?.client?.clientLocations?.[0]?.location?.helpfulLinks?.filter(
      (link) => link.active
    ) || []

  return customTextLoading ? (
    <NextStepsLoading />
  ) : (
    <Container
      style={{
        paddingTop: !isMobile ? '2rem' : '0',
        overflowX: isMobile ? 'hidden' : 'visible',
      }}
    >
      <StyledWrapper>
        <div className='styledWrapper__columns'>
          <div className=''>
            <StyledFlexWrapper justifyContent='space-between'>
              <StyledMainHeading
                className='underline'
                style={{
                  color: 'hsla(162, 85%, 22%, 1)',
                  fontWeight: '600',
                  paddingBottom: isMobile ? '8px' : '0',
                  fontSize: isMobile ? '20px' : '24px',
                }}
              >
                Next Steps
              </StyledMainHeading>
            </StyledFlexWrapper>

            {customContent ? (
              <StyledCustomContent
                dangerouslySetInnerHTML={{ __html: customContent }}
              />
            ) : (
              <NextStepsDefaultCopy />
            )}
          </div>
          <div className=''>
            <CustomStyledBox>
              <CustomStyledBoxTitle>
                More Resources Close to Home
              </CustomStyledBoxTitle>
              <CustomStyledBoxDescription>
                Our extensive map of resources can help you find programs and
                services in your local community.<br></br>
                <br></br> We've started a search with your ZIP code{' '}
                <span></span> find more by entering a keyword or selecting a
                category.
              </CustomStyledBoxDescription>
              <StyledDynamicCtaButton
                component={Link}
                to={'/locations/list'}
                onClick={() => navigate('/locations/list')}
                background={'hsla(162, 85%, 22%, 1)'}
                textshadow='none'
                border={'0.0625rem solid ' + singleStopGreen}
                margin='0'
                padding={'7.5px 16px'}
                fontSize={isTabletAndMobile ? '14px' : '16px'}
                fontWeight={isTabletAndMobile ? '400' : '600'}
                width={isMobile ? '100%' : 'auto'}
                height={'auto'}
                justcont={isTabletAndMobile ? 'center' : ''}
                borderhover={'0.0625rem solid ' + singleStopGreen}
                $nextSteps={true}
                role='link'
                label={'Visit the interactive map now'}
                style={{
                  borderRadius: '56px',
                  textAlign: 'center',
                  textTransform: 'none',
                }}
              >
                Visit the interactive map now
              </StyledDynamicCtaButton>
            </CustomStyledBox>
          </div>
        </div>
      </StyledWrapper>
      <StyledWrapper>
        <Box
          sx={{
            borderBottom: 0,
            borderColor: 'divider',
            paddingBottom: {
              xs: '16px',
              sm: '24px',
              md: '24px',
              lg: '24px',
            },
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
            TabIndicatorProps={{ sx: { backgroundColor: '#08674A' } }}
            sx={{
              '.MuiTabs-flexContainer': {
                borderBottom: 'none',
              },
            }}
          >
            <Tab
              label='Benefits Guide'
              {...a11yProps(0)}
              sx={{
                '&.Mui-selected': { color: '#08674A' },
                color: '#545456',
              }}
            />
            {activeLinks.length > 0 && (
              <Tab
                label='Helpful Links'
                {...a11yProps(1)}
                sx={{
                  '&.Mui-selected': { color: '#08674A' },
                  color: '#545456',
                }}
              />
            )}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Benefitwrapper>
            <StyledMainHeading>Benefit Guides</StyledMainHeading>
            <StyledParagraph
              fontSize='16px !important'
              color='#545456 !important'
              fontWeight='400 !important'
            >
              Downloadable benefit guides for each of your likely eligible
              services are linked below.
            </StyledParagraph>
            {benefitGuidesLoading && <NextStepsLoading />}
            {benefitGuides && (
              <BenefitGuidesList benefitGuides={benefitGuides} />
            )}
          </Benefitwrapper>
        </CustomTabPanel>

        {hasHelpfulLinks && (
          <CustomTabPanel value={value} index={1}>
            <Benefitwrapper>
              <StyledMainHeading>Helpful Links</StyledMainHeading>
              <StyledParagraph
                color='#545456 !important'
                fontWeight='400 !important'
              >
                Downloadable benefit guides for each of your likely eligible
                services are linked below.
              </StyledParagraph>
              {HelpfulLinksLoading && <NextStepsLoading />}
              {HelpfulLinksData && (
                <HelpfulLinksList activeLinks={activeLinks} />
              )}
            </Benefitwrapper>
          </CustomTabPanel>
        )}
      </StyledWrapper>
      <style>{`
        @media (max-width: 480px) {
          .underline {
            position: relative;
          }
          .underline::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: -30px;
            height: 1px;
            width: calc(100% + 60px);
            background: hsla(0, 0%, 94%, 1) !important;
          }
        }
      `}</style>
    </Container>
  )
}

export default BenefitGuides
