import { withStyles } from '@material-ui/core'
import { Button } from '@material-ui/core'
import theme from 'Shared/Theme/ssTheme'

export const StyledEditButtonWrapper = withStyles({
  root: {
    padding: '0.5rem 1rem',
    color: theme.palette.primary.darken10,
    fontSize: '.75rem',
    textTransform: 'capitalize',
  },
})(Button)

export const StyledCancelButtonWrapper = withStyles({
  root: {
    padding: '0.5rem 1rem',
    color: theme.palette.color.black,
    fontSize: '.75rem',
    textTransform: 'capitalize',
    marginInlineEnd: '1rem',
    backgroundColor: theme.palette.secondary.lightGrey,
  },
})(Button)

export const StyledReviewCancelButton = withStyles({
  root: {
    padding: '7px 16px 9px 16px !important',
    color: theme.palette.color.black,
    fontSize: '.75rem',
    textTransform: 'capitalize',
    marginInlineEnd: '1rem',
    backgroundColor: '#FFF !important',
    border: '1px solid #EFEFEF !important',
    borderRadius: '56px',
    fontWeight: '600',
  },
})(Button)
export const StyledReviewButton = withStyles({
  root: {
    padding: '7px 16px 9px 16px !important',
    color: 'white',
    fontSize: '.75rem',
    textTransform: 'capitalize',
    marginInlineEnd: '1rem',
    backgroundColor: '#08674A !important',
    border: '1px solid #EFEFEF !important',
    borderRadius: '56px',
    fontWeight: '600',

    '@media (max-width: 576px)': {
      '&.Review-edit': {
        padding: '8px !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  },
})(Button)

StyledEditButtonWrapper.displayName = 'StyledEditButtonWrapper'
StyledCancelButtonWrapper.displayName = 'StyledCancelButtonWrapper'
StyledReviewCancelButton.displayName = 'StyledReviewCancelButton'
StyledReviewButton.displayName = 'StyledReviewSaveButton'
