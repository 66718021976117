import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  StyledResourcesBtn,
  StyledResourcesBtnWrapper,
  StyledResourcesDeleteCard,
  StyledResourcesHeading,
  StyledResourcesText,
} from 'Components/ClientPortal/MyResources/style'

import { TOGGLE_FAVORITE_LOCATION } from './gql'
import Loading from './Loading'
import { Warningsvg } from './Warningsvg'

type DeleteUiProps = {
  setdeletedstate: React.Dispatch<React.SetStateAction<boolean>>
  resourceData: object
  onDeleteToggle: () => void
}
const DeleteUi: React.FC<DeleteUiProps> = ({
  resourceData,
  onDeleteToggle,
}) => {
  const [isloading, setIsLoading] = useState(false)

  const [handleToggleFavoriteLocation] = useMutation(TOGGLE_FAVORITE_LOCATION)
  const CancelDelete = () => {
    onDeleteToggle()
  }

  const RemoveItem = () => {
    setIsLoading(true)
    const createFindHelpLocationInput = {
      officeId: resourceData?.findHelpLocation?.officeId,
      providerId: resourceData?.findHelpLocation?.providerId,
      programId: resourceData?.findHelpLocation?.programId,
      providerName: resourceData?.findHelpLocation?.providerName,
      programName: resourceData?.findHelpLocation?.programName,
      zipCode: resourceData?.findHelpLocation?.zipCode,
      officeName: resourceData?.findHelpLocation?.name,
      serviceTags: resourceData?.findHelpLocation?.categories,
    }
    const toggleFavoriteLocation = async () => {
      setIsLoading(true)
      try {
        await handleToggleFavoriteLocation({
          variables: {
            input: {
              createFindHelpLocationInput,
              favoriteLocation: false,
            },
          },
        })
      } catch (error) {
        alert('something went wrong ,please try again')
        setIsLoading(false)
      } finally {
        setIsLoading(false)
        onDeleteToggle()
        window.location.reload()
      }
    }
    toggleFavoriteLocation()
  }
  return (
    <StyledResourcesDeleteCard>
      <Warningsvg />
      <StyledResourcesHeading>
        Are you sure you want to remove this resource?
      </StyledResourcesHeading>
      <StyledResourcesText>
        <span style={{ fontWeight: '600' }}>
          {resourceData?.findHelpLocation?.programName}
        </span>{' '}
        <span>will be removed from your My Resources list.</span>
      </StyledResourcesText>
      <StyledResourcesBtnWrapper>
        <StyledResourcesBtn
          background='transparent'
          color='black'
          onClick={CancelDelete}
        >
          Cancel
        </StyledResourcesBtn>
        <StyledResourcesBtn
          background='#E31010'
          color='#FFF'
          onClick={RemoveItem}
        >
          Remove
        </StyledResourcesBtn>
      </StyledResourcesBtnWrapper>
      {isloading && <Loading />}
    </StyledResourcesDeleteCard>
  )
}

export default DeleteUi
