import React from 'react'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import {
  COMPLETE_SERVICE_CASE,
  GET_CASES,
} from 'Components/ClientPortal/MyBenefits/gql'
import BenefitModalPageTemplate from 'Components/ClientPortal/MyBenefits/UpdateBenefitModal/BenefitModalTemplate'
import ButtonsContainer from 'Components/Outcome/OutcomeCashBenefitForm/ButtonsContainer'
import {
  StyledContentContainer,
  StyledLineBreak,
} from 'Components/Outcome/style'
import StyledRadioButton from 'Components/Outcome/ui/StyledRadioButton'
import { FORM_ERROR } from 'final-form'
import {
  closeEditBenefitStatusModal,
  updateDenial,
} from 'Reducers/myBenefitsSlice'
import { DENIAL_MAPPINGS } from 'Shared/constants'
import { useAppSelector } from 'Store/hooks'

const UpdateBenefitDenialModal = () => {
  const { benefitName, caseId, denial, denialReasons } = useAppSelector(
    (state) => state.myBenefits
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [handleCompleteMutation] = useMutation(COMPLETE_SERVICE_CASE)
  const onSubmit = ({ denialType }) => {
    dispatch(updateDenial({ denial: denialType }))
    handleCompleteMutation({
      variables: {
        serviceCaseId: caseId,
        outcomeType: 'DENIED',
        denialReason: denialType.toUpperCase(),
      },
      refetchQueries: [GET_CASES],
    }).then(() => {
      navigate('..')
      dispatch(closeEditBenefitStatusModal())
    })
  }

  return (
    <BenefitModalPageTemplate
      handleCancel={() => {
        navigate('..')
        dispatch(closeEditBenefitStatusModal())
      }}
    >
      <StyledContentContainer $modal>
        <section className='form-container'>
          <h2>
            <StyledLineBreak>Why were you denied</StyledLineBreak>
            {benefitName}?
          </h2>
          <Form
            initialValues={denial ? { denialType: denial } : {}}
            onSubmit={onSubmit}
            validate={({ denialType }) =>
              denialType ? {} : { [FORM_ERROR]: 'Please choose an option' }
            }
            render={({ handleSubmit, values, error, touched }) => (
              <form onSubmit={handleSubmit}>
                <ul>
                  {DENIAL_MAPPINGS.filter((mapping) =>
                    denialReasons.includes(mapping.value?.toUpperCase())
                  ).map((mapping, index) => {
                    const { label, value } = mapping
                    const isChecked = values.denialType === value
                    const radioId = `${value}+${index}`

                    return (
                      <li key={radioId}>
                        <StyledRadioButton
                          id={radioId}
                          name='denialType'
                          value={value}
                          $isChecked={isChecked}
                          label={label}
                        />
                      </li>
                    )
                  })}
                </ul>
                {error && touched?.denialType && (
                  <p className='error-message'>{error}</p>
                )}
                <ButtonsContainer
                  backButtonCallback={() => {
                    navigate('../response')
                  }}
                />
              </form>
            )}
          />
        </section>
      </StyledContentContainer>
    </BenefitModalPageTemplate>
  )
}

export default UpdateBenefitDenialModal
