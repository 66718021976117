import React, { useEffect } from 'react'
import OutcomePageTemplate from 'Components/Outcome/OutcomePageTemplate/OutomePageTemplate'
import { resetAll } from 'Reducers/outcome'
import { useAppDispatch } from 'Store/hooks'

const OutcomeThankYou = ({ logo }: { logo: string | null }) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(resetAll()) // replace this with a referral  feedback action creator
  }, [])
  return (
    <OutcomePageTemplate logo={logo}>
      <section className='outcome-thank-you'>
        <h1>Thanks! We'll update your Single Stop record with this info.</h1>
      </section>
    </OutcomePageTemplate>
  )
}

export default OutcomeThankYou
