import React, { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import BenefitTooltipDialog from 'Components/Shared/BenefitTooltipDialog/BenefitTooltipDialog'
import Icon from 'Components/Shared/Icon/Icon'
import { StyledMuiTextFieldWrapper } from 'Components/Shared/ReduxForm/style'
import RecurringBenefitAmount from 'Pages/Results/RecurringBenefitAmount'
import { StyledBenefitAmount } from 'Pages/Results/style'
import {
  MOBILE_AND_TABLET_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
} from 'Shared/constants'
import { toCapitalized } from 'Shared/helpers'
import { isSpacebarOrEnterKey } from 'Shared/helpers'
import { isFeatureToggleOn } from 'Shared/helpers'
import LikeIconPath from 'Shared/Icons/LikeIconPath'
import UnLikeIconPath from 'Shared/Icons/UnLikeIconPath'
import theme from 'Shared/Theme/ssTheme'

import BenefitAmount from './BenefitAmount'
import BenefitGuideLink from './BenefitGuide'
import EligibilityStatus from './EligibilityStatus'
import {
  StyledBenefitHeading,
  StyledBenefitListItemToggleButton,
  StyledBenefitsSpan,
  StyledFocusButtonWrapper,
} from './style'

import 'Components/Uielements/styles/globalStyle.css'

const EligibilityBenefitListItem = (props) => {
  const {
    alreadyReceiving,
    amountLabel,
    description,
    eligible,
    eligibleAmount,
    findOutMore,
    frequency,
    name,
    toolkitUrl,
    recurringAmount,
  } = props

  let [isFeatureToggleOnValue, setIsFeatureToggleOnValue] = useState(false)
  useEffect(() => {
    setIsFeatureToggleOnValue(isFeatureToggleOn('already_receiving_benefits'))
  }, [])
  const { people, category, categoryIcon } = { ...props }

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const focusRef = useRef(null)

  const [activeFocusRef, setActiveFocusRef] = useState(null)

  const handleClose = () => {
    setOpen(false)
    if (activeFocusRef) {
      activeFocusRef.current.focus()
      setActiveFocusRef(null)
    }
  }

  const isTabletAndMobile = useMediaQuery({
    query: MOBILE_AND_TABLET_MEDIA_QUERY,
  })

  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })

  // Accessibility audit fix: Added Aria Attr in Right Div of Modal and Remove Role Presentation
  useEffect(() => {
    setTimeout(() => {
      const ModalContainer = document.querySelector('div[role="dialog"]')
      if (ModalContainer) {
        ModalContainer.setAttribute('aria-modal', 'true')
        ModalContainer.setAttribute('aria-label', name)
      }
      const ModalRolePresentation = document.querySelector(
        'div[role="presentation"]'
      )
      const ModalRoleNone = document.querySelector(
        'div[role="none presentation"]'
      )
      if (ModalRolePresentation) {
        ModalRolePresentation.removeAttribute('role')
      }
      if (ModalRoleNone) {
        ModalRoleNone.removeAttribute('role')
      }
    })
  })

  return (
    <>
      <StyledMuiTextFieldWrapper
        $mbottom='0'
        padding='1rem 0 1rem 1.5rem'
        tabPadd='1rem 0 1rem 1.5rem'
        display='flex'
      >
        <Icon
          position={'absolute'}
          top={'1rem'}
          fill={'unset'}
          scale={'3rem'}
          margin={isMobile ? '0 1rem 0 0.5rem' : '0 1rem 0 0'}
          viewBox={'0 0 32.043 32.043'}
          backgroundColor={
            eligible ? theme.palette.primary.lighten10 : '#DEDEDE'
          }
          padding={'.2rem .5rem'}
          radius={'5rem'}
          screenReaderLabel={
            eligible ? 'Likely Eligible Program' : 'Unlikely Eligible Program'
          }
        >
          {eligible ? LikeIconPath : UnLikeIconPath}
        </Icon>
        <StyledMuiTextFieldWrapper
          padding='0 0 0 3.5rem'
          mobilePadd='0rem 0rem 0rem 4rem'
          $mbottom='0'
          tabPadd='0 0 0 3.5rem'
        >
          <StyledBenefitHeading
            data-testid='benefit-program'
            onClick={handleClickOpen}
          >
            {name}
            <StyledFocusButtonWrapper
              tabIndex={0}
              role={'button'}
              ref={focusRef}
              onKeyDown={(evt) => {
                if (isSpacebarOrEnterKey(evt.keyCode)) {
                  handleClickOpen()
                }
              }}
              onClick={() => {
                setActiveFocusRef(focusRef)
              }}
            >
              <Icon
                fill={'#000'}
                scale={'1.25rem'}
                margin={'0 0 0 .4rem'}
                viewBox={'0 0 24 24'}
                padding={'0'}
                screenReaderLabel={'More Info'}
                cursorOnHover={'pointer'}
              >
                <path d='M0 0h24v24H0V0z' fill='none' />
                <path d='M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z' />
              </Icon>
            </StyledFocusButtonWrapper>
          </StyledBenefitHeading>
          <StyledBenefitListItemToggleButton
            eligible={eligible}
            findOutMore={findOutMore}
            flex={'0'}
            background={'none'}
            fontSize={'0.75rem'}
            fontWeight={'500'}
            padding={'0'}
            paddLeft={'0'}
            radius={'0'}
            margin={'.5rem 0 0 0'}
            border={'0'}
          >
            <EligibilityStatus
              people={people}
              findOutMore={findOutMore}
              eligible={eligible}
            />
          </StyledBenefitListItemToggleButton>
          {(eligibleAmount !== 0 || alreadyReceiving) && (
            <div>
              <StyledBenefitsSpan>
                {toCapitalized(amountLabel)} Yearly Value:
              </StyledBenefitsSpan>{' '}
              {alreadyReceiving && isFeatureToggleOnValue ? (
                <StyledBenefitAmount highlight={true}>
                  Current Benefit
                </StyledBenefitAmount>
              ) : (
                <BenefitAmount eligibleAmount={eligibleAmount} />
              )}
            </div>
          )}
          <RecurringBenefitAmount
            frequency={frequency}
            recurringAmount={recurringAmount}
          />
          {toolkitUrl && <BenefitGuideLink viewGuideURL={toolkitUrl} />}
        </StyledMuiTextFieldWrapper>
      </StyledMuiTextFieldWrapper>

      <BenefitTooltipDialog
        isTabletAndMobile={isTabletAndMobile}
        handleClose={handleClose}
        open={open}
        categoryIcon={categoryIcon}
        category={category}
        description={description}
        toolkitUrl={toolkitUrl}
        name={name}
      />
    </>
  )
}

export default EligibilityBenefitListItem
