import React from 'react'
import { NumericFormat } from 'react-number-format'
import CardInfo from 'Components/CaseManagement/Reporting/Charts/Demographics/Overview/CardInfo/CardInfo'
import { StyledCard } from 'Components/CaseManagement/Reporting/Charts/Shared/style'
import Icon from 'Components/Shared/Icon/Icon'

import {
  StyledCardContainer,
  StyledCardContainerItem,
  StyledCardContainerItemCurrency,
  StyledCardContainerItemLabel,
  StyledGrid,
  StyledLabel,
  StyledLeftPanel,
  StyledValue,
} from './style'

type RateDisplayCardsProps = {
  className?: string
  value: string | number
  icon: React.ReactNode
  isCurrency?: boolean
  description?: string
  dataTestId?: string
  cashBenefits: string | number | undefined
  nonCashBenefits: string | number | undefined
  height?: string
}

interface CurrencyDisplayProps {
  value: number | string | undefined
  isCurrency: boolean | undefined
}
const CurrencyDisplay: React.FC<CurrencyDisplayProps> = ({
  value,
  isCurrency,
}) => {
  return isCurrency ? (
    <NumericFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'$'}
    />
  ) : (
    <span>{value}</span>
  )
}

const RateDisplayCards = ({
  className,
  value,
  icon,
  isCurrency,
  dataTestId,
  cashBenefits,
  nonCashBenefits,
  height,
}: RateDisplayCardsProps) => {
  const currency = isCurrency ? (
    <NumericFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'$'}
    />
  ) : (
    value
  )

  return (
    <StyledCard
      data-testid={dataTestId}
      style={{ height }}
      className={`${className || ''}`}
    >
      <StyledGrid>
        <StyledLeftPanel>
          <Icon
            screenReaderLabel='Potential Yearly Benefit Value'
            fill={'auto'}
            scale={'3.825rem'}
            viewBox={'0 0 60.705 60.705'}
          >
            {icon}
          </Icon>
        </StyledLeftPanel>
        <StyledValue style={{ marginTop: '12px' }}>
          {currency}
          <CardInfo
            rateDisplay={false}
            title={'Potential Yearly Benefit Value'}
            descr={'Total of likely eligible cash and non-cash benefits.'}
          />
        </StyledValue>
        <StyledLabel style={{ marginTop: '0' }}>
          Potential Yearly Benefit Value
        </StyledLabel>
      </StyledGrid>
      <StyledCardContainer style={{ marginTop: '0' }}>
        <StyledCardContainerItem backgroundColor='hsla(164, 48%, 95%, 1)'>
          <StyledCardContainerItemCurrency className='cash-element'>
            <CurrencyDisplay value={cashBenefits} isCurrency={isCurrency} />
            {/* <CardInfo
              toolTipClassName='cash-benefits-tooltip'
              rateDisplay={false}
              title='Cash Benefits'
              descr={
                'Total likely eligible in cash benefits. Cash benefits have a monetary value and may be used to pay for goods and services.'
              }
            /> */}
          </StyledCardContainerItemCurrency>
          <StyledCardContainerItemLabel>
            Cash Benefits
          </StyledCardContainerItemLabel>
        </StyledCardContainerItem>

        <StyledCardContainerItem backgroundColor='hsla(46, 100%, 92%, 1)'>
          <StyledCardContainerItemCurrency>
            <CurrencyDisplay value={nonCashBenefits} isCurrency={isCurrency} />
            {/* <CardInfo
              toolTipClassName='non-cash-benefits-tooltip'
              rateDisplay={false}
              title='Non-cash Benefits'
              descr={
                'Total likely eligible non-cash benefits. Non-cash benefits represent the value of  goods and services made available to those that qualify.'
              }
            /> */}
          </StyledCardContainerItemCurrency>
          <StyledCardContainerItemLabel>
            Non-cash Benefits
          </StyledCardContainerItemLabel>
        </StyledCardContainerItem>
      </StyledCardContainer>
    </StyledCard>
  )
}

export default RateDisplayCards
