import styled from 'styled-components'

export const StyledQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;

  @media (max-width: 768px) {
    width: 100%;
    &.reviewPageContainer {
      padding-right: 1rem;
    }
  }
`

export const StyledSecondaryHeading = styled.h4`
  font-size: 1.4rem;
  margin-top: 0;
  margin-bottom: 1rem;
  padding-bottom: 0.25rem;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
`
export const StyledButtonFilter = styled.button<{ isActive: boolean }>`
  border-radius: 32px;
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? '#EEF9F6' : '#f9f9f9')};
  color: ${(props) => (props.isActive ? '#08674A' : '#151515')};

  padding: 5px 12px;
  border: none;

  font-weight: 450;
  font-size: 14px;
`
export const StyledButtonFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
`
