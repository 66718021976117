import React, { useEffect } from 'react'
import MediaQuery from 'react-responsive'
import { useParams } from 'react-router-dom'
import { SwipeableDrawer } from '@material-ui/core'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'

import Location from './Location'
import { StyledBox, StyledMobileContainer, StyledPuller } from './style'

const MobileView = () => {
  const [open, setOpen] = React.useState(true)
  const locationId = useParams()

  useEffect(() => {
    if (locationId) {
      setOpen(true)
    }
    return () => {
      setOpen(false)
    }
  }, [locationId])

  return (
    <MediaQuery query={MOBILE_MEDIA_QUERY}>
      <SwipeableDrawer
        variant='temporary'
        hideBackdrop
        disableAutoFocus
        BackdropProps={{
          style: {
            display: 'none',
          },
        }}
        draggable
        PaperProps={{
          style: {
            overflowX: 'hidden',
            height: '60%',
            overflowY: 'scroll',
            borderRadius: '2.5rem 2.5rem 0 0',
            paddingTop: '2.5rem',
          },
        }}
        disableBackdropTransition
        anchor={'bottom'}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        swipeAreaWidth={56}
        ModalProps={{
          keepMounted: false,
        }}
      >
        <StyledBox>
          <StyledPuller />
        </StyledBox>
        <StyledMobileContainer>
          <Location />
        </StyledMobileContainer>
      </SwipeableDrawer>
    </MediaQuery>
  )
}

export default MobileView
