const locations = {
  searchResults: {},
  locationDetail: {},
  rehydrated: false,
  persisted: false,
  showZeroResultsMessage: false,
  locationsCount: 0,
}

export { locations }
