import React from 'react'
import MediaQuery from 'react-responsive'
import { useLocation, useNavigate } from 'react-router-dom'
import { Dialog, DialogContent } from '@material-ui/core'
import { DESKTOP_MEDIA_QUERY } from 'Shared/constants'

import Location from './Location'

const DesktopView = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <MediaQuery query={DESKTOP_MEDIA_QUERY}>
      <Dialog
        hideBackdrop
        open={true}
        transitionDuration={{
          appear: 2000,
          enter: 1500,
          exit: 1000,
        }}
        onClose={() => {
          navigate(location.pathname.split('/list')[0] + '/list')
        }}
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: '100%',
            padding: '0',
            margin: '0',
            height: '100%',
            maxHeight: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            boxShadow: 'none',
            borderRadius: '1rem',
          },
        }}
        scroll={'paper'}
        style={{
          maxHeight: 'fit-content',
          height: 'calc(100vh - 220px)',
          left: location.pathname.includes('case-management')
            ? '35rem'
            : '25rem',
          marginLeft: '4px',
          width: '30rem',
          overflowX: 'hidden',
          position: 'absolute',
          top: '170px',
          zIndex: '1300',
          boxShadow: 'none',
        }}
      >
        <DialogContent
          style={{
            padding: 0,
            overflowX: 'hidden',
          }}
        >
          <Location />
        </DialogContent>
      </Dialog>
    </MediaQuery>
  )
}

export default DesktopView
