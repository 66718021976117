import { FormControl, withStyles } from '@material-ui/core'
import theme from 'Shared/Theme/ssTheme'
import { media } from 'styled-bootstrap-grid'
import styled, { css } from 'styled-components'

export const StyledDatePickerWrap = styled.div`
  width: 17rem;
  margin: 0;
  display: flex;
  background-color: #f9f9f9;
  align-items: flex-end;
  margin-top: 1rem;
  & label {
    width: 60%;
    & + div {
      width: 40%;
    }
  }
  & input {
    height: auto;
    padding: 0;
    font-size: 1rem !important;
    font-weight: 400;
    color: ${theme.palette.primary.dark};
    margin-left: 4px;
  }
  & button {
    padding: 0;
    color: ${theme.palette.color.black};
  }
  ${media.smaller`
    width: 10rem;
    display: flex;
    flex-direction: column;
    & label {
      display: flex;
      width: 100%;
      margin-bottom: 0.5rem;
      & + div{
        width: 100%;
      }
    }
  `}
  ${({ review }) =>
    review &&
    css`
      width: 100%;
      ${media.smaller`
        width: 100%;
      `}
    `}
`

StyledDatePickerWrap.displayName = 'StyledDatePickerWrap'

export const StyledFormControl = withStyles({
  root: {
    width: '100%',
    '& > div': {
      border: '0.0625rem solid rgba(0, 0, 0, 0.23)',
      borderRadius: '0.375rem',
      padding: '1.15625rem 0.875rem',
    },
    '& input': {
      color: '#29283E',
      fontSize: '1rem',
      fontWeight: '600',
    },
    '& p': {
      fontSize: '.875rem',
      fontWeight: '400',
    },
    '&&:hover': {
      borderColor: 'rgb(0, 0, 0)',
    },
  },
})(FormControl)
