import React from 'react'
import MediaQuery from 'react-responsive'
import { StyledLocationListContainer } from 'Pages/Locations/style'
import {
  desktopAndTabletMediaQuery,
  MOBILE_MEDIA_QUERY,
  TABLET_MEDIA_QUERY,
} from 'Shared/constants'
import { useAppSelector } from 'Store/hooks'

import LocationsListResults from './LocatiosListResults'
import SearchFeedBackResults from './SearchFeedBackResults'
import { StyledContainer } from './style'

import 'Components/Uielements/styles/globalStyle.css'

const LocationsList = () => {
  const showLocationsNotFound = useAppSelector(
    (state) => state.locations.showZeroResultsMessage
  )

  return (
    <>
      <MediaQuery query={MOBILE_MEDIA_QUERY}>
        <StyledContainer>
          {showLocationsNotFound ? (
            <SearchFeedBackResults searchValue={2}></SearchFeedBackResults>
          ) : (
            <LocationsListResults />
          )}
        </StyledContainer>
      </MediaQuery>

      <MediaQuery query={desktopAndTabletMediaQuery}>
        <MediaQuery query={TABLET_MEDIA_QUERY}>
          {(matches) => {
            const device = matches ? 'tablet' : 'desktop'
            return (
              <StyledLocationListContainer
                padding={'0'}
                device={device}
                id='locationCard-wrapper'
              >
                {showLocationsNotFound ? (
                  <SearchFeedBackResults
                    searchValue={2}
                  ></SearchFeedBackResults>
                ) : (
                  <LocationsListResults />
                )}
              </StyledLocationListContainer>
            )
          }}
        </MediaQuery>
      </MediaQuery>
    </>
  )
}

export default LocationsList
