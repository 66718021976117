import React from 'react'
import { ApolloError } from '@apollo/client'
import {
  CustomEmptyState,
  ResourcesWrapperContainer,
  StyledCaseCategoryWrapper,
  StyledCasesWrapper,
} from 'Components/ClientPortal/MyBenefits/style'
import LoadingIndicator from 'Components/Shared/LoadingIndicator/LoadingIndicator'

import Paginationresources from '../Pagination/Pagination'

import CaseCard from './mainCard'

type MyReferralsContentProps = {
  data: unknown
  loading: boolean
  error: ApolloError | undefined
  pageQuery?: number | undefined
  onPaginationChange?: unknown
}

const MainContent = ({
  data,
  loading,
  error,
  pageQuery,
  onPaginationChange,
}: MyReferralsContentProps) => {
  let getTotalItemsCount: number
  let updatedDataObject: unknown
  const pageSize = 12
  let pageCount = 1
  let itemsInSpecificPage: unknown

  function splitItemsForPaging() {
    getTotalItemsCount = updatedDataObject.length
    pageCount = Math.ceil(getTotalItemsCount / pageSize)

    if (!pageQuery || pageQuery > pageCount) {
      pageQuery = 1
    }
    itemsInSpecificPage = getItemsForPage(pageQuery)
  }

  function getItemsForPage(pageNumber: number) {
    const startIndex = (pageNumber - 1) * pageSize
    const endIndex = startIndex + pageSize
    return updatedDataObject.slice(startIndex, endIndex)
  }

  if (loading) {
    return <LoadingIndicator show={true} />
  } else if (error) {
    return <div>ERROR_GENERIC_MESSAGE</div>
  } else if (data) {
    const filteredData = []
    if (data?.activities) {
      for (let i = 0; i < data?.activities?.nodes.length; i++) {
        if (data?.activities?.nodes[i].actionable?.findHelpLocation != null) {
          filteredData.push(data?.activities?.nodes[i])

          if (
            data?.activities?.nodes[i].actionable?.referralCategories
              ?.length === 0
          ) {
            const clonedActionable = JSON.parse(
              JSON.stringify(filteredData[i].actionable)
            )

            clonedActionable.referralCategories = [
              {
                name: '',
                createdAt: new Date(),
              },
            ]

            filteredData[i] = {
              ...filteredData[i],
              actionable: clonedActionable,
            }
          }
        }
      }
    }

    updatedDataObject = filteredData
    const handlePageChange = (event, value) => {
      pageQuery = value
      onPaginationChange(value)
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      urlParams.set('page', value.toString())
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`
      const pageHistory = window.history

      pageHistory.replaceState(null, '', newUrl)
    }

    splitItemsForPaging()

    return (
      <>
        <ResourcesWrapperContainer>
          {updatedDataObject?.length > 0 ? (
            <>
              <StyledCaseCategoryWrapper>
                <StyledCasesWrapper className='StyledReferralWrapper'>
                  {itemsInSpecificPage.map((item: unknown, i: number) => (
                    <CaseCard resourceData={item} key={item.id} />
                  ))}
                </StyledCasesWrapper>
              </StyledCaseCategoryWrapper>
              {pageCount > 1 && (
                <Paginationresources
                  page={pageQuery}
                  onChange={handlePageChange}
                  count={pageCount}
                />
              )}
            </>
          ) : (
            <StyledCaseCategoryWrapper>
              <CustomEmptyState>You don't have any Referrals</CustomEmptyState>
            </StyledCaseCategoryWrapper>
          )}
        </ResourcesWrapperContainer>
      </>
    )
  }
}

export default MainContent
