import React from 'react'

import {
  SearchFeedBackDescription,
  SearchFeedBackHeader,
  SearchFeedBackWrapper,
} from './style'

interface SearchFeedBackResultsProps {
  searchValue: 0 | 1 | 2
}

const SearchFeedBackResults: React.FC<SearchFeedBackResultsProps> = ({
  searchValue,
}) => {
  return (
    <>
      {searchValue === 0 && (
        <SearchFeedBackWrapper style={{ backgroundColor: '#eef9f6' }}>
          <SearchFeedBackHeader>Best matches</SearchFeedBackHeader>
          <SearchFeedBackDescription>
            These programs contain <span>all of the word(s) you searched</span>{' '}
            in the provider name, program name, or description and are likely to
            be the most relevant matches.
          </SearchFeedBackDescription>
        </SearchFeedBackWrapper>
      )}
      {searchValue === 1 && (
        <SearchFeedBackWrapper style={{ backgroundColor: '#FFF6D8' }}>
          <SearchFeedBackHeader>Possible matches</SearchFeedBackHeader>
          <SearchFeedBackDescription>
            These programs contain <span>some of the words you searched</span>{' '}
            in the provider name, program name, or description.
          </SearchFeedBackDescription>
        </SearchFeedBackWrapper>
      )}
      {searchValue === 2 && (
        <SearchFeedBackWrapper style={{ backgroundColor: '#F9F9F9' }}>
          <SearchFeedBackHeader>No matches</SearchFeedBackHeader>
          <SearchFeedBackDescription>
            We couldn't find any programs matching your search. Please try
            again!
          </SearchFeedBackDescription>
        </SearchFeedBackWrapper>
      )}
    </>
  )
}

export default SearchFeedBackResults
