import React from 'react'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import {
  COMPLETE_SERVICE_CASE,
  GET_CASES,
} from 'Components/ClientPortal/MyBenefits/gql'
import BenefitModalPageTemplate from 'Components/ClientPortal/MyBenefits/UpdateBenefitModal/BenefitModalTemplate'
import ButtonsContainer from 'Components/Outcome/OutcomeCashBenefitForm/ButtonsContainer'
import { StyledContentContainer } from 'Components/Outcome/style'
import StyledRadioButton from 'Components/Outcome/ui/StyledRadioButton'
import { FORM_ERROR } from 'final-form'
import {
  closeEditBenefitStatusModal,
  updateConfirmation,
} from 'Reducers/myBenefitsSlice'
import { CONFIRMATION_MAPPINGS } from 'Shared/constants'
import { useAppSelector } from 'Store/hooks'

const UpdateBenefitConfirmationModal = () => {
  const { benefitName, caseId, confirmation, confirmationTypes, cashBenefit } =
    useAppSelector((state) => state.myBenefits)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [handleCompleteMutation] = useMutation(COMPLETE_SERVICE_CASE)

  const onSubmit = ({ positiveConfirmationType }) => {
    dispatch(updateConfirmation({ confirmation: positiveConfirmationType }))
    if (cashBenefit) {
      navigate('../cash-benefit')
    } else {
      handleCompleteMutation({
        variables: {
          serviceCaseId: caseId,
          outcomeType: 'CONFIRMED',
          confirmationType: positiveConfirmationType.toUpperCase(),
        },
        refetchQueries: [GET_CASES],
      }).then(() => {
        navigate('..')
        dispatch(closeEditBenefitStatusModal())
      })
    }
  }

  return (
    <BenefitModalPageTemplate
      handleCancel={() => {
        navigate('..')
        dispatch(closeEditBenefitStatusModal())
      }}
    >
      <StyledContentContainer $modal>
        <section className='form-container'>
          <h2>What type of confirmation did you receive for {benefitName}?</h2>
          <Form
            initialValues={
              confirmation ? { positiveConfirmationType: confirmation } : {}
            }
            onSubmit={onSubmit}
            validate={({ positiveConfirmationType }) =>
              positiveConfirmationType
                ? {}
                : { [FORM_ERROR]: 'Please choose an option' }
            }
            render={({ handleSubmit, values, error, touched }) => (
              <form onSubmit={handleSubmit}>
                <ul>
                  {CONFIRMATION_MAPPINGS.filter((mapping) =>
                    confirmationTypes.includes(mapping.value?.toUpperCase())
                  ).map((mapping, index) => {
                    const { label, value } = mapping
                    const isChecked = values?.positiveConfirmationType === value
                    const radioId = `${value}-${index}`
                    return (
                      <li key={radioId}>
                        <StyledRadioButton
                          value={value}
                          label={label}
                          name='positiveConfirmationType'
                          id={radioId}
                          $isChecked={isChecked}
                        />
                      </li>
                    )
                  })}
                </ul>
                {error && touched?.positiveConfirmationType && (
                  <p className='error-message'>{error}</p>
                )}
                <ButtonsContainer
                  backButtonCallback={() => {
                    navigate('../response')
                  }}
                />
              </form>
            )}
          />
        </section>
      </StyledContentContainer>
    </BenefitModalPageTemplate>
  )
}

export default UpdateBenefitConfirmationModal
