import {
  ConfirmationType,
  DenialReason,
  OutcomeType,
} from '__generated__/graphql'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createSlice } from '@reduxjs/toolkit'
import { myBenefits as initialState } from 'Reducers/initialState'
import { REMEMBER_PERSISTED, REMEMBER_REHYDRATED } from 'redux-remember'

const rememberRehydrated = createAction<{ ui: typeof initialState }>(
  REMEMBER_REHYDRATED
)

export type CaseInfoModalPayload = {
  toolkitUrl?: string
  benefitDescription: string
  openEligibilityKey: string
  benefitName: string
}

export type OpenEditBenefitStatusModalPayload = {
  benefitName: string
}

export type UpdateReasonsPayload = {
  confirmationTypes: ConfirmationType[]
  denialReasons: DenialReason[]
}

export type UpdateConfirmationPayload = {
  confirmationType: ConfirmationType
}

export type OutcomeTypePayload = {
  confirmationType: OutcomeType
}

export type UpdateDenialReasonPayload = {
  denial: DenialReason
}

export const myBenefitsSlice = createSlice({
  name: 'myBenefits',
  initialState,
  reducers: {
    openBenefitInfoModal: (
      state,
      action: PayloadAction<CaseInfoModalPayload>
    ) => {
      state.openBenefitInfoModal = true
      state.toolkitUrl = action.payload.toolkitUrl
      state.benefitDescription = action.payload.benefitDescription
      state.benefitName = action.payload.benefitName
      state.openEligibilityKey = action.payload.openEligibilityKey
    },
    updateReasons: (state, action: PayloadAction<UpdateReasonsPayload>) => {
      state.confirmationTypes = action.payload.confirmationTypes
      state.denialReasons = action.payload.denialReasons
    },
    updateConfirmationType: (
      state,
      action: PayloadAction<OutcomeTypePayload>
    ) => {
      state.confirmationType = action.payload.confirmationType
    },
    updateConfirmation: (
      state,
      action: PayloadAction<UpdateConfirmationPayload>
    ) => {
      state.confirmation = action.payload.confirmation
    },
    updateDenial: (state, action: PayloadAction<UpdateDenialReasonPayload>) => {
      state.denial = action.payload.denial
    },
    closeBenefitInfoModal: (state) => {
      state.openBenefitInfoModal = false
      state.toolkitUrl = ''
      state.benefitDescription = ''
      state.benefitName = ''
      state.openEligibilityKey = ''
    },
    openEditBenefitStatusModal: (
      state,
      action: PayloadAction<OpenEditBenefitStatusModalPayload>
    ) => {
      state.openEditCaseStatusModal = true
      state.benefitName = action.payload.benefitName
      state.benefitStatus = action.payload.benefitStatus
      state.caseId = action.payload.caseId
      state.serviceId = action.payload.serviceId
      state.cashBenefit = action.payload.cashBenefit
    },
    closeEditBenefitStatusModal: (state) => {
      state.openEditCaseStatusModal = false
      state.benefitName = ''
      state.benefitStatus = ''
      state.caseId = ''
      state.serviceId = ''
      state.confirmationTypes = []
      state.denialReasons = []
      state.cashBenefit = false
      state.confirmation = ''
      state.denial = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rememberRehydrated, (state, action) => {
      return {
        ...state,
        ...action.payload.myBenefits,
        rehydrated: true,
      }
    })
    builder.addCase(REMEMBER_PERSISTED, (state) => {
      state.rehydrated = false
      state.persisted = true
    })
  },
})
export const {
  openBenefitInfoModal,
  closeBenefitInfoModal,
  openEditBenefitStatusModal,
  closeEditBenefitStatusModal,
  updateReasons,
  updateConfirmationType,
  updateConfirmation,
  updateDenial,
} = myBenefitsSlice.actions
export default myBenefitsSlice.reducer
