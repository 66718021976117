import React from 'react'
import Icon from 'Components/Shared/Icon/Icon'
import UserVerificationStatusIcon from 'Components/Shared/Icon/UserVerificationStatusIcon'

export const ClientInfoContactItem = ({
  icon,
  label,
  content,
  verified,
  isPhoneNumber,
  screenReaderLabel,
}) => {
  return (
    <div className='flex items-center gap-2'>
      <Icon
        fill={'none'}
        scale={'1.8rem'}
        viewBox={'0 0 21.904 21.904'}
        width={'1.375rem'}
        screenReaderLabel={screenReaderLabel}
      >
        {icon}
      </Icon>
      <p className='flex items-center gap-3 text-mobile-paragraph-regular-m'>
        <span className='text-primary-grey'>{label}:</span>{' '}
        <span className='text-primary-black'>{content}</span>
        {isPhoneNumber && content !== 'N/A' && (
          <span>
            <UserVerificationStatusIcon verified={verified} />
          </span>
        )}
      </p>
    </div>
  )
}

export default ClientInfoContactItem
