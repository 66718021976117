import React from 'react'
import { sortBenefitCategories, sortByKey } from 'Components/NextSteps/helpers'
import {
  Buttonwrraper,
  ListItem,
  Liststyle,
  StyledGuides,
} from 'Components/NextSteps/style'
import Icon from 'Components/Shared/Icon/Icon'
import { Dictionary } from 'lodash'
import { BenefitGuide } from 'Pages/NextSteps/NextStepsBenefitsGuides'
import { OPEN_ELIGIBILITY_ID_TO_CATEGORY_NAME_MAP as openEligibilityMap } from 'Shared/constants'
import iconMap from 'Shared/Icons/CategoryIcons'
import LinkIconPath from 'Shared/Icons/LinkIconPath'

type BenefitGuidesProps = {
  benefitGuides: Dictionary<BenefitGuide[]>
}
const BenefitGuidesList = ({ benefitGuides }: BenefitGuidesProps) => (
  <div>
    {Object.keys(benefitGuides)
      .sort(sortBenefitCategories)
      .map((key: string, i: number) => (
        <StyledGuides key={i}>
          <Liststyle>
            <Icon scale={'4.5rem'} screenReaderLabel={openEligibilityMap[key]}>
              {iconMap[key]}
            </Icon>
            <h3 key={key}>{openEligibilityMap[key]}</h3>
          </Liststyle>
          <ul>
            {benefitGuides[key]
              .sort(sortByKey('name'))
              .map((listItem: BenefitGuide) => {
                if (listItem.toolkitUrl) {
                  return (
                    <ListItem key={listItem.id}>
                      <>
                        <h4
                          aria-label={`benefit ${listItem.name}`}
                          data-testid='benefit-title'
                        >
                          <Icon
                            width='16'
                            height='16'
                            viewBox='0 0 16 16'
                            fill='none'
                            screenReaderLabel={listItem.name}
                          >
                            {LinkIconPath}
                          </Icon>{' '}
                          <span>{listItem.name}</span>
                        </h4>
                        <Buttonwrraper>
                          <a
                            href={listItem.toolkitUrl}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            View benefit guide
                          </a>
                        </Buttonwrraper>
                      </>
                    </ListItem>
                  )
                }
                return (
                  <ListItem key={listItem.id}>
                    <h4>{listItem.name}</h4>
                  </ListItem>
                )
              })}
          </ul>
        </StyledGuides>
      ))}
  </div>
)
export default BenefitGuidesList
