import { gql } from '__generated__/gql'

export const GET_REFERRALS = gql(`
  query getReferrals ($id: ID!) {
  client(id: $id) {
        clientLocations {
      activities {
        nodes {
          actionable {
            ... on ExternalReferral {
              referralCategories {
                id
                name
                createdAt
              }
              findHelpLocation {
                officeId
                programName
                providerName
                providerId
                programId
                zipCode
              }
            }
          }
        }
      }
    }
  }
}
`)

// export const GET_REFERRALS = gql(`
//   query getReferrals ($id: ID!) {
// client (id: $id){
//  clientLocation {
//    activities {
//     nodes {
//       ... on ExternalReferral {
//         id
//         referralCategories {
//          id
//          name
//         }
//       }
//     }
//    }
//  }
// }
// }
//   `)
