import React, { createContext, useState } from 'react'

export const CasesContext = createContext()

/**
 *
 * @param children
 * @return {*}
 * @constructor
 *
 * The various modals and action buttons of the Client Summary page require us to
 * share bits of case data across multiple components. In some cases, the components
 * do not have a parent / child relationship so prop drilling is not feasible.
 */
export const CasesProvider = ({ children }) => {
  const defaultState = {
    serviceCaseId: '',
    serviceCaseName: '',
    showCloseCaseModal: false,
    showActivityModal: false,
    showTransferClientModal: false,
    clientLocationId: '',
    targetLocationId: '',
    parentCategoryId: '',
  }

  const [casesMenuState, setCasesMenuState] = useState(defaultState)

  const resetContext = () => setCasesMenuState(defaultState)

  return (
    <CasesContext.Provider
      value={{ casesMenuState, setCasesMenuState, resetContext }}
    >
      {children}
    </CasesContext.Provider>
  )
}

export default CasesProvider
