import theme from 'Shared/Theme/ssTheme'
import * as u from 'Shared/Theme/utilities.styles'
import { media } from 'styled-bootstrap-grid'
import styled, { css } from 'styled-components'

const ltr = ({ dirLTR }) =>
  dirLTR &&
  css`
    direction: ltr;
  `

export const StyledParentElem = styled.div`
${({ Benefits }) =>
  Benefits &&
  css`
    ${media.tablet`
      padding: 0rem 2rem;
  `};
    ${media.desktop`
      padding: 0rem;
    `}
  `}
  ${ltr}
  ${u.GParentElem}
  z-index: 10;
  $
  position: relative;
  ${media.smaller`
    margin: 1rem 0;
  `}
  ${media.tablet`
    margin: 1rem 0;
  `}
  ${media.desktop`
    position: absolute;
    margin: 0 auto;
    width: 100%;
    align-items: baseline;
    top: ${(props) => props.sectionTop}
  `}
`
export const StyledSubTitleElem = styled.p`
  opacity: 0;
  ${u.StyledSubTitleElem}
`
export const StyledTitleElem = styled.h2`
  opacity: 0;
  ${u.GTitleElem};
  font-size: 2.813rem;
  line-height: ${(props) => props.lineHeight};
  ${media.smaller`
  width: 100%;
  font-size: 2.325rem;
  `}
  ${media.tablet`
  width: 100%;
  `}
  ${media.desktop`
  width: ${(props) => props.width};
  `};
  .custom-span {
    ${u.StyledSubTitleElem}
  }
  .custom-heading {
    color: ${theme.palette.primary.dark};
    font-size: 2.5rem;
    display: flex;
    font-weight: 700;
  }
`
export const StyledMessageElem = styled.div`
  opacity: ${(props) => props.startingOpacity ?? 0};
  white-space: ${(props) => (props.multilineText ? 'pre-line' : 'unset')};
  ${ltr}
  ${u.GMessageElem} ${media.smaller`
    width: 100%;
    margin: ${(props) => props.marginSmaller};
    font-size: 1.25rem;
  `} ${media.tablet`
    width: 100%;
    margin: ${(props) => props.marginSmaller};
  `} ${media.desktop`
    width: ${(props) => props.width};
    align-self: ${(props) => props.alignSelf};
  `};
  .important-message {
    color: ${theme.palette.primary.dark};
    font-size: 1.35rem;
    font-weight: 700;
  }
  h1 {
    font-weight: 700;
  }
  h3 {
    font-weight: bold;
    margin: 18px 0;
    font-size: 1.17em;
  }
  p {
    margin: 1em 0;
  }
`
export const StyledActionsElem = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  opacity: 0;
  z-index: 100;
  ${media.desktop`
    position: ${(props) => props.position};
    top: ${(props) => props.moveUp};
  `}
  ${({ moveBtn }) =>
    moveBtn &&
    css`
      ${media.smaller`
        text-align: center;
        margin: ${(props) => props.marginSmaller};
      `}
      ${media.tablet`
        text-align: center;
        margin: ${(props) => props.marginSmaller};
        top: ${(props) => (props.topBtnTab ? props.topBtnTab : '0')};
        position: relative;
      `}
      ${media.desktop`
        position: relative;
        top: ${(props) => (props.topBtn ? props.topBtn : '0')};
        width: 100%;
        direction: ltr;
        display: flex;
        justify-content: flex-start;
      `}
    `}
`

export const StyledActionsItem = styled.li``

export const StyledAnimatingLensBox = styled.div`
  ${media.smaller`
  margin: -4rem 0;
`}
  & > svg {
    ${media.smaller`
    width: 90% !important;
  `}
  }
`

StyledParentElem.displayName = 'StyledParentElem'
StyledSubTitleElem.displayName = 'StyledSubTitleElem'
StyledTitleElem.displayName = 'StyledTitleElem'
StyledMessageElem.displayName = 'StyledMessageElem'
StyledActionsElem.displayName = 'StyledActionsElem'
StyledActionsItem.displayName = 'StyledActionsItem'
StyledAnimatingLensBox.displayName = 'StyledAnimatingLensBox'
