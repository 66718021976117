import styled from 'styled-components'

export const StyledBenefitGuides = styled.div`
  display: table-row;
  margin-bottom: 1rem;
  margin-top: 2rem;

  h3 {
    padding: 0 0 0 2rem;
    text-transform: capitalize;
    display: table-cell;
    font-weight: 500;
  }

  ul {
    list-style-type: none;
    display: table-cell;
  }

  li {
    margin-bottom: 1rem;
    color: #0d8762;
  }

  a {
    color: #0d8762;
  }

  .table {
    display: table;
  }

  .icon {
    display: table-cell;
    position: relative;
    top: 2rem;
  }
`
export const StyleHeadingHelpfulLink = styled.a`
  font-size: 16px !important;
  padding-bottom: 12px;
  margin: 0;
  text-decoration: underline;
  color: #151515;
  cursor: pointer;
  font-weight: 600;
  padding-left: 4px;

  @media (max-width: 480px) {
    font-size: 14px !important;
    padding-bottom: 8px;
    margin: 0;
  }
`
export const StyleHeadingHelpful = styled.a`
  font-size: 16px !important;
  padding-bottom: 12px;
  margin: 0;
  @media (max-width: 480px) {
    font-size: 14px !important;
    padding-bottom: 8px;
    margin: 0;
  }
`
export const StyleVisitParagraph = styled.p`
  color: #545456 !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  margin-top: 12px !important;
`

export const StyledGuides = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  gap: 16px;
  background-color: white;
  width: 100%;
  padding: 12px;
  border-radius: 16px;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 24px;
  }

  h3 {
    text-transform: capitalize;
    display: table-cell;
    font-weight: 600;
    font-size: 18px;
    @media (max-width: 480px) {
      font-size: 16px;
    }
  }

  ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding: 0;
  }

  .icon {
    position: relative;
    top: 2rem;
  }
`
export const StyledHelpfulLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: white;
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    flex-direction: column;
    margin-bottom: 3rem;
  }
  h3 {
    text-transform: capitalize;
    display: table-cell;
    font-weight: 600;
    font-size: 18px;
    @media (max-width: 480px) {
      font-size: 16px;
    }
  }

  ul {
    display: table-cell;
    width: 100%;
  }

  li {
    font-weight: 400;
    color: hsla(0, 0%, 8%, 1);

    @media (max-width: 480px) {
      font-size: 14px;
    }
  }

  p {
    color: hsla(0, 0%, 8%, 1);
    margin-bottom: 0;
  }

  .table {
    display: table;
  }

  .icon {
    display: table-cell;
    position: relative;
    top: 2rem;
  }
`
export const StyledLink = styled.a`
  text-decoration: none;
`
export const Buttonwrraper = styled.div`
  display: flex;
  flex-shrink: 0;

  @media (max-width: 480px) {
    width: 100%;
    justify-content: flex-end;
  }
  button:nth-child(1) {
    background-color: transparent;
    border: none;
    color: hsla(0, 0%, 8%, 1);
  }
  button:nth-child(2) {
    background-color: transparent;
    border: 1px solid hsla(0, 0%, 94%, 1);
    border-radius: 56px;
    padding: 8px 16px;
  }
  a {
    color: #151515;
    font-weight: 400;
  }
`
export const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 12px;
  border: 1px solid #efefef;
  border-radius: 12px;
  padding: 0.75rem;

  a {
    font-size: 14px;
    text-decoration: none;
  }

  @media (min-width: 480px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 1rem;

    a {
      font-size: 16px;
    }
  }

  h4 {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #151515;
    font-size: 16px;
    font-weight: 400;
    margin: 0;

    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
`
export const Liststyle = styled.div`
  display: flex;
  gap: 16px;
  flex-shrink-0;
    width: 100%; /* Ensure the Liststyle takes full width */
  max-width: 220px; /* Set a max-width if needed */

`
export const ButtonWrapperBackground = styled.div`
  display: none;
  position: unset;
  @media (max-width: 480px) {
    display: block;
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: white;
    height: 90px;
    overflow-x: hidden;
    border-top: solid hsla(0, 0%, 94%, 1);
    z-index: 1000;
  }
`
StyledBenefitGuides.displayName = 'StyledBenefitGuides'
