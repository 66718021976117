import React from 'react'
import { Form } from 'react-final-form'

import { StyledButtonReferral } from '../style'

import { CancelButton, FooterForm, StyledParagraph } from './style'

interface StartReferralFormProps {
  onSubmit: () => void
  userData: {
    isACaseManager: boolean
  }
  handleClose: () => void
}

const StartReferralForm = ({
  onSubmit,
  userData,
  handleClose,
}: StartReferralFormProps) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        {userData?.isACaseManager ? (
          <>
            <StyledParagraph>
              When a referral is started, an activity will be logged in this
              client's record. The client will also receive a message that
              includes the location's name, address, and contact information.
            </StyledParagraph>
            <StyledParagraph>
              Client details will not be sent to the referral location.
            </StyledParagraph>
          </>
        ) : (
          <>
            <StyledParagraph>
              By starting a referral, an entry will be made in your client
              record. Your case manager may follow up with you about your
              experience.
            </StyledParagraph>
            <StyledParagraph>
              We'll also send you a message that includes the location's name,
              address, and contact information.
            </StyledParagraph>
            <StyledParagraph>
              Details about this referral will not be sent to the referral
              location.
            </StyledParagraph>
          </>
        )}
        <FooterForm>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <StyledButtonReferral type='submit'>
            Start referral
          </StyledButtonReferral>
        </FooterForm>
      </form>
    )}
  />
)

export default StartReferralForm
