import {
  UPDATE_FILTERED_LOCATIONS,
  UPDATE_LOCATIONS_COUNT,
  UPDATE_SHOW_ZERO_RESULTS,
} from 'Actions/filters'
import {
  LOAD_INITIAL_LOCATIONS,
  UPDATE_LOCATION_DETAIL,
} from 'Actions/locations'
import { REMEMBER_PERSISTED, REMEMBER_REHYDRATED } from 'redux-remember'

import { locations as initialState } from './initialState'

export default (state = initialState, action) => {
  switch (action.type) {
    case REMEMBER_REHYDRATED:
      return {
        ...state,
        ...action.payload.locations,
        rehydrated: true,
      }

    case REMEMBER_PERSISTED:
      return {
        ...state,
        rehydrated: false,
        persisted: true,
      }

    case UPDATE_LOCATION_DETAIL: {
      const { id, locationDetail: location } = action

      return {
        ...state,
        locationDetail: {
          [id]: location,
        },
      }
    }

    case LOAD_INITIAL_LOCATIONS: {
      const { initialLocations } = action

      return {
        ...state,
        searchResults: initialLocations,
      }
    }

    case UPDATE_FILTERED_LOCATIONS: {
      const { filteredLocations } = action

      return {
        ...state,
        searchResults: filteredLocations,
      }
    }

    case UPDATE_SHOW_ZERO_RESULTS: {
      return {
        ...state,
        showZeroResultsMessage: action.payload,
      }
    }
    case UPDATE_LOCATIONS_COUNT: {
      return {
        ...state,
        locationsCount: action.payload,
      }
    }

    default:
      return state
  }
}
