import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Icon from 'Components/Shared/Icon/Icon'
import Allyantimage from 'Components/Shared/PageFooter/Components/AllyantBadgeIcon.png'
import { ISOLogoIcon } from 'Components/Shared/PageFooter/Components/IconsPath'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { SrOnly } from 'Shared/Theme/utilities.styles'
import { Container } from 'styled-bootstrap-grid'

import {
  ExternalLink,
  FooterBottom,
  NavItem,
  NavLink,
  NavList,
  Paragraph,
  Strong,
  Wrapper,
} from '../Styles/copyrights'
import { StyledNavColumn, StyleImageWrraper } from '../Styles/FooterNav'

const SingleStopCopyright = ({ year }) => (
  <Paragraph>
    © {year} <Strong> Single Stop.</Strong> All rights reserved
  </Paragraph>
)

const Copyrights = (props) => {
  const { forwardRef } = props
  const theYear = new Date().getFullYear()
  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })

  return (
    <FooterBottom ref={forwardRef}>
      <Container>
        {isMobile && (
          <StyledNavColumn>
            <Icon
              viewBox={'0 0 174.151 88.436'}
              scale={'auto'}
              width={'9rem'}
              margin={'1rem auto'}
              screenReaderLabel={'Certified ISO'}
            >
              {ISOLogoIcon}
            </Icon>
            <StyleImageWrraper
              href='https://allyant.com/digital-accessibility-auditing/'
              target='_blank'
            >
              <img
                style={{
                  paddingTop: '24px',
                  width: 'auto',
                  height: 'auto',
                }}
                src={Allyantimage}
                alt='Reviewed By Allyant for Accessibility'
                aria-label='Reviewed By Allyant for Accessibility'
              />
            </StyleImageWrraper>
          </StyledNavColumn>
        )}
        <Wrapper>
          <h2 className='hidden-title-for-nav'>Fotter bottom Navigation</h2>
          <SrOnly>Fotter bottom Navigation</SrOnly>
          {!isMobile && <SingleStopCopyright year={theYear} />}
          <NavList>
            <NavItem>
              <ExternalLink
                href='https://singlestop.org/privacy-policy/'
                target='_blank'
                rel={'noopener noreferrer'}
              >
                Privacy Policy
              </ExternalLink>
            </NavItem>
            <NavItem>
              <ExternalLink
                href='https://singlestop.org/terms-and-conditions/'
                target='_blank'
                rel={'noopener noreferrer'}
              >
                Terms and Conditions
              </ExternalLink>
            </NavItem>
            <NavItem>
              <NavLink
                href='https://singlestop.org/single-stop-usa-statement-on-programmatic-integrity/'
                target='_blank'
                rel={'noopener noreferrer'}
              >
                Program Integrity Policy
              </NavLink>
            </NavItem>
          </NavList>
          {isMobile && <SingleStopCopyright year={theYear} />}
        </Wrapper>
      </Container>
    </FooterBottom>
  )
}

export default Copyrights
