import {
  BenefitStatus,
  GetCasesQuery,
  Maybe,
  Person,
  ScreeningEligibility,
  ServiceCase,
} from '__generated__/graphql'
import { Dictionary } from 'lodash'
import _groupBy from 'lodash.groupby'
import { calculateRecurringBenefitAmount } from 'Pages/Results/helpers'

export type ParsedServiceCase = {
  id: string
  name: string
  eligibility: string
  status: BenefitStatus
  openEligibilityKey: string
  people: Maybe<Person[]> | undefined
  activationStatus: 'ACTIVE' | 'INACTIVE' | 'CLOSED'
  description: string
  toolkitUrl?: string
  alreadyReceiving?: boolean
  eligibleAmount: unknown
  drawdownAmount: unknown
  frequency: unknown
  amountLabel: unknown
}

export type ParsedBenefits = {
  allCases: Dictionary<ParsedServiceCase[]>
  activeCases: Dictionary<ParsedServiceCase[]>
  inactiveCases: Dictionary<ParsedServiceCase[]>
}
const groupByOpenEligibilityKey = (serviceCases: ParsedServiceCase[]) =>
  _groupBy(serviceCases, 'openEligibilityKey')

const filterByInactiveStatus = (serviceCase: ParsedServiceCase) =>
  serviceCase.activationStatus === 'INACTIVE'

const filterByActiveStatus = (serviceCase: ParsedServiceCase) =>
  serviceCase.activationStatus === 'ACTIVE'

export const parseBenefits = (data: GetCasesQuery): ParsedBenefits => {
  const { clientLocations } = data.client
  const serviceCases: ParsedServiceCase[] = clientLocations[0].serviceCases
    .map(mapCases)
    .filter(
      (serviceCase: ParsedServiceCase) =>
        serviceCase.activationStatus !== 'CLOSED'
    )
  const allCases = groupByOpenEligibilityKey(serviceCases)
  const activeCases = groupByOpenEligibilityKey(
    serviceCases.filter(filterByActiveStatus)
  )
  const inactiveCases = groupByOpenEligibilityKey(
    serviceCases.filter(filterByInactiveStatus)
  )
  return {
    allCases,
    activeCases,
    inactiveCases,
  }
}

export type FormattedEligibility =
  | 'Likely Eligible'
  | 'Unlikely Eligible'
  | 'Unavailable'

const formatEligibility = (
  eligibility: ScreeningEligibility
): FormattedEligibility => {
  if (eligibility === 'UNAVAILABLE') {
    return 'Unavailable'
  } else {
    return eligibility === 'LIKELY' ? 'Likely Eligible' : 'Unlikely Eligible'
  }
}

export const filterCasesByStatus = (
  status: string,
  allCasesArray: unknown,
  isFeatureToggleOnValue: boolean
) => {
  if (Array.isArray(allCasesArray)) {
    if (isFeatureToggleOnValue === true) {
      if (status === 'CURRENTLY_RECEIVING') {
        return allCasesArray.filter(
          (serviceCase) => serviceCase.alreadyReceiving === true
        )
      } else {
        return allCasesArray.filter(
          (serviceCase) =>
            serviceCase.status === status &&
            serviceCase.alreadyReceiving === false
        )
      }
    } else {
      // if toggle is off
      return allCasesArray.filter(
        (serviceCase) => serviceCase.status === status
      )
    }
  }
}

export const mapCases = (serviceCase: ServiceCase) => ({
  id: serviceCase.id,
  name: serviceCase.service?.name,
  eligibility: formatEligibility(serviceCase.eligibility),
  status: serviceCase.benefitStatus,
  openEligibilityKey: serviceCase.service?.mainCategory?.openEligibilityKey,
  people: serviceCase.people,
  activationStatus: serviceCase.activationStatus,
  description: serviceCase.service.description,
  toolkitUrl: serviceCase.service.toolkitUrl,
  alreadyReceiving: serviceCase.alreadyReceiving,
  eligibleAmount: serviceCase.eligibleAmount,
  drawdownAmount: serviceCase.drawdownAmount,
  frequency: serviceCase.service.frequency,
  amountLabel: serviceCase.service.amountLabel,
  recurringAmount: calculateRecurringBenefitAmount(serviceCase),
  serviceId: serviceCase.service.id,
  cashBenefit: serviceCase.service.cashBenefit,
})
