import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  TableBody,
  TableCell,
  TableHead,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const StyledSectionsContainer = withStyles({
  root: {
    overflow: 'auto',
    scrollBehavior: 'smooth',
    height: 'calc(100vh - 12.3rem)',
    width: '100%',
    maxWidth: '100%',
    '@media (max-width: 576px)': {
      height: 'calc(100vh - 17rem)',
    },
    '@media (min-width: 768px) and (max-width: 1025px)': {
      height: 'calc(100vh - 13.7rem)',
    },
  },
})(Container)

export const StyledBox = withStyles({
  root: {
    margin: '1rem 0',
    width: '33.333%',
    '@media (max-width: 576px)': {
      width: '100%',
    },
  },
})(Box)

export const StyledCustomBox = withStyles({
  root: {
    margin: '1rem 0',
    width: '50%',
    '@media (max-width: 576px)': {
      width: '100%',
    },
  },
})(Box)

export const StyledWidthCard = styled.div`
  ${media.smaller`
    width: 100%;
    margin : auto
  `}
  ${media.tablet`
    width: 86%;
    margin : auto
  `}
  ${media.desktop`
    width: 72%;
    margin : auto
  `}
`

export const StyledCard = withStyles({
  root: {
    boxShadow: '0px 3px 6px #1D283117',
    margin: '1.5rem 0',
    borderRadius: '7px',
    position: 'relative',
    padding: '1.875rem 3.75rem',
    '&.Review-page': {
      padding: '40px',
    },
    '@media (max-width: 576px)': {
      position: 'relative',
      paddingBottom: '60px',
      '&.Review-page': {
        padding: '8px 8px 12px 8px',
        '&.Review-page__active': {
          paddingBottom: '80px',
        },
      },
    },
  },
})(Card)

export const StyledCardHeader = withStyles({
  root: {
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    '& .MuiCardHeader-avatar': {
      marginRight: '0.725rem',
      '& img': {
        width: '100%',
        height: '100%',
      },
    },
    '& span': {
      fontSize: '1.75rem',
      fontFamily: 'Noto Sans HK,sans-serif',
      fontWeight: 500,
      '@media (max-width: 576px)': {
        fontSize: '1.25rem !important',
      },
      '@media (max-width: 380px)': {
        fontSize: '12px !important',
      },
    },
    '& .MuiCardHeader-action': {
      padding: '0',
      borderRadius: '7px',
      flex: 'unset',
      alignSelf: 'auto',
      marginTop: 0,
      marginRight: 0,
      '& button': {
        padding: '1.25rem 2rem 1.25rem 3rem',
        display: 'flex',

        background: '#f1f1f1',
        '& span': {
          fontSize: '0.75rem',
          textTransform: 'capitalize',
          '& img': {
            position: 'absolute',
            left: '1rem',
            top: '50%',
            transform: 'translateY(-50%)',
          },
        },
      },
      '& .cancel-button-Review, & .save-button-Review': { display: 'flex' },
      '& button.cancel-button': {
        padding: '1.25rem 2rem 1.25rem 2rem',
        marginRight: '1rem',
      },
      '@media (max-width: 576px)': {
        '& .Review-edit': {
          position: 'absolute',
          top: '0',
          padding: '8px',
          right: '0',
          borderRadius: '50%',
          marginTop: '16px',
          width: '40px',
          height: '40px',
          minWidth: 'unset',
        },

        '& .cancel-button-Review, & .save-button-Review': {
          left: '0',
          right: '0',
          width: 'auto',
          bottom: '1rem',
          margin: 'auto',
          display: 'flex',
          position: 'absolute',
          borderRadius: '5px',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px',
          marginTop: '1rem',
          '& button': { flex: '1' },
        },
        '& button': {
          alignItems: 'center',
          padding: '2rem',
          '& span': {
            flexDirection: 'row-reverse',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0',

            '& img': {
              right: '2rem',
              left: 'unset !important',
            },
            '& span.label-text': { display: 'none' },
          },
        },
      },
    },
  },
})(CardHeader)

export const StyledCardContent = withStyles({
  root: {
    padding: '0.625rem 0 0',
    '&:last-child': {
      paddingBottom: '0',
    },
  },
})(CardContent)

export const StyledTableHead = withStyles({
  root: {
    background: '#f1f1f1',
    fontSize: '0.6875rem',
    fontFamily: 'Noto Sans HK,sans-serif',
    fontWeight: 500,
  },
})(TableHead)

export const StyledTableCell = withStyles({
  root: {
    lineHeight: 'normal',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    padding: '0.625rem 1rem',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
})(TableCell)

export const StyledTableBody = withStyles({
  root: {
    fontSize: '.75rem',
    fontFamily: 'Noto Sans HK,sans-serif',
    fontWeight: 500,
  },
})(TableBody)
