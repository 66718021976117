import { NavLink } from 'react-router-dom'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import theme from 'Shared/Theme/ssTheme'
import styled, { css } from 'styled-components'

import 'Components/Uielements/styles/globalStyle.css'

interface StyledSidebarMainMenuButtonProps extends CSSProperties {
  isOnMainSection?: boolean
}

export const StyledSidebarMainMenuButton = styled.button<StyledSidebarMainMenuButtonProps>`
  width: 100%;
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  align-items: center;
  color: ${(props) =>
    props.isOnMainSection
      ? theme.palette.primary.dark
      : theme.palette.text.default};
  border: unset;
  background: none;
  padding: 0.5rem 1rem;
  transition: all 0.75s;
  &:hover {
    cursor: pointer;
    color: ${theme.palette.primary.darken5};
`

export const StyledMenuItemLabel = styled.div`
  margin: auto 0 auto 0.5rem;
`

type StyledSubMenuNavLinkProps = {
  $isClientPortal?: boolean
}

export const StyledSubMenuNavLink = styled(NavLink)<StyledSubMenuNavLinkProps>`
  color: inherit;
  text-decoration: none;
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: 'Roboto';
  border-radius: 8px;
  margin: 0.3125rem 0;
  transition: all 0.5s;
  align-items: center;
  justify-content: start;
  padding-left: 2.4rem;
  min-height: 2.5rem;
  & div {
    transition: all 0.5s;
  }
  &:hover {
    cursor: pointer;
    background: ${theme.palette.primary.darken5};
    color: ${theme.palette.color.white};
    & div {
      fill: ${theme.palette.color.white};
    }
  }

  ${({ $isClientPortal }) =>
    $isClientPortal &&
    css`
      padding: 0 1rem;
      margin: 0.3125rem;
    `}
`

export const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
`

export const StyledWrapper = styled.div`
  margin-left: 0.75rem;
`
