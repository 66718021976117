export const filters = {
  serviceTags: '',
  query: '',
  rehydrated: false,
  persisted: false,
  categoriesFilter: {
    isOpen: false,
    topLevelLabel: '',
    secondLevelLabel: '',
    lastLevelLabel: '',
    currentFilterLevel: 'first',
  },
  benefitGuideState: {
    id: 'all',
    name: 'All',
  },
}
