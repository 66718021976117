import { NavLink } from 'react-router-dom'
import theme from 'Shared/Theme/ssTheme'
import * as u from 'Shared/Theme/utilities.styles'
import { media } from 'styled-bootstrap-grid'
import styled, { css } from 'styled-components'

export const StyledWrapper = styled.nav`
  ${u.dFlex}
  margin: 0 -15px;
  ${media.smaller`
    margin: 2rem 0 0;
    ${u.dBlock}
  `}
`
export const StyledNavColumn = styled.div`
  padding: 0 1.5625rem;
  ${media.smaller`
  margin-bottom: 1rem;
  `}
`

export const StyleImageWrraper = styled.a`
  display: flex;
  width: 100%;
  justify-content: center;
  text-decoration: none;
`

export const StyledTitle = styled.h2`
  margin: 0 0 1.8rem 0;
  font-size: 14px;
  color: ${theme.palette.text.darken};
  text-transform: uppercase;
  font-weight: 700;
  ${media.smaller`
  margin: 0 0 .7rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  `}
`
export const StyledNavList = styled.ul`
  ${u.zeroMargin}
  ${u.zeroPadding}
  list-style: none;
  font-size: 0.9rem;
`
export const StyledNavItem = styled.li``

const linkStyling = css`
  color: ${theme.palette.text.Lighten};
  padding: 0.5em 0;
  ${u.dBlock}
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: ${theme.palette.primary.dark};
  }
`

export const StyledNavRouterLink = styled(NavLink)`
  ${linkStyling}
`

export const StyledNavLink = styled.a`
  ${linkStyling}
`

export const StyledSubParentList = styled.div`
  width: 40%;
  ${u.dFlex}
  ${u.flexCol}
  &:last-child {
    width: 20%;
  }
`

export const StyledTitleList = styled.h4`
  color: #26272d;
  margin: 0 0 2rem;
`

export const StyledList = styled.ul`
  ${u.zeroPadding}
  ${u.zeroMargin}
  list-style: none;
  font-size: 14px;
  font-weight: 200;
  color: #717171;
`

export const StyledItem = styled.li`
  line-height: 2;
`

export const StyledItemLink = styled.a`
  color: ${theme.palette.text.default};
  &:hover {
    color: ${theme.palette.primary.dark};
    cursor: pointer;
  }
`
StyledWrapper.displayName = 'StyledWrapper'
StyledNavColumn.displayName = 'StyledNavColumn'
StyledTitle.displayName = 'StyledTitle'
StyledNavList.displayName = 'StyledNavList'
StyledNavItem.displayName = 'StyledNavItem'
StyledNavLink.displayName = 'StyledNavLink'
StyledSubParentList.displayName = 'StyledSubParentList'
StyledTitleList.displayName = 'StyledTitleList'
StyledList.displayName = 'StyledList'
StyledItem.displayName = 'StyledItem'
StyledItemLink.displayName = 'StyledItemLink'
