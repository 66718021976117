import React from 'react'
import { useLocation } from 'react-router-dom'
import { isFeatureToggleOn } from 'Shared/helpers'

import { CustomTabItem, TabsWrapper } from './clientcustomstyle'

function ClientPortalTabs(props: SidebarProps) {
  const location = useLocation()
  const clientReferralsEnabled = isFeatureToggleOn('client_referrals')
  return (
    <TabsWrapper className='ClientTabsWrapper'>
      <CustomTabItem
        to={'/account/profile'}
        $isClientPortal={true}
        className={
          location.pathname.includes('/account/profile') ? 'activeTab' : ''
        }
      >
        My Profile
      </CustomTabItem>
      <CustomTabItem
        to={'/account/benefits'}
        $isClientPortal={true}
        className={
          location.pathname.includes('/account/benefits') ? 'activeTab' : ''
        }
      >
        My Benefits
      </CustomTabItem>
      <CustomTabItem
        to={'/account/resources'}
        $isClientPortal={true}
        className={
          location.pathname.includes('/account/resources') ? 'activeTab' : ''
        }
      >
        My Resources
      </CustomTabItem>
      {clientReferralsEnabled && (
        <CustomTabItem
          to={'/account/referrals'}
          $isClientPortal={true}
          className={
            location.pathname.includes('/account/referrals') ? 'activeTab' : ''
          }
        >
          Referrals
        </CustomTabItem>
      )}
    </TabsWrapper>
  )
}

export default ClientPortalTabs
