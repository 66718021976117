export type ReferralFeedbackPayload = {
  id: string
  location_name: string
  program_name: string
  unsubscribe_token: string
  resolution_type?: string
}

export const referralFeedback: ReferralFeedbackPayload = {
  id: '',
  location_name: '',
  program_name: '',
  unsubscribe_token: '',
  resolution_type: '',
}
