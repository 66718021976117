import React from 'react'
import { Icon } from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import {
  Address,
  FindHelpResource,
} from 'Components/LocalResources/FindHelpResource'
import { StyledLocationDetail } from 'Components/LocalResources/LocationCard/style'
import ProgramEligibility from 'Components/Shared/LocationDetails/BusinessHours/Eligibility'
import LocationInfo from 'Components/Shared/LocationDetails/LocationInfo/LocationInfo'
import { StyledLocationInfo } from 'Components/Shared/LocationDetails/LocationInfo/style'
import { outlineLocationIconPath } from 'Shared/Icons/LocationInfo/paths'

import {
  CustomNavLink,
  StyledCardComponent,
  StyledCardComponentHeader,
  StyledCardComponentInfo,
  StyledCardComponentInfoLocationWrapper,
  StyledInfoTime,
  StyledInfoTimeInfo,
  StyledInfoTimeStatus,
} from './style'
import { TodaysClosingTimeFunction } from './timeUtils'

const NextStepsComponent = ({ location }: { location: FindHelpResource }) => {
  const {
    hours: businessHours,
    distance,
    address,
    numbers,
    coordinates,
    timezoneName,
    rules,
  } = location
  const { isOpenToday, closingTime, nextOpenedDayInfo } =
    TodaysClosingTimeFunction(businessHours, timezoneName)

  const renderAddress = (address: Address) => {
    if (!address) {
      return null
    }

    const addressArray = []
    const { addressLine1, addressLine2, city, state, zipCode } = address

    if (addressLine1) addressArray.push(addressLine1)
    if (addressLine2) addressArray.push(addressLine2)
    if (city) addressArray.push(city)
    if (state) addressArray.push(state)
    if (zipCode) addressArray.push(zipCode)
    return addressArray.join(', ')
  }

  return (
    <StyledCardComponent>
      {rules && (
        <>
          <StyledCardComponentHeader>Eligibility</StyledCardComponentHeader>
          <StyledCardComponentInfo>
            <ProgramEligibility items={location.rules} />
          </StyledCardComponentInfo>
        </>
      )}
      <StyledCardComponentHeader>Next Steps</StyledCardComponentHeader>

      <StyledCardComponentInfo>
        Go to the{' '}
        <CustomNavLink
          target='_blank'
          to={`http://www.google.com/maps/place/${coordinates.lat},${coordinates.lng}`}
        >
          nearest location
        </CustomNavLink>
        {numbers.primary && <> or call </>}
        {numbers?.primary && (
          <CustomNavLink target='_blank' to={`tel:+${numbers.primary}`}>
            {' '}
            {numbers.primary}
          </CustomNavLink>
        )}
      </StyledCardComponentInfo>

      <StyledCardComponentInfoLocationWrapper>
        <LocationInfo
          content={`${distance} miles away (serves your local area)`}
          icon={outlineLocationIconPath}
          screenReaderLabel={'Location'}
        />
        {address && (
          <LocationInfo
            $isAddress
            content={renderAddress(address)}
            icon={distance ? null : outlineLocationIconPath}
            screenReaderLabel={'Location'}
          />
        )}
      </StyledCardComponentInfoLocationWrapper>

      <div>
        <StyledLocationInfo showMore style={{ alignItems: 'center' }}>
          <Icon
            alignItems='center'
            style={{ alignItems: 'center', display: 'flex' }}
            scale={'1.4rem'}
            fill={'rgba(0,0,0,.2)'}
            viewBox={'0 0 24 24'}
            margin={'0 0.8rem 0 0'}
          >
            <AccessTimeIcon
              style={{ width: '24px', height: '24px', color: '#11AB7C' }}
            />
          </Icon>
          <StyledLocationDetail
            $primary
            style={{ width: '100%', marginBottom: '0' }}
          >
            <StyledInfoTime>
              <StyledInfoTimeStatus>
                {isOpenToday ? 'Open' : 'Closed'}
              </StyledInfoTimeStatus>
              {isOpenToday ? (
                <StyledInfoTimeInfo>
                  <span>Closes at {closingTime}</span>
                </StyledInfoTimeInfo>
              ) : (
                <>
                  <StyledInfoTimeInfo>
                    <span>
                      Opens{' '}
                      <span className='StyledInfoTimeInfo__capitalized'>
                        {nextOpenedDayInfo?.day}
                      </span>{' '}
                      at {nextOpenedDayInfo?.openingTime}
                    </span>
                  </StyledInfoTimeInfo>
                </>
              )}
            </StyledInfoTime>
          </StyledLocationDetail>
        </StyledLocationInfo>
      </div>
    </StyledCardComponent>
  )
}

export default NextStepsComponent
