import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createSlice } from '@reduxjs/toolkit'
import { referralFeedback as initialState } from 'Reducers/initialState/referralFeedback'
import { ReferralFeedbackPayload } from 'Reducers/initialState/referralFeedback'
import { REMEMBER_PERSISTED, REMEMBER_REHYDRATED } from 'redux-remember'

const rememberRehydrated = createAction<{ ui: typeof initialState }>(
  REMEMBER_REHYDRATED
)

const referralFeedbackSlice = createSlice({
  name: 'referralFeedback',
  initialState,
  reducers: {
    setReferralData(state, action: PayloadAction<ReferralFeedbackPayload>) {
      const { id, locationName, serviceName, unsubscribeToken } = action.payload
      state.id = id
      state.location_name = locationName
      state.program_name = serviceName
      state.unsubscribe_token = unsubscribeToken
    },
    resetReferralData(state) {
      state = initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rememberRehydrated, (state, action) => {
      return {
        ...state,
        ...action?.payload?.referralFeedback,
        rehydrated: true,
      }
    })
    builder.addCase(REMEMBER_PERSISTED, (state) => {
      state.rehydrated = false
      state.persisted = true
    })
  },
})

export const { setReferralData, resetReferralData } =
  referralFeedbackSlice.actions

export default referralFeedbackSlice.reducer
