import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { fetchLocationDetails } from 'Actions/locations'
import { updateShowLocationList } from 'Reducers/uiSlice'
import { useAppDispatch } from 'Store/hooks'

import DesktopView from './DesktopView'
import MobileView from './MobileView'
import TabletView from './TabletView'
import { useLocationSelector } from './useLocationSelector'

const LocationDetail = () => {
  const id = useParams().locationId
  const dispatch = useAppDispatch()
  const { locationDetail, searchResults } = useLocationSelector()

  const location = id ? searchResults[id] : locationDetail

  const boundUpdateShowLocationList = () =>
    dispatch(updateShowLocationList(false))

  const boundFetchLocationDetails = () => dispatch(fetchLocationDetails(id))

  useEffect(() => {
    boundFetchLocationDetails()
    boundUpdateShowLocationList()
  }, [])

  if (!location) return null

  return (
    <>
      <DesktopView />
      <TabletView />
      <MobileView />
    </>
  )
}

export default LocationDetail
