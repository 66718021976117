import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SvgIcon } from '@material-ui/core'
import { Location } from '@types/history'
import { unsubscribeClient } from 'Components/Outcome/helpers'
import { OutcomeButton } from 'Components/Outcome/OutcomeButtons'
import OutcomePageTemplate from 'Components/Outcome/OutcomePageTemplate/OutomePageTemplate'
import { FAQIcon } from 'Pages/BenefitsRecieved/IconPaths'
import { useAppSelector } from 'Store/hooks'

const ReferralFeedbackScreenOne = ({
  logo,
  token,
}: {
  logo: string | null
  token: string | undefined
}) => {
  const location: Location = useLocation()
  const navigate = useNavigate()
  const proceedToThankYouPage = () => {
    navigate(`../thank-you`)
  }
  const { location_name, program_name } = useAppSelector(
    (state) => state.referralFeedback
  )
  return (
    <OutcomePageTemplate logo={logo} radioContainer={true}>
      <section className='form-container'>
        <article className='screen-one-header'>
          <SvgIcon>{FAQIcon}</SvgIcon>
          <h1 data-testid='screen-one-title'>
            Were you able to visit or contact {location_name} to discuss{' '}
            {program_name}
          </h1>
          <p>
            Your feedback will help us improve the referral application process.
          </p>
        </article>
        <ul>
          <li>
            <OutcomeButton
              type='primary'
              label='Yes'
              onClick={() => {
                navigate(`../outcome`, {
                  state: { from: location.pathname },
                })
              }}
            />
          </li>
          <li>
            <OutcomeButton
              type='secondary'
              label='No'
              onClick={() => {
                proceedToThankYouPage()
              }}
            />
          </li>
          <li>
            <OutcomeButton
              type='tertiary'
              label='Not yet'
              onClick={() => {
                proceedToThankYouPage()
              }}
            />
          </li>
          <li>
            <OutcomeButton
              type='tertiary'
              label="I don't know"
              onClick={() => {
                proceedToThankYouPage()
              }}
            />
          </li>
          <li>
            <OutcomeButton
              type='tertiary'
              label="I'd prefer to not give feedback"
              onClick={async () => {
                await unsubscribeClient(token, 'referral_feedback')
                proceedToThankYouPage()
              }}
            />
          </li>
        </ul>
      </section>
    </OutcomePageTemplate>
  )
}

export default ReferralFeedbackScreenOne
