import { gql } from '__generated__/gql'

export const CASE_MANAGER_SKIP_CLIENT_OTP = gql(`
mutation CaseManagerSkipClientOtp($email: String, $phone: String, $skip: Boolean) {
  caseManagerSkipClientOtp(email: $email, phone: $phone, skip: $skip) {
    client {
      email
      id
      primaryPhone
    }
  }
}
`)
