import React from 'react'

import {
  CustomStyledImage,
  StyledCard,
  StyledCardTitle,
} from './ResourcesCard.style'

interface ResourceCardProps {
  title: string
  imageSrc: string
  transform: string
  forwardRef: React.Ref<HTMLDivElement>
}
export const ResourceCard = ({
  title,
  imageSrc,
  transform,
  forwardRef,
}: ResourceCardProps) => (
  <StyledCard ref={forwardRef} style={{ transform }}>
    <StyledCardTitle>{title}</StyledCardTitle>
    <CustomStyledImage src={imageSrc} alt='' className='max-w-none' />
  </StyledCard>
)
