import React from 'react'

const ReferralIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    data-name='Layer 1'
    viewBox='0 0 512 640'
    x='0px'
    y='0px'
  >
    <path d='M411.69336,320.62354a67.60909,67.60909,0,0,0-22.8938,3.97985l-86.968-150.63281a67.93415,67.93415,0,1,0-91.33056-.576l-87.30054,151.2088a67.9318,67.9318,0,1,0,43.86633,76.45276H344.93311a67.927,67.927,0,1,0,66.76025-80.43261ZM256.48193,80.51123a42.93262,42.93262,0,1,1-42.93261,42.93262A42.98139,42.98139,0,0,1,256.48193,80.51123ZM100.30664,431.48877a42.93262,42.93262,0,1,1,42.93262-42.93262A42.98087,42.98087,0,0,1,100.30664,431.48877Zm244.62647-55.43262H167.06677a67.92274,67.92274,0,0,0-22.30359-38.80023l86.9375-150.58026a67.71885,67.71885,0,0,0,48.77784.31006l86.75878,150.2699A67.92316,67.92316,0,0,0,344.93311,376.05615Zm66.76025,55.43262A42.93262,42.93262,0,1,1,454.626,388.55615,42.98128,42.98128,0,0,1,411.69336,431.48877Z' />
  </svg>
)

export default ReferralIcon
