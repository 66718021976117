import styled, { css } from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding-top: 0.4rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  max-height: 100%;
  padding-bottom: 1rem;
`

export const StyledLocationCardContainer = styled.button<{
  $noLocationFound?: boolean
}>`
  padding: 0;
  text-align: left;
  background: unset;
  border: unset;
  width: 100%;
  ${({ $noLocationFound }) =>
    $noLocationFound &&
    css`
      padding: 1rem 1.875rem;
      line-height: 1.5;
    `}
`

export const StyledAlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding-top: 0.4rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: unset;
  border: unset;
  width: 100%;
`

export const StyledLocationsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`
export const SearchFeedBackWrapper = styled.div`
  margin: 0 1em 0 1.4em;
  border-radius: 12px;
  background-color: #eef9f6;
  padding: 12px;

  @media only screen and (max-width: 1024px) {
    margin: 0 1rem;
  }
  @media only screen and (max-width: 570px) {
    margin: 0 19px;
  }
`
export const SearchFeedBackHeader = styled.h2`
  font-family: 'Noto Sans HK', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 0 10px 0;
  color: #151515;
`
export const SearchFeedBackDescription = styled.p`
  font-family: 'Noto Sans HK', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  color: #545456;
  span {
    font-weight: 700;
  }
`
StyledContainer.displayName = 'StyledContainer'
StyledAlertContainer.displayName = 'StyledAlertContainer'
StyledLocationsList.displayName = 'StyledLocationsList'
