import React from 'react'
import MediaQuery from 'react-responsive'
import { TABLET_MEDIA_QUERY } from 'Shared/constants'

import Location from './Location'

const TabletView = () => {
  return (
    <MediaQuery query={TABLET_MEDIA_QUERY}>
      <div
        style={{
          background: 'white',
          height: '100%',
          left: '0',
          marginLeft: '4px',
          width: '420px',
          overflowX: 'hidden',
          position: 'absolute',
          top: '0',
          zIndex: '100',
          boxShadow: 'none',
        }}
      >
        <Location />
      </div>
    </MediaQuery>
  )
}

export default TabletView
