import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { Maybe, Person } from '__generated__/graphql'
import { StyledBenefitStatusWrapper } from 'Components/ClientPortal/MyBenefits/style'
import { openEditBenefitStatusModal } from 'Reducers/myBenefitsSlice'
import { isFeatureToggleOn, isSpacebarOrEnterKey } from 'Shared/helpers'

import BenefitCardsPortal from './BenefitCardsPortal'
import KebabMenuIcon from './KebabMenuIcon'

type CaseCardProps = {
  name: string
  eligibility: string
  status: 'ACTIVE' | 'INACTIVE'
  id: string
  people: Maybe<Person[]> | undefined
  description: string
  openEligibilityKey: string
  serviceCaseObject: unknown
  serviceId: string
  cashBenefit: boolean
  BenefitValues: unknown
  className?: string
}

const CaseCardBenefit = ({
  name,
  eligibility,
  status,
  id,
  people,
  description,
  openEligibilityKey,
  serviceCaseObject,
  serviceId,
  cashBenefit,
  BenefitValues,
  className,
}: CaseCardProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleEdit = ({ name, status, id, serviceId, cashBenefit }) => {
    if (status === 'CONFIRMED' || status === 'NOT_APPROVED') {
      navigate('up-to-date')
    } else {
      dispatch(
        openEditBenefitStatusModal({
          benefitName: name,
          benefitStatus: status,
          caseId: id,
          serviceId: serviceId,
          cashBenefit: cashBenefit,
        })
      )
      status === 'NOT_STARTED' ? navigate('application') : navigate('response')
    }
  }
  const enableEdit = isFeatureToggleOn('client_portal_update_benefits')
  const firstWordOfEligibility = eligibility.split(' ')[0]

  return (
    <div
      data-testid={'service-case-card'}
      className={`relative mt-3 w-full overflow-hidden ${className}`}
      key={id}
      id={`service-case-card-${id}`}
    >
      <div className='flex items-center justify-between'>
        <div className='flex gap-8'>
          <p
            className={`rounded-3xl border border-stroke-grey px-3 py-1 ${firstWordOfEligibility === 'Likely' ? 'text-text-dark-green' : 'text-text-dark-grey'} mb-2 mt-3 flex items-center text-xs font-semibold`}
          >
            {firstWordOfEligibility}
          </p>
        </div>
        {enableEdit && (
          <div
            aria-label={'update'}
            role='button'
            tabIndex={0}
            style={{
              color: 'green',
              cursor: 'pointer',
              fontSize: '1rem',
              background: '#ffffff',
              border: 'none',
              fontWeight: 'bold',
            }}
            onClick={() =>
              handleEdit({ name, status, id, serviceId, cashBenefit })
            }
            onKeyDown={(evt) => {
              if (isSpacebarOrEnterKey(evt.keyCode)) {
                handleEdit({ name, status, id, serviceId, cashBenefit })
              }
            }}
          >
            <KebabMenuIcon />
          </div>
        )}
      </div>
      <h4 className='mb-2 text-xs'>{name}</h4>
      <BenefitCardsPortal serviceCaseObject={serviceCaseObject} />
      <StyledBenefitStatusWrapper>
        {people && <span>`For ${people.join(', ')}`</span>}
      </StyledBenefitStatusWrapper>
    </div>
  )
}

export default CaseCardBenefit
