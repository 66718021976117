import {
  ConfirmationType,
  DenialReason,
  OutcomeType,
} from '__generated__/graphql'

export interface MyBenefitsState {
  openBenefitInfoModal: boolean
  openEditCaseStatusModal: boolean
  toolkitUrl?: string
  benefitDescription: string
  benefitName: string
  openEligibilityKey: string
  caseId: string
  serviceId: string
  updatedCaseStatus: string
  rehydrated: boolean
  persisted: boolean
  benefitStatus: string
  confirmationTypes: ConfirmationType[]
  denialReasons: DenialReason[]
  confirmation: ConfirmationType
  denial: DenialReason
  confirmationType: OutcomeType
  cashBenefit: boolean
}

export const myBenefits: MyBenefitsState = {
  openBenefitInfoModal: false,
  openEditCaseStatusModal: false,
  toolkitUrl: '',
  benefitDescription: '',
  benefitName: '',
  benefitStatus: '',
  openEligibilityKey: '',
  caseId: '',
  serviceId: '',
  updatedCaseStatus: '',
  rehydrated: false,
  persisted: false,
  confirmationTypes: [],
  denialReasons: [],
  confirmation: '',
  denial: '',
  confirmationType: '',
  cashBenefit: false,
}
