import { gql } from '__generated__/gql'

// populates first dropdown
// ToDo fix API for screenings (It should not return an array)
export const GET_ACTIVE_CASES = gql(`
  query GetActiveCases($id: ID!) {
    clientLocation(id: $id) {
      id
      client {
        id
        createdAt # need for minDate for Activty Modal
      }
      serviceCases {
        id
        service {
          id
          name
          cashBenefit
          individualBenefit
          mainCategory {
            id
            name
          }
        }
      }
    }
 }`)

gql(`
  fragment ActivityFields on Activity {
    id
    actionable {
      __typename
      ... on Note {
        id
        clientLocation {
          id
          status
          updatedAt
          serviceCases {
            id
            eligibility
            status
            service {
              id
              name
              cashBenefit
              individualBenefit
            }
          }
        }
      }
    }
 }`)

export const CREATE_ACTIVITY = gql(`
  mutation CreateActivity($noteInput: NoteInput!) {
    createNote(input: $noteInput) {
      activity {
        ...ActivityFields
      }
    }
  }
`)

export const UPDATE_ACTIVITY = gql(`
  mutation UpdateActivity($noteInput: NoteInput!) {
    updateNote(input: $noteInput) {
      activity {
        ...ActivityFields
      }
    }
  }
`)

export const GET_SERVICE_NOTE = gql(`
  query GetServiceNote($serviceId: ID!) {
    serviceNote(serviceId: $serviceId) {
      id
      noteTypes
      confirmationTypes
      denialReasons
      applicationTypes
      referralTypes
      __typename
    }
 }`)

export const PIN_NOTE = gql(`
  mutation PinNote($noteId: ID!){
    pinNote(noteId: $noteId) {
      pinnedNote {
        note {
          body
          noteType
          applicationType
          referralType
          outcomeType
          confirmationType
          denialReason
          outcomeAmount
          summary
          attachments {
            fileName
          }
        }
        pinnedBy {
          fullName
        }
      }
    }
  }`)

export const UNPIN_NOTE = gql(`
mutation UnpinNote($noteId: ID!){
  unpinNote(noteId: $noteId) {
    note {
      id
    }
  }
}`)

export const VERIFY_SERVICE_CASE_FOLLOWUP = gql(`
  mutation VerifyServiceCaseFollowup($noteInput: NoteInput, $serviceCaseFollowupId: ID!) {
    verifyServiceCaseFollowup(input: $noteInput, serviceCaseFollowupId: $serviceCaseFollowupId) {
      activity {
        ...ActivityFields
      }
    }
  }`)
