import { FindHelpResource } from 'Components/LocalResources/FindHelpResource'
import { useAppSelector } from 'Store/hooks'

export const useLocationSelector = () => {
  return useAppSelector((state) => ({
    locationDetail: state.locations.locationDetail as FindHelpResource,
    searchResults: state.locations.searchResults as Record<
      string,
      FindHelpResource
    >,
  }))
}
