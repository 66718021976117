import {
  Client,
  ClientLocation,
  ClientScreeningStatus,
  HouseholdMember,
  ProgramAmountLabel,
  ServiceCase,
  ServiceCaseClosureReason,
} from '__generated__/graphql'
import { FieldFunctionOptions } from '@apollo/client'
import groupBy from 'lodash.groupby'
import moment from 'moment'
import { calculateRecurringBenefitAmount } from 'Pages/Results/helpers'
import { isEmpty, toCapitalized } from 'Shared/helpers'

// PRIVATE FUNCTIONS
// These are used by other parsers

const parseAssignedTo = ({
  caseManager,
}: {
  caseManager: { fullName: string }
}) => {
  return caseManager ? caseManager.fullName : 'N/A'
}

const parseClientRelationship = (
  member: HouseholdMember | (Client & { relationshipToClient: string })
) => {
  const { relationshipToClient } = member
  if (!relationshipToClient) return 'N/A'
  const strArr = relationshipToClient.split('_')
  const capitalized = strArr.map((item) => toCapitalized(item))
  return capitalized.join('/')
}

const parseLastUpdated = ({ updatedAt }: { updatedAt: string }) =>
  updatedAt ? formatLocaleTime(updatedAt) : 'N/A'

// PUBLIC FUNCTIONS
// These are used to create props for the components

export const ParseScreeningType = ({
  screening,
}: {
  screening: { isAssisted: boolean }
}) => {
  if (!screening) return 'N/A'
  return screening.isAssisted ? 'Assisted Screening' : 'Self Screened'
}

export const parseCreated = ({ createdAt }: { createdAt: string }) =>
  formatLocaleTime(createdAt)

export const parseCalendarMinDate = ({ createdAt }: { createdAt: string }) =>
  createdAt

/**
 *
 * @param clientLocation {{client: {object}, status: {string}}}
 * @returns {{languages: string, phone: string, email: string, status: string}}
 */

export const parseClientInfo = ({
  client,
  status,
}: {
  client: Client
  status: string
}) => ({
  nickName: client.nickName || 'N/A',
  firstName: client.firstName || '',
  lastName: client.lastName || '',
  status: status || 'N/A',
  email: client.email || 'N/A',
  primaryPhone: client.primaryPhone || 'N/A',
  zipCode: client.zipCode || 'N/A',
  languages: client.languages ? client.languages.join(', ') : 'English',
  screening: client?.screening?.status || '',
  user: client.user || '',
})

/**
 *
 *  @param clientLocation {{client: {object}, status: {string}}}
 * @returns {string}
 */
export const parseClientLocationNeeds = ({
  needs,
}: {
  needs: { name: string }[]
}) => (needs.length ? needs.map((item) => item.name).join(', ') : 'N/A')

/**
 *
 * @param numberOfYears {number|undefined}
 * @return {string|*|string}
 */
export const parseAge = (numberOfYears?: number) => {
  if (isEmpty(numberOfYears)) return 'N/A'
  return numberOfYears ? numberOfYears : 'Under 1 year'
}

/**
 *
 * @param clientLocation {{client: {object}, status: {string}}}
 * @returns {{count: number, householdMembers: {object}}}
 */
export const parseHouseholdMembers = ({ client }: ClientLocation) => {
  const members = client.household ? client.household.members : []
  const withClient = [{ relationshipToClient: 'Self', ...client }, ...members]
  return {
    count: withClient.length,
    householdMembers: withClient.map((member) => ({
      name: parseName({ client: member }),
      relationship: parseClientRelationship(member),
      age: parseAge(member.age),
      firstName: member.firstName || '',
      lastName: member.lastName || '',
      nickName: member.nickName || '',
    })),
  }
}

/**
 *
 * @param {*} otherData
 * @returns
 */
const parseScreeningSite = (otherData: { location: { name: string } }) =>
  otherData?.location?.name

/**
 *
 * @param clientLocation {{client: {object}, status: {string}}}
 * @returns {{lastUpdated: string, created: string, screeningType: string, assignTo: string}}
 */
export const parseInternalInfo = ({
  client,
  ...otherData
}: {
  client: Client
  location: { id: string }
  followUp: boolean
  id: string
}) => ({
  assignTo: parseAssignedTo(otherData),
  created: parseCreated(client),
  followUp: otherData.followUp,
  id: otherData.id,
  lastUpdated: parseLastUpdated(otherData),
  screeningType: ParseScreeningType(client),
  screeningSite: parseScreeningSite(otherData),
  locationId: otherData.location.id,
  okToContact: client?.okToContact,
})

/**
 *
 * @param clientLocation {{client: {object}, status: {string}}}
 * @returns {string}
 */
export const parseName = ({
  client: { firstName, lastName, nickName },
}: {
  client:
    | (HouseholdMember & { nickName: string })
    | (Client & { relationshipToClient: string; nickName: string })
}) => {
  if (!firstName && !lastName && !nickName) return 'N/A'
  const strArr = []
  nickName && strArr.push(firstName && lastName ? `${nickName},` : nickName)
  firstName && strArr.push(firstName)
  lastName && strArr.push(lastName)
  return strArr.join(' ')
}

/**
 *
 * @param clinetLocation {{activities: {nodes}}},
 * @returns {array}
 */

export const parseRecentActivities = ({
  activities: { nodes: activities },
}) => {
  return activities.filter((activity) => {
    // 5/28/2020 BE seeds displaying weird ServiceCase type notes as well; currently fixing
    // 6/18/2020 an update to an activity counts as a new activity; a quick fix for now
    return (
      activity.action !== 'UPDATED' &&
      [
        'Note',
        'ServiceCase',
        'ClientTransfer',
        'Referral',
        'ExternalReferral',
        'ServiceCaseFollowup',
        'ScreeningCalculation',
      ].includes(activity.actionable.__typename)
    )
  })
}

/**
 *
 * @param parsePinnedNotes { pinnedNotes },
 * @returns {array}
 * @description This function is used to parse pinned notes from the clientLocation query
 * and return an array of objects that can be used by the RecentActivities component.
 *
 * See this discussion for background:
 * https://github.com/FEDCAP/single_stop_frontend/pull/3890#discussion_r1365967925
 */
export const parsePinnedNotes = ({ pinnedNotes = [] }) => {
  return pinnedNotes
    .map((note) => ({
      actionable: {
        ...note.note,
        __typename: 'PinnedNote',
        createdAt: note.createdAt,
        updatedAt: note.updatedAt,
        pinnedBy: note.pinnedBy,
      },
    }))
    .sort((a, b) => {
      return a.createdAt > b.createdAt ? -1 : 1
    })
}

export const sortActivityDate = (activities) => {
  return activities.sort((a, b) =>
    a.actionable.activityDate > b.actionable.activityDate ? -1 : 1
  )
}

/**
 *
 * @param item {Object}
 * @returns {string}
 */
export const parseCasesIteratee = (item) => item.mainCategory.openEligibilityKey

/**
 *
 * @param eligibility
 * @return {string|string|*}
 */
export const formatEligibility = (eligibility) => {
  if (!eligibility) return ''
  return eligibility === 'UNAVAILABLE' ? '' : toCapitalized(eligibility)
}

// Sort cases: first based on whether they are closed or not, and then by service id to ensure consistent ordering
// as the backend does not guarantee any ordering
const sortCases = (a, b) => {
  if (a.closed !== b.closed) {
    return a.closed ? 1 : -1
  }
  return a.id.localeCompare(b.id)
}

export interface MappedCase {
  amountLabel: ServiceCase['service']['amountLabel']
  alreadyReceiving: ServiceCase['alreadyReceiving']
  id: ServiceCase['id']
  name: ServiceCase['service']['name']
  eligibility: string
  status: string
  mainCategory: {
    openEligibilityKey: ServiceCase['service']['mainCategory']['openEligibilityKey']
  }
  pendingFollowup: ServiceCase['pendingFollowup']
  people: ServiceCase['people']
  eligibleAmount: ServiceCase['eligibleAmount']
  recurringAmount: number
  frequency: string
  closureReason: ServiceCaseClosureReason | undefined
  closed: boolean
}
export const mapCases = (serviceCase: ServiceCase): MappedCase => ({
  amountLabel: serviceCase.service?.amountLabel ?? ProgramAmountLabel.Potential,
  alreadyReceiving: serviceCase.alreadyReceiving,
  id: serviceCase.id,
  name: serviceCase.service?.name,
  eligibility: formatEligibility(serviceCase.eligibility),
  status: serviceCase.eligibility && toCapitalized(serviceCase.status),
  mainCategory: {
    openEligibilityKey: serviceCase.service?.mainCategory?.openEligibilityKey,
  },
  pendingFollowup: serviceCase.pendingFollowup,
  people: serviceCase.people,
  eligibleAmount: serviceCase?.eligibleAmount,
  recurringAmount: calculateRecurringBenefitAmount(serviceCase),
  frequency: parseFrequency(serviceCase),
  closureReason: serviceCase?.serviceCaseClosure?.reason,
  closed: serviceCase?.closed ?? false,
})

/**
 *
 * @param clientLocation {{client: {object}, status: {string}}}
 * @returns {Object}
 */
export const parseCases = ({ serviceCases }: ClientLocation) => {
  const cases = serviceCases?.map(mapCases)

  const sortedCases = cases?.sort(sortCases)

  return groupBy(sortedCases, parseCasesIteratee)
}

export const parseFrequency = (serviceCase) => {
  if (!serviceCase?.service?.frequency) return ''
  return serviceCase?.service?.frequency === 'NONRECURRENT'
    ? ''
    : serviceCase?.service?.frequency.toLowerCase()
}

export const parseServices = ({ services }) => {
  return groupBy(services, parseCasesIteratee)
}

export const sortCaseMainCategories = (a, b) => {
  const { position: positionA } = mainCategoryMap[a]
  const { position: positionB } = mainCategoryMap[b]
  return positionA - positionB
}

export const sortByMainCategoryName = (a, b) => {
  const nameA = mainCategoryMap[a]?.name.toLocaleString().toLowerCase() || ''
  const nameB = mainCategoryMap[b]?.name.toLocaleString().toLowerCase() || ''
  return nameA.localeCompare(nameB)
}

export const mainCategoryMap: Record<
  string,
  Record<string, string | number>
> = {
  1102: {
    openEligibilityKey: 1102,
    position: 0,
    name: 'Food',
  },
  1270: {
    openEligibilityKey: 1270,
    position: 1,
    name: 'Tax Preparation',
  },
  1106: {
    openEligibilityKey: 1106,
    position: 2,
    name: 'Health',
  },
  1108: {
    openEligibilityKey: 1108,
    position: 3,
    name: 'Care',
  },
  1381: {
    openEligibilityKey: 1381,
    position: 4,
    name: 'Citizenship & Immigration',
  },
  1109: {
    openEligibilityKey: 1109,
    position: 5,
    name: 'Education',
  },
  1101: {
    openEligibilityKey: 1101,
    position: 6,
    name: 'Emergency',
  },
  1104: {
    openEligibilityKey: 1104,
    position: 7,
    name: 'Goods',
  },
  1103: {
    openEligibilityKey: 1103,
    position: 8,
    name: 'Housing',
  },
  1111: {
    openEligibilityKey: 1111,
    position: 9,
    name: 'Legal',
  },
  1107: {
    openEligibilityKey: 1107,
    position: 10,
    name: 'Money',
  },
  1105: {
    openEligibilityKey: 1105,
    position: 11,
    name: 'Transportation',
  },
  20010: {
    openEligibilityKey: 20010,
    position: 12,
    name: 'Veterans',
  },
  1110: {
    openEligibilityKey: 1110,
    position: 13,
    name: 'Work',
  },
}

export const parseScreeningStatus = ({
  client,
}: ClientLocation): ClientScreeningStatus | undefined =>
  client?.screening?.status

export const parseAssignCaseManagerInitialValue = ({ caseManager }) => {
  return caseManager && caseManager.id ? { caseManager: caseManager.id } : null
}

export const parseAssignCaseManagerMenuItems = ({
  caseManagers,
  initialValues,
}) => {
  const data = initialValues
    ? [
        {
          id: 'UNASSIGN',
          fullName: 'Unassign',
        },
        ...caseManagers,
      ]
    : caseManagers
  return data.map((item) => ({
    value: item.id,
    name: item.fullName,
  }))
}

// FUNCTIONS USED BY BOTH CLIENT RECORDS & CLIENT SUMMARY
export const formatLocaleTime = (timestamp: moment.MomentInput) =>
  moment(timestamp).format('ll')

export const formatTimeFromNow = (timestamp: moment.MomentInput) =>
  moment(timestamp).fromNow()

export const timeRightNow = moment().format()

export const startDateOfSingleStop = moment('2007-01-01')
  .startOf('day')
  .toISOString()

export const convertRelativeTimeAgoToISO = ({
  time,
  unit,
}: {
  time: moment.DurationInputArg1
  unit: moment.unitOfTime.DurationConstructor
}) => moment().subtract(time, unit).startOf('day').toISOString()

/**
 * Formats a timestamp into a human-readable date.
 * If 'precise' is true, includes time down to seconds.
 *
 * @param {string|number|Date} timestamp - The timestamp to format.
 * @param {boolean} [precise=false] - Whether to include time in the format.
 * @returns {string} Formatted date string.
 */
export const formatTimeRecentActivity = (
  timestamp: moment.MomentInput,
  precise = false
) =>
  timestamp
    ? moment(timestamp).format(`MMM DD, YYYY${precise ? ' [at] hh:mm A' : ''}`)
    : ''

export const formatFileSize = (fileSizeInBytes: number) => {
  let i = -1
  const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB']
  do {
    fileSizeInBytes = fileSizeInBytes / 1024
    i++
  } while (fileSizeInBytes > 1024)

  return Math.round(fileSizeInBytes) + byteUnits[i]
}

export const formatDatePickerDate = (date: moment.MomentInput) =>
  moment(date).startOf('day')

export const formatEnum = (enumValue: string) => {
  if (!enumValue) return null
  return enumValue
    .toLowerCase()
    .split('_')
    .map((s) => toCapitalized(s))
    .join(' ')
}

export const isEmptyArray = (obj: []) => Array.isArray(obj) && obj.length === 0

export const listHouseholdPeople = (people: Client[]) => {
  return people
    .map(
      (person) =>
        person.nickName ||
        `${person.firstName || ''}${
          person.lastName ? ' ' + person.lastName : ''
        }`
    )
    .join(', ')
}

export const parseClientStatus = ({ status }: { status: string }) =>
  status === 'FRESH' ? 'New' : toCapitalized(status)

// REPORTING

export const reportingParseName = (
  options: FieldFunctionOptions<
    Record<string, unknown>,
    Record<string, unknown>
  >
) => {
  const nickName = options.readField('nickName')
  const firstName = options.readField('firstName')
  const lastName = options.readField('lastName')
  if (!firstName && !lastName && !nickName) return 'N/A'
  const strArr = []
  nickName && strArr.push(firstName && lastName ? `${nickName},` : nickName)
  firstName && strArr.push(firstName)
  lastName && strArr.push(lastName)
  return strArr.join(' ')
}

export const isUpdatedToday = (updatedAt: moment.MomentInput) =>
  moment(updatedAt).isSame(moment(), 'day')

export const formatTimeNow = () => moment().format('MM/DD/YYYY')

export const formatTypedDate = (date: moment.MomentInput) =>
  moment(date).format('MM/DD/YYYY')

export const getFirstOfTheYear = () => moment().startOf('year')

export const getFirstOfPreviousMonth = () =>
  moment().subtract(1, 'months').startOf('month')

export const getEndOfPreviousMonth = () =>
  moment().subtract(1, 'months').endOf('month')

export const enumToDisplayString = (val: string) => {
  if (!val) return ''
  const split = val.split('_')
  const capitalized = split.map((str) => toCapitalized(str))
  return capitalized.join(' ')
}

export const toDollars = (num: number) =>
  num ? `$${num.toLocaleString()}` : ''
