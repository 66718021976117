import { InMemoryCache, makeVar } from '@apollo/client'
import {
  enumToDisplayString,
  formatTimeRecentActivity,
  getEndOfPreviousMonth,
  getFirstOfPreviousMonth,
  parseClientStatus,
  reportingParseName,
  startDateOfSingleStop,
  toDollars,
} from 'Components/CaseManagement/helpers'
import {
  adjustedGrossIncome,
  taxFilingStatus,
} from 'Components/Review/helpers/utilities'
import moment from 'moment'
import possibleTypes from 'possibleTypes.json'
import {
  formatPhoneNumber,
  isFeatureToggleOn,
  toCapitalized,
} from 'Shared/helpers'

import { convertSnakeCaseToCapitalized } from '../Shared/helpers'

import {
  getActivitiesFilterSettings,
  getAnalyticsDashboardFilterSettings,
  getCasesFilterSettings,
  getDemographicDashboardFilterSettings,
  getDemographicHouseholdFilterSettings,
  getDrawdownFilterSettings,
  getHouseholdFilterSettings,
  getIndividualFilterSettings,
  getKpiDashboardFilterSettings,
  getMilitaryDate,
  getNotesFilterSettings,
  showValueIfVeteran,
  toDollarsWithoutDecimals,
} from './helpers'

// BEGIN REACTIVE VARIABLES:

// CASE MANAGEMENT REACTIVE VARIABLES:
export const screeningVar = makeVar([]) // COMPLETED, INCOMPLETE, BYPASSED

export const statusVar = makeVar([]) // FRESH, ACTIVE, CLOSED, ARCHIVED

export const pageNumberVar = makeVar(0)

export const searchTermVar = makeVar('')

export const assignedToVar = makeVar(null) // case manager ID

export const assignmentStatusVar = makeVar(null) // null, ASSIGNED, UNASSIGNED,

export const minDateVar = makeVar(startDateOfSingleStop) // 2007-01-01

export const sortDirectionVar = makeVar('DESC')

export const sortFieldVar = makeVar('UPDATED_AT')

export const showFileUploadModalVar = makeVar(false)

export const filesVar = makeVar([])

export const clientRecordsSiteVar = makeVar(['ALL'])

export const resetCaseManagementFilters = () => {
  screeningVar([])
  statusVar([])
  pageNumberVar(0)
  searchTermVar('')
  assignedToVar(null)
  assignmentStatusVar(null)
  minDateVar(startDateOfSingleStop)
  sortDirectionVar('DESC')
  sortFieldVar('UPDATED_AT')
  showFileUploadModalVar(false)
  filesVar([])
  clientRecordsSiteVar(['ALL'])
}

// REPORTING REACTIVE VARIABLES:
export const showReportingMainModalVar = makeVar(false)

// REPORTING SORT REACTIVE VARIABLES:
export const reportingHouseholdSortVar = makeVar({
  field: 'DISPLAY_NAME',
  direction: 'ASC',
})

export const reportingIndividualSortVar = makeVar({
  field: 'FULL_NAME',
  direction: 'ASC',
})

export const reportingActivitiesSortVar = makeVar({
  field: 'PRIMARY_HOUSEHOLD_MEMBER',
  direction: 'ASC',
})

export const reportingReferralsSortVar = makeVar({
  field: 'HOUSEHOLD_ID',
  direction: 'ASC',
})

export const reportingCasesSortVar = makeVar({
  field: 'PRIMARY_HOUSEHOLD_MEMBER',
  direction: 'ASC',
})

export const reportingNotesSortVar = makeVar({
  field: 'HOUSEHOLD_ID',
  direction: 'ASC',
})

// REPORTING SKIP REACTIVE VARIABLES: (pagination)
export const reportingHouseholdSkipVar = makeVar(0)

export const reportingIndividualSkipVar = makeVar(0)

export const reportingActivitiesSkipVar = makeVar(0)

export const reportingReferralsSkipVar = makeVar(0)

export const reportingCasesSkipVar = makeVar(0)

export const reportingDrawdownSkipVar = makeVar(0)

export const reportingNotesSkipVar = makeVar(0)

// REPORTING FILTER SELECT MENU REACTIVE VARIABLES:
export const screeningStatusFilterVar = makeVar('ALL')

export const clientStatusFilterVar = makeVar('ALL')

export const screeningTypeFilterVar = makeVar('ALL')

export const reportingIndividualTypeFilterVar = makeVar('ALL')

export const householdDemographicsColumnsVar = makeVar('ALL')

export const individualDemographicsColumnsVar = makeVar('ALL')

export const reportingCasesColumnsVar = makeVar('ALL')

export const reportingActivitiesColumnsVar = makeVar('ALL')

export const reportingReferralsColumnsVar = makeVar('ALL')

export const reportingActivitiesTypeFilterVar = makeVar('ALL')
export const reportingActivitiesAddedByFilterVar = makeVar('ALL')
export const reportingActivitiesAddedByUserFilterVar = makeVar(null)
export const reportingCasesCaseStatusFilterVar = makeVar('ALL')
export const reportingCasesCategoriesFilterVar = makeVar(['ALL'])
export const reportingCasesAssignedToFilterVar = makeVar('ALL')

export const reportingCaseFilterVar = makeVar('ALL')

export const reportingCasesOutcomeFilterVar = makeVar(['ALL'])
export const demographicsDashboardStateVar = makeVar('ALL')

export const kpiDashboardStateVar = makeVar('ALL')

export const demographicsScreeningDetailsChartScreeningTypeVar = makeVar('ALL')

export const demographicsIndividualByOneChartFilterVar = makeVar('GENDER_CHART')

export const demographicsIndividualByTwoChartFilterOneVar =
  makeVar('GENDER_CHART')

export const demographicsIndividualByTwoChartFilterTwoVar =
  makeVar('EDUCATION_CHART')

export const reportingCasesClosedVar = makeVar('ALL')
export const reportingNotesAddedByVar = makeVar('ALL')

// SITE filters
export const reportsSiteFilterVar = makeVar(['ALL'])

export const assignedToFilterVar = makeVar(['ALL'])

// Benefit guides filters
export const benefitGuideStateVar = makeVar('all')

// Analytics Dashboard Cases By Outcome
export const outcomeServiceCaseChartServiceIdVar = makeVar('ALL')

export const serviceCasesByTypeChartOutcomeTypeVar = makeVar('ALL')

export const confirmedCasesTimeseriesChartServiceIdVar = makeVar('ALL')

// REPORTING DATE RANGE REACTIVE VARIABLES:
export const reportingDateRangeStartVar = makeVar(
  moment(getFirstOfPreviousMonth()).toISOString()
)

export const reportingDateRangeEndVar = makeVar(
  moment(getEndOfPreviousMonth()).toISOString()
)

// REPORTING SEARCH REACTIVE VARIABLES:
export const reportingHouseholdSearchTermVar = makeVar('')

export const reportingIndividualSearchTermVar = makeVar('')

export const reportingActivitiesSearchTermVar = makeVar('')

export const reportingReferralsSearchTermVar = makeVar('')

export const reportingCasesSearchTermVar = makeVar('')

export const reportingDrawdownSearchTermVar = makeVar('')

export const reportingNotesSearchTermVar = makeVar('')

export const resetReportingDateRange = () => {
  reportingDateRangeStartVar(moment(getFirstOfPreviousMonth()).toISOString())
  reportingDateRangeEndVar(moment(getEndOfPreviousMonth()).toISOString())
}

// REPORTING RESET FILTER FUNCTIONS:
export const resetHouseholdGridFilters = () => {
  screeningStatusFilterVar('ALL')
  clientStatusFilterVar('ALL')
  screeningTypeFilterVar('ALL')
  resetReportingDateRange()
  reportsSiteFilterVar(['ALL'])
  householdDemographicsColumnsVar('ALL')
}

export const resetIndividualGridFilters = () => {
  reportingIndividualTypeFilterVar('ALL')
  screeningStatusFilterVar('ALL')
  resetReportingDateRange()
  reportsSiteFilterVar(['ALL'])
  assignedToFilterVar(['ALL'])
}

export const resetActivitiesGridFilters = () => {
  reportingActivitiesTypeFilterVar('ALL')
  reportingCaseFilterVar('ALL')
  reportingActivitiesAddedByFilterVar('ALL')
  reportingActivitiesAddedByUserFilterVar(null)
  resetReportingDateRange()
  reportsSiteFilterVar(['ALL'])
}

export const resetCasesGridFilters = () => {
  reportingCasesCaseStatusFilterVar('ALL')
  reportingCasesCategoriesFilterVar(['ALL'])
  reportingCasesAssignedToFilterVar('ALL')
  reportingCaseFilterVar('ALL')
  reportingCasesOutcomeFilterVar(['ALL'])
  resetReportingDateRange()
  reportsSiteFilterVar(['ALL'])
  reportingCasesClosedVar(false)
}

export const resetDemographicsDashboardFilters = () => {
  demographicsDashboardStateVar('ALL')
  demographicsScreeningDetailsChartScreeningTypeVar('ALL')
  demographicsIndividualByOneChartFilterVar('GENDER_CHART')
  demographicsIndividualByTwoChartFilterOneVar('GENDER_CHART')
  demographicsIndividualByTwoChartFilterTwoVar('EDUCATION_CHART')
  resetReportingDateRange()
  reportsSiteFilterVar(['ALL'])
}

export const resetAnalyticsDashboardFilters = () => {
  resetReportingDateRange()
  reportsSiteFilterVar(['ALL'])
  outcomeServiceCaseChartServiceIdVar('ALL')
  serviceCasesByTypeChartOutcomeTypeVar('ALL')
  confirmedCasesTimeseriesChartServiceIdVar('ALL')
}

export const resetKpiDashboardFilters = () => {
  resetReportingDateRange()
  kpiDashboardStateVar('ALL')
  reportsSiteFilterVar(['ALL'])
}

export const resetDrawdownGridFilters = () => {
  reportingCaseFilterVar('ALL')
  reportsSiteFilterVar(['ALL'])
  resetReportingDateRange()
}

export const resetNotesReportFilters = () => {
  resetReportingDateRange()
  reportsSiteFilterVar(['ALL'])
  reportingNotesAddedByVar('ALL')
}

const booleanToYesOrNo = (bool) => {
  const f = (bool) => (bool === true ? 'Yes' : 'No')
  return bool !== undefined ? f(bool) : null
}

const readVar = (varName) => {
  return {
    read() {
      return varName()
    },
  }
}

const createDisplay = (readField, formatFunc) => {
  return {
    read: (_, options) => {
      return formatFunc(options.readField(readField))
    },
  }
}
let isFeatureToggleOnValue = isFeatureToggleOn('already_receiving_benefits')
// BEGIN CACHE

const cache = new InMemoryCache({
  possibleTypes: possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        kpiQuickDetails: {
          merge(existing, incoming, { mergeObjects }) {
            // Correct, thanks to invoking nested merge functions.
            return mergeObjects(existing, incoming)
          },
        },
        screening: readVar(screeningVar),
        status: readVar(statusVar),
        pageNumber: readVar(pageNumberVar),
        searchTerm: readVar(searchTermVar),
        assignedTo: readVar(assignedToVar),
        assignmentStatus: readVar(assignmentStatusVar),
        minDate: readVar(minDateVar),
        sortDirection: readVar(sortDirectionVar),
        sortField: readVar(sortFieldVar),
        showFileUploadModal: readVar(showFileUploadModalVar),
        files: readVar(filesVar),
        screeningStatusFilter: readVar(screeningStatusFilterVar),
        clientStatusFilter: readVar(clientStatusFilterVar),
        clientRecordsSiteFilter: readVar(clientRecordsSiteVar),
        // TODO: ADD REMAINING FILTERS HERE when backend is ready
        // benefitGuideState: {
        //   read() {
        //     return benefitGuideStateVar()
        //   },
        // },

        reportingFilters: {
          read() {
            return {
              grid: {
                household: getHouseholdFilterSettings(),
                individual: getIndividualFilterSettings(),
                cases: getCasesFilterSettings(),
                activities: getActivitiesFilterSettings(),
                drawdown: getDrawdownFilterSettings(),
                notes: getNotesFilterSettings(),
              },
              dashboard: {
                demographics: {
                  ...getDemographicDashboardFilterSettings(),
                  // no filters in the card for this chart
                  householdScreeningChartData: null,
                  singleIndividualDemographicsChart: {
                    demographicType:
                      demographicsIndividualByOneChartFilterVar(),
                  },
                  demographicVerticalBarChart: {
                    demographicOne:
                      demographicsIndividualByTwoChartFilterOneVar(),
                    demographicTwo:
                      demographicsIndividualByTwoChartFilterTwoVar(),
                  },
                  demographicQuickDetails: {
                    // no filters in the card for this chart
                  },
                  screeningDetails: {
                    screeningType:
                      demographicsScreeningDetailsChartScreeningTypeVar(),
                  },
                },
                analytics: {
                  ...getAnalyticsDashboardFilterSettings(),
                  outcomeServiceCaseChart: {
                    serviceId: outcomeServiceCaseChartServiceIdVar(),
                  },
                  serviceCasesByTypeChart: {
                    outcomeType: serviceCasesByTypeChartOutcomeTypeVar(),
                  },
                  confirmedCasesTimeseriesChart: {
                    serviceId: confirmedCasesTimeseriesChartServiceIdVar(),
                  },
                },
                kpi: {
                  // The top-level spread controls the state of the page-level filters
                  ...getKpiDashboardFilterSettings(),
                  // If we add individual filters for each chart they will be nested inside the current object like so:
                  // fooChart: { bar: 'baz' }
                },
              },
            }
          },
        },
        reportingGridQueryVariables: {
          read() {
            return {
              household: {
                filter: getHouseholdFilterSettings(),
                skip: reportingHouseholdSkipVar(),
                sort: reportingHouseholdSortVar(),
              },
              individual: {
                filter: getIndividualFilterSettings(),
                searchTerm: reportingIndividualSearchTermVar(),
                skip: reportingIndividualSkipVar(),
                sort: reportingIndividualSortVar(),
              },
              cases: {
                filter: getCasesFilterSettings(),
                searchTerm: reportingCasesSearchTermVar(),
                skip: reportingCasesSkipVar(),
                sort: reportingCasesSortVar(),
              },
              activities: {
                filter: getActivitiesFilterSettings(),
                searchTerm: reportingActivitiesSearchTermVar(),
                skip: reportingActivitiesSkipVar(),
                sort: reportingActivitiesSortVar(),
              },
              referrals: {
                skip: reportingReferralsSkipVar(),
                sort: reportingReferralsSortVar(),
              },
              drawdown: {
                filter: getDrawdownFilterSettings(),
                searchTerm: reportingDrawdownSearchTermVar(),
              },
              notes: {
                filter: getNotesFilterSettings(),
                searchTerm: reportingNotesSearchTermVar(),
                skip: reportingNotesSkipVar(),
                sort: reportingNotesSortVar(),
              },
            }
          },
        },
        dashboardQueryVariables: {
          read() {
            return {
              demographicQuickDetails: {
                ...getDemographicDashboardFilterSettings(),
              },
              demographicVerticalBarChart: {
                ...getDemographicDashboardFilterSettings(),
                demographicOne: demographicsIndividualByTwoChartFilterOneVar(),
                demographicTwo: demographicsIndividualByTwoChartFilterTwoVar(),
              },
              householdScreeningChartData: {
                filter: getDemographicHouseholdFilterSettings(),
              },
              screeningDetails: {
                ...getDemographicDashboardFilterSettings(),
                screeningType:
                  demographicsScreeningDetailsChartScreeningTypeVar(),
              },
              singleIndividualDemographicsChart: {
                ...getDemographicDashboardFilterSettings(),
                demographicType: demographicsIndividualByOneChartFilterVar(),
              },
              outcomeServiceCaseChart: {
                ...getAnalyticsDashboardFilterSettings(),
                serviceId: outcomeServiceCaseChartServiceIdVar(),
              },
              programAnalyticsQuickDetails: {
                ...getAnalyticsDashboardFilterSettings(),
              },
              confirmedCasesTimeseriesChart: {
                ...getAnalyticsDashboardFilterSettings(),
                serviceId: confirmedCasesTimeseriesChartServiceIdVar(),
              },
              serviceCasesByTypeChart: {
                ...getAnalyticsDashboardFilterSettings(),
                outcomeType: serviceCasesByTypeChartOutcomeTypeVar(),
              },
              overallConfirmationRate: {
                ...getKpiDashboardFilterSettings(),
              },
              overallMultipleConfirmationRate: {
                ...getKpiDashboardFilterSettings(),
              },
              totalDrawdown: {
                ...getKpiDashboardFilterSettings(),
              },
              outcomesByTypeChart: {
                ...getKpiDashboardFilterSettings(),
              },
              activitiesByType: {
                ...getKpiDashboardFilterSettings(),
              },
              drawdown: {
                ...getKpiDashboardFilterSettings(),
              },
              kpiQuickDetails: {
                ...getKpiDashboardFilterSettings(),
              },
            }
          },
        },
        showReportingMainModal: {
          read() {
            return showReportingMainModalVar()
          },
        },
        reportingIndividualTypeFilter: {
          read() {
            return reportingIndividualTypeFilterVar()
          },
        },
        columnSelections: {
          read() {
            return {
              household: householdDemographicsColumnsVar(),
              individual: individualDemographicsColumnsVar(),
              cases: reportingCasesColumnsVar(),
              activities: reportingActivitiesColumnsVar(),
              referrals: reportingReferralsColumnsVar(),
            }
          },
        },
      },
    },
    Person: {
      fields: {
        displayDateOfBirth: {
          read(_, options) {
            const dob = options.readField('dateOfBirth')
            return dob
              ? moment(dob, 'YYYY-MM-DD').format('MM/DD/YYYY')
              : undefined
          },
        },
      },
    },
    ActivityReport: {
      fields: {
        displayConfirmedAmount: {
          read(_, options) {
            return toDollarsWithoutDecimals(
              options.readField('confirmedAmount')
            )
          },
        },
        displayOutcomeType: {
          read(_, options) {
            const outcomeType = options.readField('outcomeType')
            return outcomeType ? toCapitalized(outcomeType) : ''
          },
        },
        displayActivityType: {
          read(_, options) {
            return enumToDisplayString(options.readField('activityType'))
          },
        },
        displayApplicationType: {
          read(_, options) {
            return enumToDisplayString(options.readField('applicationType'))
          },
        },
        displayReferralType: {
          read(_, options) {
            return enumToDisplayString(options.readField('referralType'))
          },
        },
        displayConfirmationType: {
          read(_, options) {
            return enumToDisplayString(options.readField('confirmationType'))
          },
        },
        displayDenialReason: {
          read(_, options) {
            return enumToDisplayString(options.readField('denialReason'))
          },
        },
        displayOutcomeAmount: createDisplay('confirmedAmount', toDollars),
        displayCreatedAt: {
          read(_, options) {
            return formatTimeRecentActivity(options.readField('createdAt'))
          },
        },
        displayUpdatedAt: {
          read(_, options) {
            return formatTimeRecentActivity(options.readField('updatedAt'))
          },
        },
        displayDrawdownAmount: createDisplay('drawdownAmount', toDollars),
      },
    },
    ReferralReport: {
      fields: {
        displayReferralType: createDisplay('referralType', enumToDisplayString),
        displayOutcomeType: createDisplay('outcomeType', enumToDisplayString),
        displayResolutionType: createDisplay(
          'resolutionType',
          enumToDisplayString
        ),
        displayUpdatedAt: createDisplay('updatedAt', formatTimeRecentActivity),
      },
    },
    DrawdownByCaseReport: {
      fields: {
        displayCaseType: {
          read(_, options) {
            const caseType = options.readField('caseType')
            return convertSnakeCaseToCapitalized(caseType)
          },
        },
        displayCashBenefit: {
          read(_, options) {
            const cashBenefit = options.readField('cashBenefit')
            return booleanToYesOrNo(cashBenefit)
          },
        },
        displayConfirmationType: {
          read(_, options) {
            const confirmationType = options.readField('confirmationType')
            return convertSnakeCaseToCapitalized(confirmationType)
          },
        },
        displayDrawdownAmount: {
          read(_, options) {
            const drawdownAmount = options.readField('drawdownAmount')
            return drawdownAmount ? toDollars(drawdownAmount) : '$0'
          },
        },
      },
    },
    Demographic: {
      fields: {
        displayHasHealthInsurance: {
          read(_, options) {
            const healthInsurance = options.readField('hasHealthInsurance')
            return booleanToYesOrNo(healthInsurance)
          },
        },
        displayIsStudent: {
          read(_, options) {
            const isStudent = options.readField('isStudent')
            return booleanToYesOrNo(isStudent)
          },
        },
        displayHasWorkStudy: {
          read(_, options) {
            const hasWorkStudy = options.readField('hasWorkStudy')
            return booleanToYesOrNo(hasWorkStudy)
          },
        },
        displayIsEligibleForWorkStudy: {
          read(_, options) {
            const isEligibleForWorkStudy = options.readField(
              'isEligibleForWorkStudy'
            )
            return booleanToYesOrNo(isEligibleForWorkStudy)
          },
        },
        displayIsVeteran: {
          read(_, options) {
            const isVeteran = options.readField('isVeteran')
            return booleanToYesOrNo(isVeteran)
          },
        },
        displayMilitaryDischargeStatus: {
          read(_, options) {
            return showValueIfVeteran(options, () =>
              options.readField('militaryDischargeStatus')
            )
          },
        },
        displayMilitaryBranch: {
          read(_, options) {
            return showValueIfVeteran(options, () =>
              options
                .readField('militaryBranch')
                ?.replace(/Noaa/g, 'NOAA')
                .replace(/Usphs/g, 'USPHS')
            )
          },
        },
        displayMilitaryStartDate: {
          read(_, options) {
            return getMilitaryDate('min', options)
          },
        },
        displayMilitaryEndDate: {
          read(_, options) {
            return getMilitaryDate('max', options)
          },
        },
        displayIsUsCitizen: {
          read(_, options) {
            const isUsCitizen = options.readField('isUsCitizen')
            return booleanToYesOrNo(isUsCitizen)
          },
        },
        displayImmigrationStatus: createDisplay(
          'immigrationStatus',
          (status) => status ?? 'US Citizen'
        ),
        displayEfc: {
          read(_, options) {
            const efc = options.readField('expectedFamilyContribution')
            return toDollars(efc) || '$0'
          },
        },
        displayCoa: {
          read(_, options) {
            const coa = options.readField('costOfAttendance')
            return toDollars(coa) || '$0'
          },
        },
        displayEducation: createDisplay('education', (education) =>
          education ? education.replace(/Ged/g, 'GED') : ''
        ),
      },
    },
    HouseholdDemographicReport: {
      fields: {
        displayTotalAnnualIncome: {
          read(_, options) {
            return toDollars(options.readField('totalAnnualIncome'))
          },
        },
        displayTotalAdditionalIncome: {
          read(_, options) {
            return toDollars(options.readField('totalAdditionalIncome'))
          },
        },
        displayHasEducationExpenses: {
          read(_, options) {
            const hasEducationExpenses = options.readField(
              'hasEducationExpenses'
            )
            return booleanToYesOrNo(hasEducationExpenses)
          },
        },
        displayHasUtilityExpenses: {
          read(_, options) {
            const hasUtilityExpenses = options.readField('hasUtilityExpenses')
            return booleanToYesOrNo(hasUtilityExpenses)
          },
        },
        displayTotalSavingsAmount: {
          read(_, options) {
            return toDollars(options.readField('totalSavingsAmount'))
          },
        },
        displayRentOrMortgageExpensesAmount: {
          read(_, options) {
            return toDollars(options.readField('rentOrMortgageExpensesAmount'))
          },
        },
        displayAdultCareExpensesAmount: {
          read(_, options) {
            return toDollars(options.readField('adultCareExpensesAmount'))
          },
        },
        displayChildSupportExpensesAmount: {
          read(_, options) {
            return toDollars(options.readField('childSupportExpensesAmount'))
          },
        },
        displayChildcareExpensesAmount: {
          read(_, options) {
            return toDollars(options.readField('childcareExpensesAmount'))
          },
        },
        displayOutOfPocketMedicalExpensesAmount: {
          read(_, options) {
            return toDollars(
              options.readField('outOfPocketMedicalExpensesAmount')
            )
          },
        },
      },
    },
    IndividualDemographicReport: {
      fields: {
        displayAnnualIncome: {
          read(_, options) {
            return toDollars(options.readField('annualIncome'))
          },
        },
        displayIsCurrentlyWorking: {
          read(_, options) {
            const isCurrentlyWorking = options.readField('isCurrentlyWorking')
            return booleanToYesOrNo(isCurrentlyWorking)
          },
        },
        displayClaimedAsDependent: {
          read(_, options) {
            const claimedAsDependent = options.readField('claimedAsDependent')
            return booleanToYesOrNo(claimedAsDependent)
          },
        },
        displayAdditionalIncome: {
          read(_, options) {
            const additionalIncome = options.readField('additionalIncome')
            return additionalIncome > 0 ? 'Yes' : 'No'
          },
        },
        displaySavings: {
          read(_, options) {
            const savings = options.readField('savingsAmount')
            return savings > 0 ? 'Yes' : 'No'
          },
        },
        displayDateOfBirth: {
          read(_, options) {
            return formatTimeRecentActivity(options.readField('dateOfBirth'))
          },
        },
        displaySavingsAmount: {
          read(_, options) {
            return toDollars(options.readField('savingsAmount'))
          },
        },
        displayAlimonyAmount: {
          read(_, options) {
            return toDollars(options.readField('alimonyAmount'))
          },
        },
        displayChildSupportAmount: {
          read(_, options) {
            return toDollars(options.readField('childSupportAmount'))
          },
        },
        displayPensionAmount: {
          read(_, options) {
            return toDollars(options.readField('pensionAmount'))
          },
        },
        displaySsdSsiAmount: {
          read(_, options) {
            return toDollars(options.readField('ssdSsiAmount'))
          },
        },
        displayUnemploymentInsuranceAmount: {
          read(_, options) {
            return toDollars(options.readField('unemploymentInsuranceAmount'))
          },
        },
        displayVaBenefitsAmount: {
          read(_, options) {
            return toDollars(options.readField('vaBenefitsAmount'))
          },
        },
        displayWorkersCompensationAmount: {
          read(_, options) {
            return toDollars(options.readField('workersCompensationAmount'))
          },
        },
        displayOtherAdditionalIncomeAmount: {
          read(_, options) {
            return toDollars(options.readField('otherAdditionalIncomeAmount'))
          },
        },
      },
    },
    Note: {
      fields: {
        displayActivityDate: {
          read(_, options) {
            return formatTimeRecentActivity(options.readField('activityDate'))
          },
        },
        displayOutcomeType: {
          read(_, options) {
            const outcomeType = options.readField('outcomeType')
            return outcomeType ? toCapitalized(outcomeType) : ''
          },
        },
        displayNoteType: {
          read(_, options) {
            return options.readField('noteType') === 'GENERAL'
              ? 'Case Note'
              : 'Case Activity'
          },
        },
        displayActivityType: {
          read(_, options) {
            return enumToDisplayString(options.readField('activityType'))
          },
        },
        displayApplicationType: {
          read(_, options) {
            return enumToDisplayString(options.readField('applicationType'))
          },
        },
        displayReferralType: {
          read(_, options) {
            return enumToDisplayString(options.readField('referralType'))
          },
        },
        displayConfirmationType: {
          read(_, options) {
            return enumToDisplayString(options.readField('confirmationType'))
          },
        },
        displayDenialReason: {
          read(_, options) {
            return enumToDisplayString(options.readField('denialReason'))
          },
        },
        displayOutcomeAmount: {
          read(_, options) {
            return toDollars(options.readField('outcomeAmount'))
          },
        },
        displayCreatedAt: {
          read(_, options) {
            return formatTimeRecentActivity(options.readField('createdAt'))
          },
        },
        displayUpdatedAt: {
          read(_, options) {
            return formatTimeRecentActivity(options.readField('updatedAt'))
          },
        },
      },
    },
    CaseReport: {
      fields: {
        displayCreatedAt: {
          read(_, options) {
            return formatTimeRecentActivity(options.readField('createdAt'))
          },
        },
        displayUpdatedAt: {
          read(_, options) {
            return formatTimeRecentActivity(options.readField('updatedAt'))
          },
        },
        displayOutcomeDate: {
          read(_, options) {
            return formatTimeRecentActivity(options.readField('outcomeDate'))
          },
        },
        displayState: {
          read(_, options) {
            return options.readField('closed') ? 'Closed' : 'Open'
          },
        },
        displayCaseStatus: {
          read(_, options) {
            if (
              isFeatureToggleOnValue &&
              options.readField('serviceCase').alreadyReceiving
            ) {
              return 'Current Benefit'
            } else {
              return toCapitalized(options.readField('caseStatus'))
            }
          },
        },
        displayConfirmedAmount: {
          read(_, options) {
            return toDollars(options.readField('confirmedAmount'))
          },
        },
        displayEstimatedAmount: {
          read(_, options) {
            return toDollarsWithoutDecimals(
              options.readField('estimatedAmount')
            )
          },
        },
        displayEligibility: {
          read(_, options) {
            return enumToDisplayString(options.readField('eligibility'))
          },
        },
        displayClosedReason: {
          read(_, options) {
            return enumToDisplayString(options.readField('closedReason'))
          },
        },
        displayOutcomeType: {
          read(_, options) {
            return enumToDisplayString(options.readField('outcomeType'))
          },
        },
        displayDenialReason: {
          read(_, options) {
            return enumToDisplayString(options.readField('denialReason'))
          },
        },
        displayDrawdownAmount: {
          read(_, options) {
            return toDollarsWithoutDecimals(options.readField('drawdownAmount'))
          },
        },
      },
    },
    ServiceCase: {
      fields: {
        displayDrawdownAmount: {
          read(_, options) {
            return toDollarsWithoutDecimals(options.readField('drawdownAmount'))
          },
        },
      },
    },
    ServiceCaseClosure: {
      fields: {
        displayClosureReason: {
          read(_, options) {
            return enumToDisplayString(options.readField('reason'))
          },
        },
      },
    },
    Client: {
      fields: {
        displayName: {
          read(_, options) {
            return reportingParseName(options)
          },
        },
        displayContactInformation: {
          read(_, options) {
            return (
              options.readField('email') || options.readField('primaryPhone')
            )
          },
        },
        displayAlternatePhone: createDisplay(
          'alternatePhone',
          formatPhoneNumber
        ),
        displayLastYearAdjustedGrossIncome: {
          read(_, options) {
            const grossIncome = options.readField('lastYearAdjustedGrossIncome')
            return adjustedGrossIncome(grossIncome) || '$0'
          },
        },
        displayTaxFilingStatus: {
          read(_, options) {
            const filingStatus = options.readField('taxFilingStatus')
            return taxFilingStatus(filingStatus)
          },
        },
      },
    },
    ClientLocation: {
      fields: {
        displayClosedReason: {
          read(_, options) {
            return enumToDisplayString(options.readField('closedReason'))
          },
        },
        displayClientRecordStatus: {
          read(_, options) {
            const status = options.readField('status')
            return parseClientStatus({ status })
          },
        },
        displayCreatedAt: {
          read(_, options) {
            const createdAt = options.readField('createdAt')
            return formatTimeRecentActivity(createdAt)
          },
        },
        displayUpdatedAt: {
          read(_, options) {
            const updatedAt = options.readField('updatedAt')
            return formatTimeRecentActivity(updatedAt)
          },
        },
        displayAssignedTo: {
          read(_, options) {
            const caseManager = options.readField('caseManager')
            return options.readField('fullName', caseManager) || 'Unassigned'
          },
        },
      },
    },
    Screening: {
      fields: {
        displayStatus: {
          read(_, options) {
            const status = options.readField('status')
            return toCapitalized(status)
          },
        },
        displayScreeningType: {
          read(_, options) {
            return options.readField('isAssisted')
              ? 'Assisted'
              : 'Self-Screened'
          },
        },
      },
    },
    // GET_CONFIRMED_CASES_TIMESERIES_CHART returns the date range in ISO format
    // i.e. '2019-01-01T05:00:00.000Z'
    // Victory takes in Javascript Dates for their timeline chart so might as well convert to date here
    LineDataSet: {
      fields: {
        x: {
          read(isoTime) {
            return new Date(isoTime)
          },
        },
      },
    },
  },
})

export default cache
