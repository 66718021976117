import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { CustomIconWrapper } from 'Components/Shared/ActionButton/style'
import { CustomStyledActionButton } from 'Components/Shared/ActionButton/style'
import {
  GET_FAVORITE_LOCATIONS,
  TOGGLE_FAVORITE_LOCATION,
} from 'Components/Shared/LocationDetails/Header/gql'
import HeartFilledIcon from 'Components/Shared/LocationDetails/Header/HeartFilledIcon'
import { isSpacebarOrEnterKey } from 'Shared/helpers'

type SaveLocationButtonProps = {
  activateFavorites?: boolean
}

const SaveLocationButton = ({
  activateFavorites = false,
}: SaveLocationButtonProps) => {
  const [isSaved, setIsSaved] = useState(false)

  const [ariaLabel, setAriaLabel] = useState('')

  const [handleToggleFavoriteLocation] = useMutation(TOGGLE_FAVORITE_LOCATION)

  const { state: createFindHelpLocationInput } = useLocation()

  // In order to know if the current location is saved, we must:
  // 1. get a list of all saved locations
  const { data } = useQuery(GET_FAVORITE_LOCATIONS)
  // 2. compare the officeId, programId, and providerId of each of the saved locations against the those of the current location
  useEffect(() => {
    if (!data) return
    const { favoriteLocations } = data.me
    // if there are no saved locations, then the current location is not saved
    if (!favoriteLocations.length) {
      setIsSaved(false)
      return
    }
    for (let i = 0; i < favoriteLocations.length; i++) {
      const { findHelpLocation } = favoriteLocations[i]
      const isSaved =
        findHelpLocation?.officeId === createFindHelpLocationInput?.officeId &&
        findHelpLocation?.programId ===
          createFindHelpLocationInput?.programId &&
        findHelpLocation?.providerId === createFindHelpLocationInput?.providerId
      if (isSaved) {
        setIsSaved(true)
        break
      }
      setIsSaved(false)
    }
  }, [data, createFindHelpLocationInput]) //the second dependency is for clicking from one card to another

  const handleClick = () => {
    if (!activateFavorites) return
    handleToggleFavoriteLocation({
      variables: {
        input: {
          createFindHelpLocationInput,
          favoriteLocation: !isSaved,
        },
      },
      refetchQueries: [GET_FAVORITE_LOCATIONS],
    })
  }

  useEffect(() => {
    if (!activateFavorites) {
      setAriaLabel('Log in to your account to save this location')
      return
    }
    setAriaLabel(
      isSaved
        ? 'Click to remove location from favorites'
        : 'Click to add location to favorites'
    )
  }, [activateFavorites, isSaved])

  return (
    <CustomStyledActionButton
      onClick={handleClick}
      onKeyDown={(e) => {
        if (isSpacebarOrEnterKey(e.keyCode)) {
          handleClick()
        }
      }}
      disabled={!activateFavorites}
      tabIndex={0}
      data-testid={'save-location-button'}
      aria-label={ariaLabel}
    >
      <CustomIconWrapper>
        <HeartFilledIcon enabled={activateFavorites} isSaved={isSaved} />
      </CustomIconWrapper>
      <span style={{ fontSize: '14px', color: 'rgb(42, 42, 42' }}>Save</span>
    </CustomStyledActionButton>
  )
}

export default SaveLocationButton
