import React from 'react'
import {
  StyledHelpfulLinks,
  StyleHeadingHelpful,
  StyleHeadingHelpfulLink,
  StyleVisitParagraph,
} from 'Components/NextSteps/style'

import {
  DescriptionContent,
  DescriptionWrapper,
} from '../Shared/LocationDetails/Description/style'

type HelpfulLink = {
  title: string
  description: string
  active: boolean
  link: string
}

type HelpfulLinksListProps = {
  activeLinks: HelpfulLink[]
}

const HelpfulLinksList: React.FC<HelpfulLinksListProps> = ({ activeLinks }) => {
  return (
    <div>
      {activeLinks.map((data: HelpfulLink, i: number) => (
        <StyledHelpfulLinks key={i}>
          <StyleHeadingHelpful>{data?.title}</StyleHeadingHelpful>
          <ul>
            <li>
              <DescriptionWrapper color='#545456' paddingLeft='0'>
                <DescriptionContent
                  fontSize='1rem'
                  role='document'
                  dangerouslySetInnerHTML={{ __html: data?.description }}
                />
              </DescriptionWrapper>
            </li>
          </ul>
          <StyleVisitParagraph>
            Visit
            <StyleHeadingHelpfulLink
              href={data?.link}
              target='_blank'
              aria-label={`Visit the page for ${data?.title}`}
            >
              {data?.link}
            </StyleHeadingHelpfulLink>
          </StyleVisitParagraph>
        </StyledHelpfulLinks>
      ))}
    </div>
  )
}

export default HelpfulLinksList
