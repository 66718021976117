const mockCategories = [
  {
    id: 1108,
    name: 'Care',
    explanation:
      'Programs and services that offer residential care, child and adult daytime care, adoption & foster care.',
    detailedExplanation: '',
  },
  {
    id: 1109,
    name: 'Education',
    explanation:
      'Programs and services that offer preschool, after school, special education, help finding school, help paying for school, summer camps, etc.',
    detailedExplanation: '',
  },
  {
    id: 1101,
    name: 'Emergency',
    explanation:
      'Programs and services that offer immediate assistance during crises, including emergency shelter, disaster relief, food assistance, urgent medical care, and financial aid for emergencies.',
    detailedExplanation: '',
  },
  {
    id: 1102,
    name: 'Food',
    explanation:
      'Food Programs and services that offer free meals, food pantries, help paying for food, meals on wheels, food benefits, etc.',
    detailedExplanation: '',
  },
  {
    id: 1104,
    name: 'Goods',
    explanation:
      'Programs and services that offer clothing, furniture, books, laptops, baby supplies, toys, and gifts, etc.',
    detailedExplanation: '',
  },
  {
    id: 1106,
    name: 'Health',
    explanation:
      'Programs and services that offer medical care, dental care, mental health, addiction and recovery, and help paying for healthcare/insurance.',
    detailedExplanation: '',
  },
  {
    id: 1103,
    name: 'Housing',
    explanation:
      'Programs and services that help with emergency, short- term and long- term housing, help finding housing, and paying for housing.',
    detailedExplanation: '',
  },
  {
    id: 1381,
    name: 'Immigration',
    explanation:
      'Programs and services that offer Immigration specific services for housing, financial assistance, health, etc.',
    detailedExplanation: '',
  },
  {
    id: 1111,
    name: 'Legal',
    explanation:
      'Programs and services that offer legal representation, mediation, advocacy, citizenship and immigration services, civil rights, etc.',
    detailedExplanation: '',
  },
  {
    id: 1107,
    name: 'Money',
    explanation:
      'Programs and services that offer help pay for housing, education, childcare, school, food and goods, financial education, tax preparation, insurance, government benefits, vouchers, and more.',
    detailedExplanation: '',
  },
  {
    id: 1105,
    name: 'Transportation',
    explanation:
      'Programs and services that offer bus passes, school transportation, healthcare appointments.',
    detailedExplanation: '',
  },
  {
    id: 1270,
    name: 'Taxes',
    explanation:
      'Programs and services that offer free or low-cost tax preparation',
    detailedExplanation: '',
  },
  {
    id: 20010,
    name: 'Veterans',
    explanation:
      'Programs and services that offer veteran specific services for housing, financial assistance, health, etc.',
    detailedExplanation: '',
  },
  {
    id: 1110,
    name: 'Work',
    explanation:
      'Programs and services that offer help finding and sustaining work and improving job skills and abilities',
    detailedExplanation: '',
  },
]

export default mockCategories
