import React from 'react'
import { SvgIcon } from '@material-ui/core'
import { StyledCasesCard } from 'Components/ClientPortal/MyBenefits/style'
import {
  StyledDeleteButton,
  StyledDeleteButtonWrapper,
  StyledHeader,
  StyledLink,
  StyledSubHeader,
} from 'Components/ClientPortal/MyResources/style'
import { deleteIcon } from 'Shared/Icons/LocationInfo/paths'

const CaseCard = ({ resourceData, onDeleteClick }) => {
  const {
    findHelpLocation: {
      programId,
      officeId,
      providerId,
      programName,
      providerName,
      zipCode,
      name,
      categories,
    },
  } = resourceData

  return (
    <>
      <StyledCasesCard className='styledCardItem'>
        <StyledLink
          to={{
            pathname: `/locations/list/${officeId}`,
          }}
          state={{
            officeId: officeId,
            providerId,
            programId,
            providerName: providerName,
            programName: programName,
            zipCode: zipCode,
            officeName: name,
            serviceTags: categories,
          }}
        >
          <StyledHeader title={programName}>{programName}</StyledHeader>
        </StyledLink>
        <StyledDeleteButtonWrapper>
          <StyledDeleteButton onClick={onDeleteClick}>
            <SvgIcon>{deleteIcon}</SvgIcon>
          </StyledDeleteButton>
        </StyledDeleteButtonWrapper>

        <StyledSubHeader title={providerName}>
          by <span>{providerName}</span>
        </StyledSubHeader>
      </StyledCasesCard>
    </>
  )
}

export default CaseCard
