import { gql } from '__generated__/gql'

export const GET_CASES = gql(`
    query getCases ($id: ID!) {
        client(id: $id) {
            id
            clientLocations {
                id
                benefitTotals {
                    cashBenefitEligibleAmount
                    nonCashBenefitEligibleAmount
                    totalEligibleAmount
                }
                serviceCases {
                    id
                    benefitStatus
                    eligibility
                    activationStatus
                    alreadyReceiving
                    eligibleAmount
                    drawdownAmount
                    service {
                        frequency
                        amountLabel
                        cashBenefit
                        toolkitUrl
                        id
                        name
                        mainCategory {
                            openEligibilityKey
                        }
                        description
                    }
                    status
                }
            }
        }
    }
`)

export const ACTIVATE_INACTIVE_CASE = gql(`
    mutation ActivateInactiveCase($serviceCaseId: ID!) {
        activateInactiveServiceCase(serviceCaseId: $serviceCaseId) {
            serviceCase {
                activationStatus
                alreadyReceiving
                benefitStatus
                closed
                createdAt
                drawdownAmount
                eligibility
                eligibleAmount
                id
                status
                updatedAt
            }
        }
    }
`)

export const COMPLETE_SERVICE_CASE = gql(`
    mutation CompleteServiceCase (
        $serviceCaseId: ID!
        $outcomeType: OutcomeType
        $confirmationType: ConfirmationType
        $denialReason: DenialReason
        $outcomeAmount: Float
    ) {
        completeServiceCase(
            serviceCaseId: $serviceCaseId
            outcomeType: $outcomeType
            confirmationType: $confirmationType
            denialReason: $denialReason
            outcomeAmount: $outcomeAmount
        ) {
            serviceCase {
                id
            }
        }
    }
`)

export const GET_CONFIRMATION_TYPES = gql(`
    query GetConfirmationTypes ($id: ID!) {
        clientLocation(id: $id) {
            serviceCases {
                pendingFollowup {
                    partialOutcome {
                        positiveConfirmationType
                        negativeConfirmationType
                    }
                }
            }
        }
    }
`)
