import React from 'react'

export const benefitGuideIconPath = (
  <>
    <path
      d='M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M16 13H8M16 17H8M10 9H8M14 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V8L14 2Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  </>
)

export const worldIcon = (
  <>
    <path
      d='M12.4998 2.04849C11.7106 1.80062 10.8708 1.66699 9.99984 1.66699C5.39746 1.66699 1.6665 5.39795 1.6665 10.0003C1.6665 14.6027 5.39746 18.3337 9.99984 18.3337C14.6022 18.3337 18.3332 14.6027 18.3332 10.0003C18.3332 8.57089 17.9733 7.22551 17.3391 6.04986M14.1665 4.79199H14.1707M8.74988 18.2405L8.74997 16.4044C8.74998 16.305 8.78556 16.2088 8.85028 16.1333L10.9218 13.7165C11.092 13.5179 11.0393 13.2134 10.8121 13.0836L8.43195 11.7234C8.36728 11.6865 8.3137 11.6329 8.27678 11.5682L6.72522 8.84918C6.64447 8.70767 6.48865 8.62621 6.32636 8.64067L1.71999 9.05095M17.4998 5.00033C17.4998 6.84127 15.8332 8.33366 14.1665 10.0003C12.4998 8.33366 10.8332 6.84127 10.8332 5.00033C10.8332 3.15938 12.3256 1.66699 14.1665 1.66699C16.0075 1.66699 17.4998 3.15938 17.4998 5.00033ZM14.3748 4.79199C14.3748 4.90705 14.2816 5.00033 14.1665 5.00033C14.0514 5.00033 13.9582 4.90705 13.9582 4.79199C13.9582 4.67693 14.0514 4.58366 14.1665 4.58366C14.2816 4.58366 14.3748 4.67693 14.3748 4.79199Z'
      stroke='#151515'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </>
)

export const arrowIcon = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.1665 11.5L9.99984 7.33333L5.83317 11.5L14.1665 11.5Z'
      fill='#151515'
    />
  </svg>
)

export const filterIconPath = (
  <path
    d='M2 4.6C2 4.03995 2 3.75992 2.10899 3.54601C2.20487 3.35785 2.35785 3.20487 2.54601 3.10899C2.75992 3 3.03995 3 3.6 3H20.4C20.9601 3 21.2401 3 21.454 3.10899C21.6422 3.20487 21.7951 3.35785 21.891 3.54601C22 3.75992 22 4.03995 22 4.6V5.26939C22 5.53819 22 5.67259 21.9672 5.79756C21.938 5.90831 21.8901 6.01323 21.8255 6.10776C21.7526 6.21443 21.651 6.30245 21.4479 6.4785L15.0521 12.0215C14.849 12.1975 14.7474 12.2856 14.6745 12.3922C14.6099 12.4868 14.562 12.5917 14.5328 12.7024C14.5 12.8274 14.5 12.9618 14.5 13.2306V18.4584C14.5 18.6539 14.5 18.7517 14.4685 18.8363C14.4406 18.911 14.3953 18.9779 14.3363 19.0315C14.2695 19.0922 14.1787 19.1285 13.9971 19.2012L10.5971 20.5612C10.2296 20.7082 10.0458 20.7817 9.89827 20.751C9.76927 20.7242 9.65605 20.6476 9.58325 20.5377C9.5 20.4122 9.5 20.2142 9.5 19.8184V13.2306C9.5 12.9618 9.5 12.8274 9.46715 12.7024C9.43805 12.5917 9.39014 12.4868 9.32551 12.3922C9.25258 12.2856 9.15102 12.1975 8.94789 12.0215L2.55211 6.4785C2.34898 6.30245 2.24742 6.21443 2.17449 6.10776C2.10986 6.01323 2.06195 5.90831 2.03285 5.79756C2 5.67259 2 5.53819 2 5.26939V4.6Z'
    stroke='currentColor'
    strokeWidth='1.5'
    strokeLinecap='round'
    strokeLinejoin='round'
  />
)

export const closeIconPath = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17 7L7 17M7 7L17 17'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
