import React from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import ButtonsContainer from 'Components/Outcome/OutcomeCashBenefitForm/ButtonsContainer'
import OutcomePageTemplate from 'Components/Outcome/OutcomePageTemplate/OutomePageTemplate'
import StyledRadioButton from 'Components/Outcome/ui/StyledRadioButton'
import { FORM_ERROR } from 'final-form'
import {
  ERROR_GENERIC_MESSAGE,
  REFERRAL_FEEDBACK_RESPONSES,
} from 'Shared/constants'
import { API_BASE } from 'Shared/constants'

const ReferralFeedbackScreenTwo = ({ logo }: { logo: string | null }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const onSubmit = (values: {
    resolution_type:
      | 'received_services'
      | 'unable_to_contact'
      | 'not_able_to_get_help'
      | 'no_change'
      | 'no_capacity'
      | 'not_interested'
      | 'not_eligible'
      | 'referred_elsewhere'
  }) => {
    return axios
      .post(`${API_BASE}/external_referrals/${id}/referral_feedback`, {
        feedback: {
          resolution_type: values.resolution_type,
        },
      })
      .then(() => {
        navigate('../thank-you')
      })
      .catch((error) => {
        console.error(error)
        return { [FORM_ERROR]: ERROR_GENERIC_MESSAGE }
      })
  }

  return (
    <OutcomePageTemplate logo={logo} radioContainer>
      <section className='form-container'>
        <article>
          <h2
            style={{
              fontSize: '1.6rem',
              marginBottom: '1.6rem',
              fontWeight: '600',
            }}
          >
            Select from the list below to tell us how it went during your
            referral visit
          </h2>
        </article>
        <Form
          onSubmit={onSubmit}
          validate={({ resolution_type }) =>
            resolution_type ? {} : { [FORM_ERROR]: 'Please choose an option' }
          }
          render={({ handleSubmit, values, error, submitError, touched }) => (
            <form onSubmit={handleSubmit}>
              <ul>
                {REFERRAL_FEEDBACK_RESPONSES.map(({ label, value, id }) => {
                  const isChecked = values.confirmationType === value
                  return (
                    <li key={id}>
                      <StyledRadioButton
                        id={id}
                        name='resolution_type'
                        value={value}
                        $isChecked={isChecked}
                        label={label}
                      />
                    </li>
                  )
                })}
              </ul>
              {error && touched?.resolution_type && (
                <p className='error-message'>{error}</p>
              )}
              {submitError && <p className='error-message'>{submitError}</p>}
              <ButtonsContainer
                backButtonCallback={() => {
                  navigate('..')
                }}
              />
            </form>
          )}
        />
      </section>
    </OutcomePageTemplate>
  )
}

export default ReferralFeedbackScreenTwo
