import * as u from 'Shared/Theme/utilities.styles'
import styled from 'styled-components'

import { StyledHeader } from '../Header/style'

export const StyledMenuParent = styled.div`
  position: relative;
`

export const StyledMenu = styled.nav`
  ${u.pFixed}
  ${u.wd100}
  background-color: #fff;
  padding: 0 0 6rem 0;
  height: 100vh;
  overflow-y: auto;
  z-index: 99;
  z-index: 999 !important;
`
export const StyledDesktopAndTabletMenuLogo = styled(StyledHeader)`
  ${u.pFixed}
  bottom: 0;
  width: ${(props) => (props.device === 'tablet' ? '50%' : '76.8rem')};
  padding: 0;
  background-color: rgb(255, 255, 255);
`
export const StyledMobileMenuLogo = styled(StyledDesktopAndTabletMenuLogo)`
  ${u.wd100}
  background-color: rgb(255, 255, 255);
`
export const StyledList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  z-index: 9;
`

StyledMenu.displayName = 'StyledMenu'
StyledDesktopAndTabletMenuLogo.displayName = 'DesktopAndTabletMenuLogo'
StyledMobileMenuLogo.displayName = 'MobileMenuLogo'
StyledMenuParent.displayName = 'StyledMenuParent'
StyledList.displayName = 'StyledList'
