import React from 'react'
import { NumericFormat } from 'react-number-format'
import { isFeatureToggleOn } from 'Shared/helpers'

const CurrencyDisplay = ({ value, isCurrency = true }) => {
  return isCurrency ? (
    <NumericFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'$'}
      decimalScale={0}
      fixedDecimalScale={true}
      style={{ fontSize: '14px' }}
    />
  ) : (
    <span>{value}</span>
  )
}

function BenefitCardsPortal({ serviceCaseObject }) {
  const frequencies = serviceCaseObject.frequency
  const eligibalAmount = serviceCaseObject.eligibleAmount
  const alreadyReceiving = serviceCaseObject.alreadyReceiving

  const isFeatureToggleOnValue = isFeatureToggleOn('already_receiving_benefits')
  return (
    <div className='flex flex-col gap-2'>
      {((eligibalAmount !== 0 && eligibalAmount !== null) ||
        (alreadyReceiving && isFeatureToggleOnValue)) && (
        <div className='flex w-full flex-row rounded-lg bg-surface-light-grey p-2'>
          <div className='m-0 flex w-full items-center justify-between gap-2 font-roboto text-xs font-semibold'>
            <p className='whitespace-nowrap font-roboto font-normal text-text-dark-grey'>
              Potential Yearly Value
            </p>
            {alreadyReceiving && isFeatureToggleOnValue ? (
              <span className='text-sm font-semibold'>Current Benefit</span>
            ) : (
              <CurrencyDisplay
                value={serviceCaseObject.eligibleAmount}
                isCurrency={true}
              />
            )}
          </div>
        </div>
      )}

      {frequencies !== 'NONRECURRENT' &&
        eligibalAmount !== 0 &&
        eligibalAmount !== null && (
          <div className='flex w-full flex-row rounded-lg bg-surface-light-grey p-2'>
            <div className='m-0 flex w-full items-center justify-between gap-2 font-roboto text-xs font-semibold'>
              <p className='whitespace-nowrap font-roboto font-normal text-text-dark-grey'>
                {frequencies
                  ?.toLowerCase()
                  ?.replace(/^\w/, (c) => c.toUpperCase())}
              </p>
              <CurrencyDisplay
                value={serviceCaseObject.recurringAmount}
                isCurrency={true}
              />
            </div>
          </div>
        )}
    </div>
  )
}

export default BenefitCardsPortal
