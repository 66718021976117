import React, { createContext, ReactNode, useContext, useState } from 'react'
import { FindHelpResource } from 'Components/LocalResources/FindHelpResource'

type ReferralData = {
  locationId: string
  services: number[]
  name: string
  address: Record<string, string>
  numbers: Record<string, string>
  locationRef: FindHelpResource
}

type ReferralContextType = {
  referralData: ReferralData | null
  setReferralData: React.Dispatch<React.SetStateAction<ReferralData | null>>
}

const ReferralContext = createContext<ReferralContextType | null>(null)

export const useReferralData = () => {
  const context = useContext(ReferralContext)
  if (!context) {
    throw new Error('useReferralData must be used within a ReferralProvider')
  }
  return context
}

type ReferralProviderProps = {
  children: ReactNode
}

const ReferralProvider = ({ children }: ReferralProviderProps) => {
  const [referralData, setReferralData] = useState<ReferralData | null>(null)

  return (
    <ReferralContext.Provider value={{ referralData, setReferralData }}>
      {children}
    </ReferralContext.Provider>
  )
}

export default ReferralProvider
